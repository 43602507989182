import { DateTime } from "luxon";
import moment from "moment";

const importData = async (dcfId, items, rawsitelist, stdlist, login_data, refineddata, quantitativesubmission) => {
    let promises = [], rejected = [], local2 = [], errorArray = []
    if (dcfId === 304) {
        let promise = new Promise(async (res, rej) => {
            try {

                if (!items['Entity'] || !items['Entity'].toString().trim().length) {
                    errorArray.push('Entity Field is Required')
                }
                if (!items['Entity Level'] || !items['Entity Level'].toString().trim().length) {
                    errorArray.push('Entity Level Field is Required')
                }
                if (!items['Null Report(Yes/No)'] || !items['Null Report(Yes/No)'].toString().trim().length) {
                    errorArray.push('Null Report(Yes/No) Field is Required')
                }
                if (!items['Reporting Period'] || !items['Reporting Period'].toString().trim().length) {
                    errorArray.push('Reporting Period Field is Required')
                } else if (items['Reporting Period'].toString().trim().length) {
                    if (!validateDateString(items['Reporting Period'])) {
                        errorArray.push('Reporting Period Field Format is Incorrect')
                    }
                }
                if (!items['Category of Fuel Used'] || !items['Category of Fuel Used'].toString().trim().length) {
                    errorArray.push('Category of Fuel Used Field is Required')
                }

                if (!items['Fuel Used'] || !items['Fuel Used'].toString().trim().length) {
                    errorArray.push('Fuel Used Field is Required')
                }

                if (!items['Unit of Measurement'] || !items['Unit of Measurement'].toString().trim().length) {
                    errorArray.push('Unit of Measurement Field is Required')
                }
                if ((!items['Quantity of Fuel Consumed'] && items['Quantity of Fuel Consumed'] !== 0) || !items['Quantity of Fuel Consumed'].toString().trim().length) {
                    errorArray.push('Quantity of Fuel Consumed Field is Required')
                } else if (items['Quantity of Fuel Consumed'].toString().trim().length) {
                    console.log(items['Quantity of Fuel Consumed'], parseFloat(items['Quantity of Fuel Consumed'].toString()).toString().trim().length, items['Quantity of Fuel Consumed'].toString().trim().length)
                    if (items['Quantity of Fuel Consumed'] !== null &&
                        items['Quantity of Fuel Consumed'] !== undefined &&
                        parseFloat(items['Quantity of Fuel Consumed'].toString()) >= 0 && (parseFloat(items['Quantity of Fuel Consumed'].toString()).toString().trim().length === items['Quantity of Fuel Consumed'].toString().trim().length)
                    ) {
                        errorArray.push('Quantity of Fuel Consumed Field Accepts Only valid Number Input as Either Rounded or Fraction value (Dont use , or other notation)')
                    } else if (!parseFloat(items['Quantity of Fuel Consumed'].toString().trim()) && parseFloat(items['Quantity of Fuel Consumed'].toString().trim()) !== 0) {
                        errorArray.push('Quantity of Fuel Consumed Field Requires Either Rounded or Fraction value')
                    }
                }
                if (items['Entity'] && items['Entity'].toString().trim().length && items['Entity Level'] && items['Entity Level'].toString().trim().length && items['Reporting Period'] && items['Reporting Period'].toString().trim().length && validateDateString(items['Reporting Period']) && items['Fuel Used'] && items['Fuel Used'].toString().trim().length && items['Unit of Measurement'] && items['Unit of Measurement'].toString().trim().length && items['Category of Fuel Used'] && items['Category of Fuel Used'].toString().trim().length) {
                    let level = items['Entity Level'];

                    const { result, obj } = getCoverageIdByText({
                        entity: items['Entity'],
                        level: level.toString().toLowerCase().trim() === 'corporate' ? 0 : level.toString().toLowerCase().trim() === 'country' ? 1 : level.toString().toLowerCase().trim() === 'region' ? 2 : level.toString().toLowerCase().trim() === 'business unit' ? 3 : null
                    }, rawsitelist);
                    const { found, assObj } = await getAssignment(items['Reporting Period'], obj, errorArray, refineddata).then((assObj) => { return assObj })
                    console.log(found, 'found')
                    if (found) {
                        let stdindex = JSON.parse(JSON.stringify(stdlist)).findIndex(
                            (i) => i.id === assObj[0].standard
                        );
                        if (stdindex !== -1) {
                            if (JSON.parse(JSON.stringify(stdlist))[stdindex].newEfCategories !== undefined) {
                                let cat_index = JSON.parse(JSON.stringify(stdlist))[stdindex].newEfCategories.findIndex((i) => i.id === 1);
                                let category = JSON.parse(JSON.stringify(stdlist))[stdindex].newEfCategories[cat_index].newEfSubcategory1s;

                                const { result, id } = findMatchingIds(
                                    category,
                                    items['Category of Fuel Used'].toString(),
                                    items['Fuel Used'].toString(),
                                    items['Unit of Measurement'].toString()
                                );
                                if (!result) {
                                    errorArray.push('Mismatch in Combination Category of Fuel Used & Fuel Used & Unit of Measurement')

                                }

                            }
                        } else {
                            errorArray.push('Inform Admin to check selected Standard for this Enity on this Form ')

                        }
                    } else if (!found && assObj[0].id) {
                        let oldIndex = quantitativesubmission.find(i => i.id === assObj[0].id)
                        let refObjData = assObj.filter(i => i.refobj)
                        let refObj = refObjData[0].refobj
                        let stdindex = JSON.parse(JSON.stringify(stdlist)).findIndex(
                            (i) => i.id === refObj.standard
                        );
                        if (stdindex !== -1) {
                            if (JSON.parse(JSON.stringify(stdlist))[stdindex].newEfCategories !== undefined) {
                                let cat_index = JSON.parse(JSON.stringify(stdlist))[stdindex].newEfCategories.findIndex((i) => i.id === 1);
                                let category = JSON.parse(JSON.stringify(stdlist))[stdindex].newEfCategories[cat_index].newEfSubcategory1s;

                                const { result, id } = findMatchingIds(
                                    category,
                                    items['Category of Fuel Used'].toString(),
                                    items['Fuel Used'].toString(),
                                    items['Unit of Measurement'].toString()
                                );
                                if (!result) {
                                    errorArray.push('Mismatch in Combination Category of Fuel Used & Fuel Used & Unit of Measurement')

                                }

                            }
                        } else {
                            errorArray.push('Inform Admin to check selected Standard for this Enity on this Form ')

                        }




                    }
                }

                let local = {};
                let newObj = {};
                let level = items['Entity Level'];
                if (level && items['Entity'] && items['Null Report(Yes/No)'] && items['Reporting Period'] && validateDateString(items['Reporting Period'])) {
                    const { result, obj } = getCoverageIdByText({
                        entity: items['Entity'],
                        level: level.toString().toLowerCase().trim() === 'corporate' ? 0 : level.toString().toLowerCase().trim() === 'country' ? 1 : level.toString().toLowerCase().trim() === 'region' ? 2 : level.toString().toLowerCase().trim() === 'business unit' ? 3 : null
                    }, rawsitelist);

                    if (result) {
                        newObj = { ...newObj, ...obj };
                        let dt = DateTime.utc();
                        const { found, assObj } = await getAssignment(items['Reporting Period'], obj, null, refineddata).then((assObj) => { return assObj });
                        console.log(found, assObj)
                        if (found) {
                            newObj['level'] = level.toString().toLowerCase().trim() === 'corporate' ? 0 : level.toString().toLowerCase().trim() === 'country' ? 1 : level.toString().toLowerCase().trim() === 'region' ? 2 : level.toString().toLowerCase().trim() === 'business unit' ? 3 : null;
                            newObj['reporting_period'] = getRPLuxon(items['Reporting Period']);
                            newObj['rp'] = items['Reporting Period'];
                            newObj['locationId'] = newObj[`tier${newObj['level']}_id`]
                            newObj['form_type'] = 1
                            newObj['user_type'] = login_data.role === 'clientuser' ? 1 : login_data.role === 'clientadmin' ? 0 : 2;
                            newObj['logs'] = [];
                            newObj['submitted_by'] = login_data.id;
                            newObj['submitted_on'] = dt;
                            newObj['last_modified_on'] = dt;
                            newObj['reporter_modified_on'] = dt;
                            newObj['reporter_modified_by'] = login_data.id;
                            newObj['last_modified_by'] = login_data.id;
                            newObj['entityUserAssId'] = assObj[0].entityUserAssId;
                            newObj['entityAssId'] = assObj[0].entityAssId;
                            newObj['standard'] = assObj[0].standard;
                            newObj['dcfId'] = refineddata[0].dcfId
                            if (items['Null Report(Yes/No)'].toString().toLowerCase().trim() === 'no') {
                                if (newObj['standard']) {
                                    let stdindex = JSON.parse(JSON.stringify(stdlist)).findIndex(
                                        (i) => i.id === newObj['standard']
                                    );

                                    if (stdindex !== -1) {
                                        if (JSON.parse(JSON.stringify(stdlist))[stdindex].newEfCategories !== undefined) {
                                            let cat_index = JSON.parse(JSON.stringify(stdlist))[stdindex].newEfCategories.findIndex((i) => i.id === 1);
                                            let category = JSON.parse(JSON.stringify(stdlist))[stdindex].newEfCategories[cat_index].newEfSubcategory1s;

                                            if (cat_index !== -1) {
                                                let index = -1;
                                                if (items['Category of Fuel Used']) {
                                                    index = category.findIndex(
                                                        (i) => i.title.trim().toLowerCase() === items['Category of Fuel Used'].toString().trim().toLowerCase()
                                                    );
                                                }
                                                if (
                                                    items['Category of Fuel Used'] &&
                                                    items['Category of Fuel Used'].toString().trim().length !== 0 &&
                                                    items['Fuel Used'] !== null &&
                                                    items['Fuel Used'].trim().length !== 0 &&
                                                    items['Unit of Measurement'] &&
                                                    items['Unit of Measurement'].toString().trim().length !== 0 &&
                                                    (index !== -1) &&
                                                    items['Quantity of Fuel Consumed'] !== null &&
                                                    items['Quantity of Fuel Consumed'] !== undefined &&
                                                    parseFloat(items['Quantity of Fuel Consumed'].toString()) >= 0 && (parseFloat(items['Quantity of Fuel Consumed'].toString()).toString().trim().length === items['Quantity of Fuel Consumed'].toString().trim().length)

                                                ) {
                                                    const { result, id } = findMatchingIds(
                                                        category,
                                                        items['Category of Fuel Used'].toString(),
                                                        items['Fuel Used'].toString(),
                                                        items['Unit of Measurement'].toString()
                                                    );
                                                    if (result) {
                                                        local["DPA0130"] = id[0];
                                                        local["DPA0131"] = id[1];
                                                        local["DPA0132"] = id[2];
                                                        local["id"] = getID();
                                                        local["attachment"] = null;
                                                        local["DPA0336"] = parseFloat(items['Quantity of Fuel Consumed'].toString());
                                                        local["DPA0133"] = "";
                                                        local["DPA0134"] = "";
                                                        local['DPA0131B'] = 2
                                                        local["remarks"] = items['Remarks']
                                                        local['created_on'] = DateTime.utc()
                                                        local['created_by'] = login_data.id
                                                        if (local['remarks']) {


                                                            local['remarks_created_on'] = DateTime.utc()
                                                            local['remarks_created_by'] = login_data.id
                                                        }

                                                        newObj['response'] = [local];
                                                        newObj['excel_data'] = [items]
                                                        newObj['Attachment(Yes/No)-Default will be No'] = items['Attachment(Yes/No)-Default will be No'] ? items['Attachment(Yes/No)-Default will be No'].toString() : 'no'
                                                        local2.push(newObj);
                                                        res(newObj); // Resolve this promise with the newObj
                                                    } else {
                                                        rejected.push({ ...items, "Rejection Remarks": errorArray.join(',') })
                                                        res(rejected)

                                                    }
                                                } else {

                                                    rejected.push({ ...items, "Rejection Remarks": errorArray.join(',') })
                                                    res(rejected)
                                                }
                                            } else {
                                                rejected.push({ ...items, "Rejection Remarks": errorArray.join(',') })
                                                res(rejected) // Resolve even if not matching
                                            }
                                        } else {
                                            rejected.push({ ...items, "Rejection Remarks": errorArray.join(',') })
                                            res(rejected) // Resolve even if not matching
                                        }
                                    } else {
                                        rejected.push({ ...items, "Rejection Remarks": errorArray.join(',') })
                                        res(rejected) // Resolve even if not matching
                                    }
                                }
                            } else if (items['Null Report(Yes/No)'].toString().toLowerCase().trim() === 'yes' && (!items['Category of Fuel Used'] && !items['Fuel Used'])) {
                                newObj['edit'] = 1
                                newObj['response'] = []
                                newObj['excel_data'] = [items]
                                newObj['Attachment(Yes/No)-Default will be No'] = items['Attachment(Yes/No)-Default will be No'] ? items['Attachment(Yes/No)-Default will be No'].toString() : 'no'
                                res(newObj);
                                local2.push(newObj);
                            } else {

                                rejected.push({ ...items, "Rejection Remarks": errorArray.join(',') })
                                res(rejected)
                            }
                        } else {
                            console.log(assObj)
                            if (!found && assObj[0].id) {
                                let oldIndex = quantitativesubmission.find(i => i.id === assObj[0].id)
                                let refObjData = assObj.filter(i => i.refobj)
                                if (oldIndex && refObjData.length !== 0) {
                                    let refObj = refObjData[0].refobj
                                    console.log(refObjData[0])
                                    newObj['level'] = level.toString().toLowerCase().trim() === 'corporate' ? 0 : level.toString().toLowerCase().trim() === 'country' ? 1 : level.toString().toLowerCase().trim() === 'region' ? 2 : level.toString().toLowerCase().trim() === 'business unit' ? 3 : null;
                                    newObj['reporting_period'] = getRPLuxon(items['Reporting Period']);
                                    newObj['rp'] = items['Reporting Period'];
                                    newObj['locationId'] = newObj[`tier${newObj['level']}_id`]
                                    newObj['form_type'] = 1
                                    newObj['user_type'] = login_data.role === 'clientuser' ? 1 : login_data.role === 'clientadmin' ? 0 : 2;
                                    newObj['logs'] = [];
                                    newObj['submitted_by'] = login_data.id;
                                    newObj['submitted_on'] = dt;
                                    newObj['last_modified_on'] = dt;
                                    newObj['reporter_modified_on'] = dt;
                                    newObj['reporter_modified_by'] = login_data.id;
                                    newObj['last_modified_by'] = login_data.id;
                                    newObj['entityUserAssId'] = refObj.id;
                                    newObj['entityAssId'] = refObj.entityAssId;
                                    newObj['standard'] = refObj.standard;
                                    newObj['dcfId'] = refineddata[0].dcfId
                                    if (items['Null Report(Yes/No)'].toString().toLowerCase().trim() === 'no') {

                                        if (newObj['standard']) {
                                            let stdindex = JSON.parse(JSON.stringify(stdlist)).findIndex(
                                                (i) => i.id === newObj['standard']
                                            );

                                            if (stdindex !== -1) {
                                                console.log('1')
                                                if (JSON.parse(JSON.stringify(stdlist))[stdindex].newEfCategories !== undefined) {
                                                    let cat_index = JSON.parse(JSON.stringify(stdlist))[stdindex].newEfCategories.findIndex((i) => i.id === 1);
                                                    let category = JSON.parse(JSON.stringify(stdlist))[stdindex].newEfCategories[cat_index].newEfSubcategory1s;

                                                    if (cat_index !== -1) {
                                                        console.log('2')
                                                        let index = -1;
                                                        if (items['Category of Fuel Used']) {
                                                            index = category.findIndex(
                                                                (i) => i.title.trim().toLowerCase() === items['Category of Fuel Used'].toString().trim().toLowerCase()
                                                            );
                                                        }
                                                        if (
                                                            items['Category of Fuel Used'] &&
                                                            items['Category of Fuel Used'].toString().trim().length !== 0 &&
                                                            items['Fuel Used'] &&
                                                            items['Fuel Used'].trim().length !== 0 &&
                                                            items['Unit of Measurement'] &&
                                                            items['Unit of Measurement'].toString().trim().length !== 0 &&
                                                            (index !== -1) &&
                                                            items['Quantity of Fuel Consumed'] !== null &&
                                                            items['Quantity of Fuel Consumed'] !== undefined &&
                                                            parseFloat(items['Quantity of Fuel Consumed'].toString()) >= 0 && (parseFloat(items['Quantity of Fuel Consumed'].toString()).toString().trim().length === items['Quantity of Fuel Consumed'].toString().trim().length)
                                                        ) {
                                                            console.log('3')
                                                            const { result, id } = findMatchingIds(
                                                                category,
                                                                items['Category of Fuel Used'].toString(),
                                                                items['Fuel Used'].toString(),
                                                                items['Unit of Measurement'].toString()
                                                            );
                                                            if (result) {

                                                                local["DPA0130"] = id[0];
                                                                local["DPA0131"] = id[1];
                                                                local["DPA0132"] = id[2];
                                                                local["id"] = getID();
                                                                local["remarks"] = items['Remarks']
                                                                local["attachment"] = null;
                                                                local["DPA0336"] = parseFloat(items['Quantity of Fuel Consumed'].toString());
                                                                local["DPA0133"] = "";
                                                                local["DPA0134"] = "";
                                                                local['DPA0131B'] = 2
                                                                local['created_on'] = DateTime.utc()
                                                                local['created_by'] = login_data.id
                                                                if (local['remarks']) {


                                                                    local['remarks_created_on'] = DateTime.utc()
                                                                    local['remarks_created_by'] = login_data.id
                                                                }
                                                                newObj['id'] = oldIndex.id
                                                                newObj['response'] = [local];
                                                                newObj['excel_data'] = [items]
                                                                newObj['Attachment(Yes/No)-Default will be No'] = items['Attachment(Yes/No)-Default will be No'] ? items['Attachment(Yes/No)-Default will be No'].toString() : 'no'
                                                                local2.push(newObj);
                                                                res(newObj); // Resolve this promise with the newObj
                                                            } else {

                                                                rejected.push({ ...items, "Rejection Remarks": errorArray.join(',') })
                                                                res(rejected)
                                                            }
                                                        } else {

                                                            rejected.push({ ...items, "Rejection Remarks": errorArray.join(',') })
                                                            res(rejected)
                                                        }
                                                    } else {
                                                        rejected.push({ ...items, "Rejection Remarks": errorArray.join(',') })
                                                        res(rejected) // Resolve even if not matching
                                                    }
                                                } else {
                                                    rejected.push({ ...items, "Rejection Remarks": errorArray.join(',') })
                                                    res(rejected) // Resolve even if not matching
                                                }
                                            } else {
                                                rejected.push({ ...items, "Rejection Remarks": errorArray.join(',') })
                                                res(rejected) // Resolve even if not matching
                                            }
                                        }
                                    } else if (items['Null Report(Yes/No)'].toString().toLowerCase().trim() === 'yes' && (!items['Category of Fuel Used'] && !items['Fuel Used'])) {
                                        newObj['edit'] = 1
                                        newObj['id'] = oldIndex.id
                                        newObj['response'] = []
                                        newObj['excel_data'] = [items]
                                        newObj['Attachment(Yes/No)-Default will be No'] = items['Attachment(Yes/No)-Default will be No'] ? items['Attachment(Yes/No)-Default will be No'].toString() : 'no'
                                        res(newObj);

                                        local2.push(newObj);
                                    } else {

                                        rejected.push({ ...items, "Rejection Remarks": errorArray.join(',') })
                                        res(rejected)
                                    }

                                } else {

                                    rejected.push({ ...items, "Rejection Remarks": 'You are not assigned to this entity on this form' })
                                    res(rejected)
                                }
                            } else {

                                rejected.push({ ...items, "Rejection Remarks": 'You are not assigned to this entity on this form' })
                                res(rejected)
                            }

                        }
                    } else {



                        rejected.push({ ...items, "Rejection Remarks": errorArray.join(',') })
                        res(rejected)
                    }
                } else {


                    rejected.push({ ...items, "Rejection Remarks": errorArray.join(',') })
                    res(rejected)
                }
                res(rejected)
            } catch (e) {


                if (!e.found) {
                    rejected.push({ ...items, "Rejection Remarks": 'Ensure Imported Data Is For The Selected Year & Entities' })

                } else {
                    rejected.push({ ...items, "Rejection Remarks": 'Either Data Approved / Reviewed ' })
                }
                res(rejected)
                console.log(e)
            }

        }

        );

        return promise



    } else if (dcfId === 305) {
        let promise = new Promise(async (res, rej) => {
            try {

                if (!items['Entity'] || !items['Entity'].toString().trim().length) {
                    errorArray.push('Entity Field is Required')
                }
                if (!items['Entity Level'] || !items['Entity Level'].toString().trim().length) {
                    errorArray.push('Entity Level Field is Required')
                }
                if (!items['Null Report(Yes/No)'] || !items['Null Report(Yes/No)'].toString().trim().length) {
                    errorArray.push('Null Report(Yes/No) Field is Required')
                }
                if (!items['Reporting Period'] || !items['Reporting Period'].toString().trim().length) {
                    errorArray.push('Reporting Period Field is Required')
                } else if (items['Reporting Period'].toString().trim().length) {
                    if (!validateDateString(items['Reporting Period'])) {
                        errorArray.push('Reporting Period Field Format is Incorrect')
                    }
                }
                if (!items['Type of Gas Refilled'] || !items['Type of Gas Refilled'].toString().trim().length) {
                    errorArray.push('Type of Gas Refilled Field is Required')
                }

                if (!items['Type of AC/ Chiller / Freezer used'] || !items['Type of AC/ Chiller / Freezer used'].toString().trim().length) {
                    errorArray.push('Type of AC/ Chiller / Freezer used Field is Required')
                }


                if ((!items['Gas Refilled (kg)'] && items['Gas Refilled (kg)'] !== 0) || !items['Gas Refilled (kg)'].toString().trim().length) {
                    errorArray.push('Gas Refilled (kg) Field is Required')
                } else if (items['Gas Refilled (kg)'].toString().trim().length) {
                    console.log(items['Gas Refilled (kg)'], parseFloat(items['Gas Refilled (kg)'].toString()).toString().trim().length, items['Gas Refilled (kg)'].toString().trim().length)
                    if (items['Gas Refilled (kg)'] !== null &&
                        items['Gas Refilled (kg)'] !== undefined &&
                        parseFloat(items['Gas Refilled (kg)'].toString()) >= 0 && (parseFloat(items['Gas Refilled (kg)'].toString()).toString().trim().length === items['Gas Refilled (kg)'].toString().trim().length)
                    ) {
                        errorArray.push('Gas Refilled (kg) Field Accepts Only valid Number Input as Either Rounded or Fraction value (Dont use , or other notation)')
                    } else if (!parseFloat(items['Gas Refilled (kg)'].toString().trim()) && parseFloat(items['Gas Refilled (kg)'].toString().trim()) !== 0) {
                        errorArray.push('Gas Refilled (kg) Field Requires Either Rounded or Fraction value')
                    }
                }
                if (items['Entity'] && items['Entity'].toString().trim().length && items['Entity Level'] && items['Entity Level'].toString().trim().length && items['Reporting Period'] && items['Reporting Period'].toString().trim().length && validateDateString(items['Reporting Period']) && items['Type of AC/ Chiller / Freezer used'] && items['Type of AC/ Chiller / Freezer used'].toString().trim().length && items['Type of Gas Refilled'] && items['Type of Gas Refilled'].toString().trim().length) {
                    let level = items['Entity Level'];

                    const { result, obj } = getCoverageIdByText({
                        entity: items['Entity'],
                        level: level.toString().toLowerCase().trim() === 'corporate' ? 0 : level.toString().toLowerCase().trim() === 'country' ? 1 : level.toString().toLowerCase().trim() === 'region' ? 2 : level.toString().toLowerCase().trim() === 'business unit' ? 3 : null
                    }, rawsitelist);
                    const { found, assObj } = await getAssignment(items['Reporting Period'], obj, errorArray, refineddata).then((assObj) => { return assObj })
                    console.log(found, 'found')
                    if (found) {
                        let stdindex = JSON.parse(JSON.stringify(stdlist)).findIndex(
                            (i) => i.id === assObj[0].standard
                        );
                        if (stdindex !== -1) {
                            if (JSON.parse(JSON.stringify(stdlist))[stdindex].newEfCategories !== undefined) {
                                let cat_index = JSON.parse(JSON.stringify(stdlist))[stdindex].newEfCategories.findIndex((i) => i.id === 5);
                                let category = JSON.parse(JSON.stringify(stdlist))[stdindex].newEfCategories[cat_index].newEfSubcategory1s;

                                const { result, id } = findMatchingIds(
                                    category,
                                    items['Type of Gas Refilled'].toString()
                                );
                                if (!result) {
                                    errorArray.push('Type of Gas Refilled is not found')

                                }

                            }
                        } else {
                            errorArray.push('Inform Admin to check selected Standard for this Enity on this Form ')

                        }
                    } else if (!found && assObj[0].id) {
                        let oldIndex = quantitativesubmission.find(i => i.id === assObj[0].id)
                        let refObjData = assObj.filter(i => i.refobj)
                        let refObj = refObjData[0].refobj
                        let stdindex = JSON.parse(JSON.stringify(stdlist)).findIndex(
                            (i) => i.id === refObj.standard
                        );
                        if (stdindex !== -1) {
                            if (JSON.parse(JSON.stringify(stdlist))[stdindex].newEfCategories !== undefined) {
                                let cat_index = JSON.parse(JSON.stringify(stdlist))[stdindex].newEfCategories.findIndex((i) => i.id === 5);
                                let category = JSON.parse(JSON.stringify(stdlist))[stdindex].newEfCategories[cat_index].newEfSubcategory1s;

                                const { result, id } = findMatchingIds(
                                    category,
                                    items['Type of Gas Refilled'].toString()

                                );
                                if (!result) {
                                    errorArray.push('Type of Gas Refilled is not found')

                                }

                            }
                        } else {
                            errorArray.push('Inform Admin to check selected Standard for this Enity on this Form ')

                        }




                    }
                }

                let local = {};
                let newObj = {};
                let level = items['Entity Level'];
                if (level && items['Entity'] && items['Null Report(Yes/No)'] && items['Reporting Period'] && validateDateString(items['Reporting Period'])) {
                    const { result, obj } = getCoverageIdByText({
                        entity: items['Entity'],
                        level: level.toString().toLowerCase().trim() === 'corporate' ? 0 : level.toString().toLowerCase().trim() === 'country' ? 1 : level.toString().toLowerCase().trim() === 'region' ? 2 : level.toString().toLowerCase().trim() === 'business unit' ? 3 : null
                    }, rawsitelist);

                    if (result) {
                        newObj = { ...newObj, ...obj };
                        let dt = DateTime.utc();
                        const { found, assObj } = await getAssignment(items['Reporting Period'], obj, null, refineddata).then((assObj) => { return assObj });
                        console.log(found, assObj)
                        if (found) {
                            newObj['level'] = level.toString().toLowerCase().trim() === 'corporate' ? 0 : level.toString().toLowerCase().trim() === 'country' ? 1 : level.toString().toLowerCase().trim() === 'region' ? 2 : level.toString().toLowerCase().trim() === 'business unit' ? 3 : null;
                            newObj['reporting_period'] = getRPLuxon(items['Reporting Period']);
                            newObj['rp'] = items['Reporting Period'];
                            newObj['locationId'] = newObj[`tier${newObj['level']}_id`]
                            newObj['form_type'] = 1
                            newObj['user_type'] = login_data.role === 'clientuser' ? 1 : login_data.role === 'clientadmin' ? 0 : 2;
                            newObj['logs'] = [];
                            newObj['submitted_by'] = login_data.id;
                            newObj['submitted_on'] = dt;
                            newObj['last_modified_on'] = dt;
                            newObj['reporter_modified_on'] = dt;
                            newObj['reporter_modified_by'] = login_data.id;
                            newObj['last_modified_by'] = login_data.id;
                            newObj['entityUserAssId'] = assObj[0].entityUserAssId;
                            newObj['entityAssId'] = assObj[0].entityAssId;
                            newObj['standard'] = assObj[0].standard;
                            newObj['dcfId'] = refineddata[0].dcfId
                            if (items['Null Report(Yes/No)'].toString().toLowerCase().trim() === 'no') {
                                if (newObj['standard']) {
                                    let stdindex = JSON.parse(JSON.stringify(stdlist)).findIndex(
                                        (i) => i.id === newObj['standard']
                                    );

                                    if (stdindex !== -1) {
                                        if (JSON.parse(JSON.stringify(stdlist))[stdindex].newEfCategories !== undefined) {
                                            let cat_index = JSON.parse(JSON.stringify(stdlist))[stdindex].newEfCategories.findIndex((i) => i.id === 5);
                                            let category = JSON.parse(JSON.stringify(stdlist))[stdindex].newEfCategories[cat_index].newEfSubcategory1s;

                                            if (cat_index !== -1) {
                                                let index = -1;
                                                if (items['Type of Gas Refilled']) {
                                                    index = category.findIndex(
                                                        (i) => i.title.trim().toLowerCase() === items['Type of Gas Refilled'].toString().trim().toLowerCase()
                                                    );
                                                }
                                                if (
                                                    items['Type of Gas Refilled'] &&
                                                    items['Type of Gas Refilled'].toString().trim().length !== 0 &&
                                                    items['Type of AC/ Chiller / Freezer used'] !== null &&
                                                    items['Type of AC/ Chiller / Freezer used'].trim().length !== 0 &&

                                                    (index !== -1) &&
                                                    items['Gas Refilled (kg)'] !== null &&
                                                    items['Gas Refilled (kg)'] !== undefined &&
                                                    parseFloat(items['Gas Refilled (kg)'].toString()) >= 0 && (parseFloat(items['Gas Refilled (kg)'].toString()).toString().trim().length === items['Gas Refilled (kg)'].toString().trim().length)

                                                ) {
                                                    const { result, id } = findMatchingIds(
                                                        category,
                                                        items['Type of Gas Refilled'].toString()
                                                    );
                                                    if (result) {
                                                        local["DPA0136"] = id[0];
                                                        local["DPA0138"] = parseFloat(items['Gas Refilled (kg)'].toString())
                                                        if (items['Type of AC/Chiller/Freezer used'] && items['Type of AC/Chiller/Freezer used'].toString().trim().length) {
                                                            local["DPA0135"] = items['Type of AC/Chiller/Freezer used']
                                                        } else {
                                                            local["DPA0135"] = ''
                                                        }
                                                        local["attachment"] = null;
                                                        local["id"] = getID();
                                                        local["remarks"] = items['Remarks']

                                                        if (local['remarks']) {


                                                            local['remarks_created_on'] = DateTime.utc()
                                                            local['remarks_created_by'] = login_data.id
                                                        }

                                                        newObj['response'] = [local];
                                                        newObj['excel_data'] = [items]
                                                        newObj['Attachment(Yes/No)-Default will be No'] = items['Attachment(Yes/No)-Default will be No'] ? items['Attachment(Yes/No)-Default will be No'].toString() : 'no'
                                                        local2.push(newObj);
                                                        res(newObj); // Resolve this promise with the newObj
                                                    } else {
                                                        rejected.push({ ...items, "Rejection Remarks": errorArray.join(',') })
                                                        res(rejected)

                                                    }
                                                } else {

                                                    rejected.push({ ...items, "Rejection Remarks": errorArray.join(',') })
                                                    res(rejected)
                                                }
                                            } else {
                                                rejected.push({ ...items, "Rejection Remarks": errorArray.join(',') })
                                                res(rejected) // Resolve even if not matching
                                            }
                                        } else {
                                            rejected.push({ ...items, "Rejection Remarks": errorArray.join(',') })
                                            res(rejected) // Resolve even if not matching
                                        }
                                    } else {
                                        rejected.push({ ...items, "Rejection Remarks": errorArray.join(',') })
                                        res(rejected) // Resolve even if not matching
                                    }
                                }
                            } else if (items['Null Report(Yes/No)'].toString().toLowerCase().trim() === 'yes' && (!items['Type of Gas Refilled'] && !items['Type of AC/ Chiller / Freezer used'])) {
                                newObj['edit'] = 1
                                newObj['response'] = []
                                newObj['excel_data'] = [items]
                                newObj['Attachment(Yes/No)-Default will be No'] = items['Attachment(Yes/No)-Default will be No'] ? items['Attachment(Yes/No)-Default will be No'].toString() : 'no'
                                res(newObj);
                                local2.push(newObj);
                            } else {

                                rejected.push({ ...items, "Rejection Remarks": errorArray.join(',') })
                                res(rejected)
                            }
                        } else {
                            console.log(assObj)
                            if (!found && assObj[0].id) {
                                let oldIndex = quantitativesubmission.find(i => i.id === assObj[0].id)
                                let refObjData = assObj.filter(i => i.refobj)
                                if (oldIndex && refObjData.length !== 0) {
                                    let refObj = refObjData[0].refobj
                                    console.log(refObjData[0])
                                    newObj['level'] = level.toString().toLowerCase().trim() === 'corporate' ? 0 : level.toString().toLowerCase().trim() === 'country' ? 1 : level.toString().toLowerCase().trim() === 'region' ? 2 : level.toString().toLowerCase().trim() === 'business unit' ? 3 : null;
                                    newObj['reporting_period'] = getRPLuxon(items['Reporting Period']);
                                    newObj['rp'] = items['Reporting Period'];
                                    newObj['locationId'] = newObj[`tier${newObj['level']}_id`]
                                    newObj['form_type'] = 1
                                    newObj['user_type'] = login_data.role === 'clientuser' ? 1 : login_data.role === 'clientadmin' ? 0 : 2;
                                    newObj['logs'] = [];
                                    newObj['submitted_by'] = login_data.id;
                                    newObj['submitted_on'] = dt;
                                    newObj['last_modified_on'] = dt;
                                    newObj['reporter_modified_on'] = dt;
                                    newObj['reporter_modified_by'] = login_data.id;
                                    newObj['last_modified_by'] = login_data.id;
                                    newObj['entityUserAssId'] = refObj.id;
                                    newObj['entityAssId'] = refObj.entityAssId;
                                    newObj['standard'] = refObj.standard;
                                    newObj['dcfId'] = refineddata[0].dcfId
                                    if (items['Null Report(Yes/No)'].toString().toLowerCase().trim() === 'no') {

                                        if (newObj['standard']) {
                                            let stdindex = JSON.parse(JSON.stringify(stdlist)).findIndex(
                                                (i) => i.id === newObj['standard']
                                            );

                                            if (stdindex !== -1) {
                                                console.log('1')
                                                if (JSON.parse(JSON.stringify(stdlist))[stdindex].newEfCategories !== undefined) {
                                                    let cat_index = JSON.parse(JSON.stringify(stdlist))[stdindex].newEfCategories.findIndex((i) => i.id === 5);
                                                    let category = JSON.parse(JSON.stringify(stdlist))[stdindex].newEfCategories[cat_index].newEfSubcategory1s;

                                                    if (cat_index !== -1) {
                                                        console.log('2')
                                                        let index = -1;
                                                        if (items['Type of Gas Refilled']) {
                                                            index = category.findIndex(
                                                                (i) => i.title.trim().toLowerCase() === items['Type of Gas Refilled'].toString().trim().toLowerCase()
                                                            );
                                                        }
                                                        if (
                                                            items['Type of Gas Refilled'] &&
                                                            items['Type of Gas Refilled'].toString().trim().length !== 0 &&
                                                            items['Type of AC/ Chiller / Freezer used'] &&
                                                            items['Type of AC/ Chiller / Freezer used'].trim().length !== 0 &&

                                                            (index !== -1) &&
                                                            items['Gas Refilled (kg)'] !== null &&
                                                            items['Gas Refilled (kg)'] !== undefined &&
                                                            parseFloat(items['Gas Refilled (kg)'].toString()) >= 0 && (parseFloat(items['Gas Refilled (kg)'].toString()).toString().trim().length === items['Gas Refilled (kg)'].toString().trim().length)
                                                        ) {
                                                            console.log('3')
                                                            const { result, id } = findMatchingIds(
                                                                category,
                                                                items['Type of Gas Refilled'].toString()
                                                            );
                                                            if (result) {

                                                                local["DPA0136"] = id[0];
                                                                local["DPA0138"] = parseFloat(items['Gas Refilled (kg)'].toString())
                                                                if (items['Type of AC/Chiller/Freezer used'] && items['Type of AC/Chiller/Freezer used'].toString().trim().length) {
                                                                    local["DPA0135"] = items['Type of AC/Chiller/Freezer used']
                                                                } else {
                                                                    local["DPA0135"] = ''
                                                                }


                                                                local["remarks"] = items['Remarks']

                                                                local["attachment"] = null;

                                                                if (local['remarks']) {


                                                                    local['remarks_created_on'] = DateTime.utc()
                                                                    local['remarks_created_by'] = login_data.id
                                                                }
                                                                newObj['id'] = oldIndex.id
                                                                newObj['response'] = [local];
                                                                newObj['excel_data'] = [items]
                                                                newObj['Attachment(Yes/No)-Default will be No'] = items['Attachment(Yes/No)-Default will be No'] ? items['Attachment(Yes/No)-Default will be No'].toString() : 'no'
                                                                local2.push(newObj);
                                                                res(newObj); // Resolve this promise with the newObj
                                                            } else {

                                                                rejected.push({ ...items, "Rejection Remarks": errorArray.join(',') })
                                                                res(rejected)
                                                            }
                                                        } else {

                                                            rejected.push({ ...items, "Rejection Remarks": errorArray.join(',') })
                                                            res(rejected)
                                                        }
                                                    } else {
                                                        rejected.push({ ...items, "Rejection Remarks": errorArray.join(',') })
                                                        res(rejected) // Resolve even if not matching
                                                    }
                                                } else {
                                                    rejected.push({ ...items, "Rejection Remarks": errorArray.join(',') })
                                                    res(rejected) // Resolve even if not matching
                                                }
                                            } else {
                                                rejected.push({ ...items, "Rejection Remarks": errorArray.join(',') })
                                                res(rejected) // Resolve even if not matching
                                            }
                                        }
                                    } else if (items['Null Report(Yes/No)'].toString().toLowerCase().trim() === 'yes' && (!items['Type of Gas Refilled'] && !items['Type of AC/ Chiller / Freezer used'])) {
                                        newObj['edit'] = 1
                                        newObj['id'] = oldIndex.id
                                        newObj['response'] = []
                                        newObj['excel_data'] = [items]
                                        newObj['Attachment(Yes/No)-Default will be No'] = items['Attachment(Yes/No)-Default will be No'] ? items['Attachment(Yes/No)-Default will be No'].toString() : 'no'
                                        res(newObj);

                                        local2.push(newObj);
                                    } else {

                                        rejected.push({ ...items, "Rejection Remarks": errorArray.join(',') })
                                        res(rejected)
                                    }

                                } else {

                                    rejected.push({ ...items, "Rejection Remarks": 'You are not assigned to this entity on this form' })
                                    res(rejected)
                                }
                            } else {

                                rejected.push({ ...items, "Rejection Remarks": 'You are not assigned to this entity on this form' })
                                res(rejected)
                            }

                        }
                    } else {



                        rejected.push({ ...items, "Rejection Remarks": errorArray.join(',') })
                        res(rejected)
                    }
                } else {


                    rejected.push({ ...items, "Rejection Remarks": errorArray.join(',') })
                    res(rejected)
                }
                res(rejected)
            } catch (e) {


                if (!e.found) {
                    rejected.push({ ...items, "Rejection Remarks": 'Ensure Imported Data Is For The Selected Year & Entities' })

                } else {
                    rejected.push({ ...items, "Rejection Remarks": 'Either Data Approved / Reviewed ' })
                }
                res(rejected)
                console.log(e)
            }

        }

        );

        return promise



    } else if (dcfId === 296) {
        let promise = new Promise(async (res, rej) => {
            try {

                if (!items['Entity'] || !items['Entity'].toString().trim().length) {
                    errorArray.push('Entity Field is Required')
                }
                if (!items['Entity Level'] || !items['Entity Level'].toString().trim().length) {
                    errorArray.push('Entity Level Field is Required')
                }
                if (!items['Null Report(Yes/No)'] || !items['Null Report(Yes/No)'].toString().trim().length) {
                    errorArray.push('Null Report(Yes/No) Field is Required')
                }
                if (!items['Reporting Period'] || !items['Reporting Period'].toString().trim().length) {
                    errorArray.push('Reporting Period Field is Required')
                } else if (items['Reporting Period'].toString().trim().length) {
                    if (!validateDateString(items['Reporting Period'])) {
                        errorArray.push('Reporting Period Field Format is Incorrect')
                    }
                }
                if (typeof items['Senior Management-Male'] !== 'number') {
                    errorArray.push('Senior Management-Male Field is Required')
                }
                if (typeof items['Senior Management-Female'] !== 'number') {
                    errorArray.push('Senior Management-Female Field is Required')
                }
                if (typeof items['Middle Management-Male'] !== 'number') {
                    errorArray.push('Middle Management-Male Field is Required')
                }
                if (typeof items['Middle Management-Female'] !== 'number') {
                    errorArray.push('Middle Management-Female Field is Required')
                }
                if (typeof items['Non-management-Male'] !== 'number') {
                    errorArray.push('Non-management-Male Field is Required')
                }
                if (typeof items['Non-management-Female'] !== 'number') {
                    errorArray.push('Non-management-Female Field is Required')
                }



                let local = {};
                let newObj = {};
                let level = items['Entity Level'];
                if (level && items['Entity'] && items['Null Report(Yes/No)'] && items['Reporting Period'] && validateDateString(items['Reporting Period'])) {
                    const { result, obj } = getCoverageIdByText({
                        entity: items['Entity'],
                        level: level.toString().toLowerCase().trim() === 'corporate' ? 0 : level.toString().toLowerCase().trim() === 'country' ? 1 : level.toString().toLowerCase().trim() === 'region' ? 2 : level.toString().toLowerCase().trim() === 'business unit' ? 3 : null
                    }, rawsitelist);

                    if (result) {
                        newObj = { ...newObj, ...obj };
                        let dt = DateTime.utc();
                        const { found, assObj } = await getAssignment(items['Reporting Period'], obj, null, refineddata).then((assObj) => { return assObj });
                        console.log(found, assObj)
                        if (found) {
                            newObj['level'] = level.toString().toLowerCase().trim() === 'corporate' ? 0 : level.toString().toLowerCase().trim() === 'country' ? 1 : level.toString().toLowerCase().trim() === 'region' ? 2 : level.toString().toLowerCase().trim() === 'business unit' ? 3 : null;
                            newObj['reporting_period'] = getRPLuxon(items['Reporting Period']);
                            newObj['rp'] = items['Reporting Period'];
                            newObj['locationId'] = newObj[`tier${newObj['level']}_id`]
                            newObj['form_type'] = 1
                            newObj['user_type'] = login_data.role === 'clientuser' ? 1 : login_data.role === 'clientadmin' ? 0 : 2;
                            newObj['logs'] = [];
                            newObj['submitted_by'] = login_data.id;
                            newObj['submitted_on'] = dt;
                            newObj['last_modified_on'] = dt;
                            newObj['reporter_modified_on'] = dt;
                            newObj['reporter_modified_by'] = login_data.id;
                            newObj['last_modified_by'] = login_data.id;
                            newObj['entityUserAssId'] = assObj[0].entityUserAssId;
                            newObj['entityAssId'] = assObj[0].entityAssId;
                            newObj['standard'] = assObj[0].standard;
                            newObj['dcfId'] = refineddata[0].dcfId
                            if (items['Null Report(Yes/No)'].toString().toLowerCase().trim() === 'no') {
                                if (typeof items['Senior Management-Male'] === 'number' && typeof items['Senior Management-Female'] === 'number' && typeof items['Middle Management-Male'] === 'number'
                                    && typeof items['Middle Management-Female'] === 'number' && typeof items['Non-management-Male'] === 'number' && typeof items['Non-management-Female'] === 'number'
                                ) {
                                    local['DPR0138'] = items['Senior Management-Male'] ? items['Senior Management-Male'] : 0
                                    local['DPR0139'] = items['Senior Management-Female'] ? items['Senior Management-Female'] : 0
                                    local['DPR0140'] = items['Middle Management-Male'] ? items['Middle Management-Male'] : 0
                                    local['DPR0141'] = items['Middle Management-Female'] ? items['Middle Management-Female'] : 0
                                    local['DPKN074'] = items['Non-management-Male'] ? items['Non-management-Male'] : 0
                                    local['DPKN075'] = items['Non-management-Female'] ? items['Non-management-Female'] : 0
                                    local2.push(newObj);
                                    newObj['response'] = [local];
                                    newObj['excel_data'] = [items]
                                    res(newObj);
                                } else {
                                    rejected.push({ ...items, "Rejection Remarks": errorArray.join(',') })
                                    res(rejected)
                                }

                            } else if (items['Null Report(Yes/No)'].toString().toLowerCase().trim() === 'yes' && (!items['Type of Gas Refilled'] && !items['Type of AC/ Chiller / Freezer used'])) {
                                newObj['edit'] = 1
                                newObj['response'] = []
                                newObj['excel_data'] = [items]
                                newObj['Attachment(Yes/No)-Default will be No'] = items['Attachment(Yes/No)-Default will be No'] ? items['Attachment(Yes/No)-Default will be No'].toString() : 'no'
                                res(newObj);
                                local2.push(newObj);
                            } else {

                                rejected.push({ ...items, "Rejection Remarks": errorArray.join(',') })
                                res(rejected)
                            }
                        } else if (!found && assObj[0].id) {
                            let oldIndex = quantitativesubmission.find(i => i.id === assObj[0].id)
                            let refObjData = assObj.filter(i => i.refobj)
                            if (oldIndex && refObjData.length !== 0) {
                                let refObj = refObjData[0].refobj
                                console.log(refObjData[0])
                                newObj['level'] = level.toString().toLowerCase().trim() === 'corporate' ? 0 : level.toString().toLowerCase().trim() === 'country' ? 1 : level.toString().toLowerCase().trim() === 'region' ? 2 : level.toString().toLowerCase().trim() === 'business unit' ? 3 : null;
                                newObj['reporting_period'] = getRPLuxon(items['Reporting Period']);
                                newObj['rp'] = items['Reporting Period'];
                                newObj['locationId'] = newObj[`tier${newObj['level']}_id`]
                                newObj['form_type'] = 1
                                newObj['user_type'] = login_data.role === 'clientuser' ? 1 : login_data.role === 'clientadmin' ? 0 : 2;
                                newObj['logs'] = [];
                                newObj['submitted_by'] = login_data.id;
                                newObj['submitted_on'] = dt;
                                newObj['last_modified_on'] = dt;
                                newObj['reporter_modified_on'] = dt;
                                newObj['reporter_modified_by'] = login_data.id;
                                newObj['last_modified_by'] = login_data.id;
                                newObj['entityUserAssId'] = refObj.id;
                                newObj['entityAssId'] = refObj.entityAssId;
                                newObj['standard'] = refObj.standard;
                                newObj['dcfId'] = refineddata[0].dcfId
                                if (items['Null Report(Yes/No)'].toString().toLowerCase().trim() === 'no') {
                                    if (typeof items['Senior Management-Male'] === 'number' && typeof items['Senior Management-Female'] === 'number' && typeof items['Middle Management-Male'] === 'number'
                                        && typeof items['Middle Management-Female'] === 'number' && typeof items['Non-management-Male'] === 'number' && typeof items['Non-management-Female'] === 'number'
                                    ) {
                                        local['DPR0138'] = items['Senior Management-Male'] ? items['Senior Management-Male'] : 0
                                        local['DPR0139'] = items['Senior Management-Female'] ? items['Senior Management-Female'] : 0
                                        local['DPR0140'] = items['Middle Management-Male'] ? items['Middle Management-Male'] : 0
                                        local['DPR0141'] = items['Middle Management-Female'] ? items['Middle Management-Female'] : 0
                                        local['DPKN074'] = items['Non-management-Male'] ? items['Non-management-Male'] : 0
                                        local['DPKN075'] = items['Non-management-Female'] ? items['Non-management-Female'] : 0
                                        newObj['id'] = oldIndex.id
                                        local2.push(newObj);

                                        newObj['response'] = [local];
                                        newObj['excel_data'] = [items]
                                        res(newObj);
                                    } else {
                                        rejected.push({ ...items, "Rejection Remarks": errorArray.join(',') })
                                        res(rejected)
                                    }

                                } else if (items['Null Report(Yes/No)'].toString().toLowerCase().trim() === 'yes' && (!items['Type of Gas Refilled'] && !items['Type of AC/ Chiller / Freezer used'])) {
                                    newObj['edit'] = 1
                                    newObj['id'] = oldIndex.id
                                    newObj['response'] = []
                                    newObj['excel_data'] = [items]
                                    newObj['Attachment(Yes/No)-Default will be No'] = items['Attachment(Yes/No)-Default will be No'] ? items['Attachment(Yes/No)-Default will be No'].toString() : 'no'
                                    res(newObj);

                                    local2.push(newObj);
                                } else {

                                    rejected.push({ ...items, "Rejection Remarks": errorArray.join(',') })
                                    res(rejected)
                                }

                            } else {

                                rejected.push({ ...items, "Rejection Remarks": 'You are not assigned to this entity on this form' })
                                res(rejected)
                            }
                        } else {

                            rejected.push({ ...items, "Rejection Remarks": 'You are not assigned to this entity on this form' })
                            res(rejected)
                        }

                    } else {



                        rejected.push({ ...items, "Rejection Remarks": errorArray.join(',') })
                        res(rejected)
                    }
                } else {


                    rejected.push({ ...items, "Rejection Remarks": errorArray.join(',') })
                    res(rejected)
                }
                res(rejected)
            } catch (e) {


                if (!e.found) {
                    rejected.push({ ...items, "Rejection Remarks": 'Ensure Imported Data Is For The Selected Year & Entities' })

                } else {
                    rejected.push({ ...items, "Rejection Remarks": 'Either Data Approved / Reviewed ' })
                }
                res(rejected)
                console.log(e)
            }

        }

        );

        return promise



    } else if (dcfId === 284) {

        let promise = new Promise(async (res, rej) => {
            try {

                if (!items['Entity'] || !items['Entity'].toString().trim().length) {
                    errorArray.push('Entity Field is Required')
                }
                if (!items['Entity Level'] || !items['Entity Level'].toString().trim().length) {
                    errorArray.push('Entity Level Field is Required')
                }
                if (!items['Null Report(Yes/No)'] || !items['Null Report(Yes/No)'].toString().trim().length) {
                    errorArray.push('Null Report(Yes/No) Field is Required')
                }
                if (!items['Reporting Period'] || !items['Reporting Period'].toString().trim().length) {
                    errorArray.push('Reporting Period Field is Required')
                } else if (items['Reporting Period'].toString().trim().length) {
                    if (!validateDateString(items['Reporting Period'])) {
                        errorArray.push('Reporting Period Field Format is Incorrect')
                    }
                }
                let count = 0
                if (typeof items['New Hires Employees who are < 30 years old (Male)'] !== 'number') {
                    count++
                    errorArray.push('New Hires Employees who are < 30 years old (Male) Field is Required')
                }
                if (typeof items['New Hires Employees who are < 30 years old (Female)'] !== 'number') {
                    count++
                    errorArray.push('New Hires Employees who are < 30 years old (Female) Field is Required')
                }
                if (typeof items['New Hires Employees who are 30-50 years old (Female)'] !== 'number') {
                    count++
                    errorArray.push('New Hires Employees who are 30-50 years old (Female) Field is Required')
                }
                if (typeof items['New Hires Employees who are 30-50 years old (Male)'] !== 'number') {
                    count++
                    errorArray.push('New Hires Employees who are 30-50 years old (Male) Field is Required')
                }
                if (typeof items['New Hires Employees who are >50 years old (Male)'] !== 'number') {
                    count++
                    errorArray.push('New Hires Employees who are >50 years old (Male) Field is Required')
                }
                if (typeof items['New Hires Employees who are >50 years old (Female)'] !== 'number') {
                    count++
                    errorArray.push('New Hires Employees who are >50 years old (Female) Field is Required')
                }
                if (typeof items['Employee Turnover Employees who are < 30 years old (Male)'] !== 'number') {
                    count++
                    errorArray.push('Employee Turnover Employees who are < 30 years old (Male) Field is Required')
                }
                if (typeof items['Employee Turnover Employees who are < 30 years old (Female)'] !== 'number') {
                    count++
                    errorArray.push('Employee Turnover Employees who are < 30 years old (Female) Field is Required')
                }
                if (typeof items['Employee Turnover Employees who are 30-50 years old (Female)'] !== 'number') {
                    count++
                    errorArray.push('Employee Turnover Employees who are 30-50 years old (Female) Field is Required')
                }
                if (typeof items['Employee Turnover Employees who are 30-50 years old (Male)'] !== 'number') {
                    count++
                    errorArray.push('Employee Turnover Employees who are 30-50 years old (Male) Field is Required')
                }
                if (typeof items['Employee Turnover Employees who are >50 years old (Male)'] !== 'number') {
                    count++
                    errorArray.push('Employee Turnover Employees who are >50 years old (Male) Field is Required')
                }
                if (typeof items['Employee Turnover Employees who are >50 years old (Female)'] !== 'number') {
                    count++
                    errorArray.push('Employee Turnover Employees who are >50 years old (Female) Field is Required')
                }


                let local = {};
                let newObj = {};
                let level = items['Entity Level'];
                if (level && items['Entity'] && items['Null Report(Yes/No)'] && items['Reporting Period'] && validateDateString(items['Reporting Period'])) {
                    const { result, obj } = getCoverageIdByText({
                        entity: items['Entity'],
                        level: level.toString().toLowerCase().trim() === 'corporate' ? 0 : level.toString().toLowerCase().trim() === 'country' ? 1 : level.toString().toLowerCase().trim() === 'region' ? 2 : level.toString().toLowerCase().trim() === 'business unit' ? 3 : null
                    }, rawsitelist);

                    if (result) {
                        newObj = { ...newObj, ...obj };
                        let dt = DateTime.utc();
                        const { found, assObj } = await getAssignment(items['Reporting Period'], obj, null, refineddata).then((assObj) => { return assObj });
                        console.log(found, assObj)
                        if (found) {
                            newObj['level'] = level.toString().toLowerCase().trim() === 'corporate' ? 0 : level.toString().toLowerCase().trim() === 'country' ? 1 : level.toString().toLowerCase().trim() === 'region' ? 2 : level.toString().toLowerCase().trim() === 'business unit' ? 3 : null;
                            newObj['reporting_period'] = getRPLuxon(items['Reporting Period']);
                            newObj['rp'] = items['Reporting Period'];
                            newObj['locationId'] = newObj[`tier${newObj['level']}_id`]
                            newObj['form_type'] = 1
                            newObj['user_type'] = login_data.role === 'clientuser' ? 1 : login_data.role === 'clientadmin' ? 0 : 2;
                            newObj['logs'] = [];
                            newObj['submitted_by'] = login_data.id;
                            newObj['submitted_on'] = dt;
                            newObj['last_modified_on'] = dt;
                            newObj['reporter_modified_on'] = dt;
                            newObj['reporter_modified_by'] = login_data.id;
                            newObj['last_modified_by'] = login_data.id;
                            newObj['entityUserAssId'] = assObj[0].entityUserAssId;
                            newObj['entityAssId'] = assObj[0].entityAssId;
                            newObj['standard'] = assObj[0].standard;
                            newObj['dcfId'] = refineddata[0].dcfId
                            if (items['Null Report(Yes/No)'].toString().toLowerCase().trim() === 'no') {
                                if (count === 0) {
                                    local['DPAN641'] = items['New Hires Employees who are < 30 years old (Male)'] ? items['New Hires Employees who are < 30 years old (Male)'] : 0
                                    local['DPAN642'] = items['New Hires Employees who are 30-50 years old (Male)'] ? items['New Hires Employees who are 30-50 years old (Male)'] : 0
                                    local['DPAN643'] = items['New Hires Employees who are >50 years old (Male)'] ? items['New Hires Employees who are >50 years old (Male)'] : 0
                                    local['DPAN644'] = items['New Hires Employees who are < 30 years old (Female)'] ? items['New Hires Employees who are < 30 years old (Female)'] : 0
                                    local['DPAN645'] = items['New Hires Employees who are 30-50 years old (Female)'] ? items['New Hires Employees who are 30-50 years old (Female)'] : 0
                                    local['DPAN646'] = items['New Hires Employees who are >50 years old (Female)'] ? items['New Hires Employees who are >50 years old (Female)'] : 0


                                    local['DPAN672'] = items['Employee Turnover Employees who are < 30 years old (Male)'] ? items['Employee Turnover Employees who are < 30 years old (Male)'] : 0
                                    local['DPAN673'] = items['Employee Turnover Employees who are 30-50 years old (Male)'] ? items['Employee Turnover Employees who are 30-50 years old (Male)'] : 0
                                    local['DPAN674'] = items['Employee Turnover Employees who are >50 years old (Male)'] ? items['Employee Turnover Employees who are >50 years old (Male)'] : 0
                                    local['DPAN675'] = items['Employee Turnover Employees who are < 30 years old (Female)'] ? items['Employee Turnover Employees who are < 30 years old (Female)'] : 0
                                    local['DPAN676'] = items['Employee Turnover Employees who are 30-50 years old (Female)'] ? items['Employee Turnover Employees who are 30-50 years old (Female)'] : 0
                                    local['DPAN677'] = items['Employee Turnover Employees who are >50 years old (Female)'] ? items['Employee Turnover Employees who are >50 years old (Female)'] : 0


                                    local2.push(newObj);
                                    newObj['response'] = [local];
                                    newObj['excel_data'] = [items]
                                    res(newObj);
                                } else {
                                    rejected.push({ ...items, "Rejection Remarks": errorArray.join(',') })
                                    res(rejected)
                                }

                            } else if (items['Null Report(Yes/No)'].toString().toLowerCase().trim() === 'yes' && (!items['Type of Gas Refilled'] && !items['Type of AC/ Chiller / Freezer used'])) {
                                newObj['edit'] = 1
                                newObj['response'] = []
                                newObj['excel_data'] = [items]
                                newObj['Attachment(Yes/No)-Default will be No'] = items['Attachment(Yes/No)-Default will be No'] ? items['Attachment(Yes/No)-Default will be No'].toString() : 'no'
                                res(newObj);
                                local2.push(newObj);
                            } else {

                                rejected.push({ ...items, "Rejection Remarks": errorArray.join(',') })
                                res(rejected)
                            }
                        } else if (!found && assObj[0].id) {
                            let oldIndex = quantitativesubmission.find(i => i.id === assObj[0].id)
                            let refObjData = assObj.filter(i => i.refobj)
                            if (oldIndex && refObjData.length !== 0) {
                                let refObj = refObjData[0].refobj
                                console.log(refObjData[0])
                                newObj['level'] = level.toString().toLowerCase().trim() === 'corporate' ? 0 : level.toString().toLowerCase().trim() === 'country' ? 1 : level.toString().toLowerCase().trim() === 'region' ? 2 : level.toString().toLowerCase().trim() === 'business unit' ? 3 : null;
                                newObj['reporting_period'] = getRPLuxon(items['Reporting Period']);
                                newObj['rp'] = items['Reporting Period'];
                                newObj['locationId'] = newObj[`tier${newObj['level']}_id`]
                                newObj['form_type'] = 1
                                newObj['user_type'] = login_data.role === 'clientuser' ? 1 : login_data.role === 'clientadmin' ? 0 : 2;
                                newObj['logs'] = [];
                                newObj['submitted_by'] = login_data.id;
                                newObj['submitted_on'] = dt;
                                newObj['last_modified_on'] = dt;
                                newObj['reporter_modified_on'] = dt;
                                newObj['reporter_modified_by'] = login_data.id;
                                newObj['last_modified_by'] = login_data.id;
                                newObj['entityUserAssId'] = refObj.id;
                                newObj['entityAssId'] = refObj.entityAssId;
                                newObj['standard'] = refObj.standard;
                                newObj['dcfId'] = refineddata[0].dcfId
                                if (items['Null Report(Yes/No)'].toString().toLowerCase().trim() === 'no') {
                                    if (count === 0) {
                                        local['DPAN641'] = items['New Hires Employees who are < 30 years old (Male)'] ? items['New Hires Employees who are < 30 years old (Male)'] : 0
                                        local['DPAN642'] = items['New Hires Employees who are 30-50 years old (Male)'] ? items['New Hires Employees who are 30-50 years old (Male)'] : 0
                                        local['DPAN643'] = items['New Hires Employees who are >50 years old (Male)'] ? items['New Hires Employees who are >50 years old (Male)'] : 0
                                        local['DPAN644'] = items['New Hires Employees who are < 30 years old (Female)'] ? items['New Hires Employees who are < 30 years old (Female)'] : 0
                                        local['DPAN645'] = items['New Hires Employees who are 30-50 years old (Female)'] ? items['New Hires Employees who are 30-50 years old (Female)'] : 0
                                        local['DPAN646'] = items['New Hires Employees who are >50 years old (Female)'] ? items['New Hires Employees who are >50 years old (Female)'] : 0


                                        local['DPAN672'] = items['Employee Turnover Employees who are < 30 years old (Male)'] ? items['Employee Turnover Employees who are < 30 years old (Male)'] : 0
                                        local['DPAN673'] = items['Employee Turnover Employees who are 30-50 years old (Male)'] ? items['Employee Turnover Employees who are 30-50 years old (Male)'] : 0
                                        local['DPAN674'] = items['Employee Turnover Employees who are >50 years old (Male)'] ? items['Employee Turnover Employees who are >50 years old (Male)'] : 0
                                        local['DPAN675'] = items['Employee Turnover Employees who are < 30 years old (Female)'] ? items['Employee Turnover Employees who are < 30 years old (Female)'] : 0
                                        local['DPAN676'] = items['Employee Turnover Employees who are 30-50 years old (Female)'] ? items['Employee Turnover Employees who are 30-50 years old (Female)'] : 0
                                        local['DPAN677'] = items['Employee Turnover Employees who are >50 years old (Female)'] ? items['Employee Turnover Employees who are >50 years old (Female)'] : 0


                                        newObj['id'] = oldIndex.id
                                        local2.push(newObj);

                                        newObj['response'] = [local];
                                        newObj['excel_data'] = [items]
                                        res(newObj);
                                    } else {
                                        rejected.push({ ...items, "Rejection Remarks": errorArray.join(',') })
                                        res(rejected)
                                    }

                                } else if (items['Null Report(Yes/No)'].toString().toLowerCase().trim() === 'yes' && (!items['Type of Gas Refilled'] && !items['Type of AC/ Chiller / Freezer used'])) {
                                    newObj['edit'] = 1
                                    newObj['id'] = oldIndex.id
                                    newObj['response'] = []
                                    newObj['excel_data'] = [items]
                                    newObj['Attachment(Yes/No)-Default will be No'] = items['Attachment(Yes/No)-Default will be No'] ? items['Attachment(Yes/No)-Default will be No'].toString() : 'no'
                                    res(newObj);

                                    local2.push(newObj);
                                } else {

                                    rejected.push({ ...items, "Rejection Remarks": errorArray.join(',') })
                                    res(rejected)
                                }

                            } else {

                                rejected.push({ ...items, "Rejection Remarks": 'You are not assigned to this entity on this form' })
                                res(rejected)
                            }
                        } else {

                            rejected.push({ ...items, "Rejection Remarks": 'You are not assigned to this entity on this form' })
                            res(rejected)
                        }

                    } else {



                        rejected.push({ ...items, "Rejection Remarks": errorArray.join(',') })
                        res(rejected)
                    }
                } else {


                    rejected.push({ ...items, "Rejection Remarks": errorArray.join(',') })
                    res(rejected)
                }
                res(rejected)
            } catch (e) {


                if (!e.found) {
                    rejected.push({ ...items, "Rejection Remarks": 'Ensure Imported Data Is For The Selected Year & Entities' })

                } else {
                    rejected.push({ ...items, "Rejection Remarks": 'Either Data Approved / Reviewed ' })
                }
                res(rejected)
                console.log(e)
            }

        }

        );

        return promise
    } else if (dcfId === 284) {

        let promise = new Promise(async (res, rej) => {
            try {

                if (!items['Entity'] || !items['Entity'].toString().trim().length) {
                    errorArray.push('Entity Field is Required')
                }
                if (!items['Entity Level'] || !items['Entity Level'].toString().trim().length) {
                    errorArray.push('Entity Level Field is Required')
                }
                if (!items['Null Report(Yes/No)'] || !items['Null Report(Yes/No)'].toString().trim().length) {
                    errorArray.push('Null Report(Yes/No) Field is Required')
                }
                if (!items['Reporting Period'] || !items['Reporting Period'].toString().trim().length) {
                    errorArray.push('Reporting Period Field is Required')
                } else if (items['Reporting Period'].toString().trim().length) {
                    if (!validateDateString(items['Reporting Period'])) {
                        errorArray.push('Reporting Period Field Format is Incorrect')
                    }
                }
                let count = 0
                if (typeof items['New Hires Employees who are < 30 years old (Male)'] !== 'number') {
                    count++
                    errorArray.push('New Hires Employees who are < 30 years old (Male) Field is Required')
                }
                if (typeof items['New Hires Employees who are < 30 years old (Female)'] !== 'number') {
                    count++
                    errorArray.push('New Hires Employees who are < 30 years old (Female) Field is Required')
                }
                if (typeof items['New Hires Employees who are 30-50 years old (Female)'] !== 'number') {
                    count++
                    errorArray.push('New Hires Employees who are 30-50 years old (Female) Field is Required')
                }
                if (typeof items['New Hires Employees who are 30-50 years old (Male)'] !== 'number') {
                    count++
                    errorArray.push('New Hires Employees who are 30-50 years old (Male) Field is Required')
                }
                if (typeof items['New Hires Employees who are >50 years old (Male)'] !== 'number') {
                    count++
                    errorArray.push('New Hires Employees who are >50 years old (Male) Field is Required')
                }
                if (typeof items['New Hires Employees who are >50 years old (Female)'] !== 'number') {
                    count++
                    errorArray.push('New Hires Employees who are >50 years old (Female) Field is Required')
                }
                if (typeof items['Employee Turnover Employees who are < 30 years old (Male)'] !== 'number') {
                    count++
                    errorArray.push('Employee Turnover Employees who are < 30 years old (Male) Field is Required')
                }
                if (typeof items['Employee Turnover Employees who are < 30 years old (Female)'] !== 'number') {
                    count++
                    errorArray.push('Employee Turnover Employees who are < 30 years old (Female) Field is Required')
                }
                if (typeof items['Employee Turnover Employees who are 30-50 years old (Female)'] !== 'number') {
                    count++
                    errorArray.push('Employee Turnover Employees who are 30-50 years old (Female) Field is Required')
                }
                if (typeof items['Employee Turnover Employees who are 30-50 years old (Male)'] !== 'number') {
                    count++
                    errorArray.push('Employee Turnover Employees who are 30-50 years old (Male) Field is Required')
                }
                if (typeof items['Employee Turnover Employees who are >50 years old (Male)'] !== 'number') {
                    count++
                    errorArray.push('Employee Turnover Employees who are >50 years old (Male) Field is Required')
                }
                if (typeof items['Employee Turnover Employees who are >50 years old (Female)'] !== 'number') {
                    count++
                    errorArray.push('Employee Turnover Employees who are >50 years old (Female) Field is Required')
                }


                let local = {};
                let newObj = {};
                let level = items['Entity Level'];
                if (level && items['Entity'] && items['Null Report(Yes/No)'] && items['Reporting Period'] && validateDateString(items['Reporting Period'])) {
                    const { result, obj } = getCoverageIdByText({
                        entity: items['Entity'],
                        level: level.toString().toLowerCase().trim() === 'corporate' ? 0 : level.toString().toLowerCase().trim() === 'country' ? 1 : level.toString().toLowerCase().trim() === 'region' ? 2 : level.toString().toLowerCase().trim() === 'business unit' ? 3 : null
                    }, rawsitelist);

                    if (result) {
                        newObj = { ...newObj, ...obj };
                        let dt = DateTime.utc();
                        const { found, assObj } = await getAssignment(items['Reporting Period'], obj, null, refineddata).then((assObj) => { return assObj });
                        console.log(found, assObj)
                        if (found) {
                            newObj['level'] = level.toString().toLowerCase().trim() === 'corporate' ? 0 : level.toString().toLowerCase().trim() === 'country' ? 1 : level.toString().toLowerCase().trim() === 'region' ? 2 : level.toString().toLowerCase().trim() === 'business unit' ? 3 : null;
                            newObj['reporting_period'] = getRPLuxon(items['Reporting Period']);
                            newObj['rp'] = items['Reporting Period'];
                            newObj['locationId'] = newObj[`tier${newObj['level']}_id`]
                            newObj['form_type'] = 1
                            newObj['user_type'] = login_data.role === 'clientuser' ? 1 : login_data.role === 'clientadmin' ? 0 : 2;
                            newObj['logs'] = [];
                            newObj['submitted_by'] = login_data.id;
                            newObj['submitted_on'] = dt;
                            newObj['last_modified_on'] = dt;
                            newObj['reporter_modified_on'] = dt;
                            newObj['reporter_modified_by'] = login_data.id;
                            newObj['last_modified_by'] = login_data.id;
                            newObj['entityUserAssId'] = assObj[0].entityUserAssId;
                            newObj['entityAssId'] = assObj[0].entityAssId;
                            newObj['standard'] = assObj[0].standard;
                            newObj['dcfId'] = refineddata[0].dcfId
                            if (items['Null Report(Yes/No)'].toString().toLowerCase().trim() === 'no') {
                                if (count === 0) {
                                    local['DPAN641'] = items['New Hires Employees who are < 30 years old (Male)'] ? items['New Hires Employees who are < 30 years old (Male)'] : 0
                                    local['DPAN642'] = items['New Hires Employees who are 30-50 years old (Male)'] ? items['New Hires Employees who are 30-50 years old (Male)'] : 0
                                    local['DPAN643'] = items['New Hires Employees who are >50 years old (Male)'] ? items['New Hires Employees who are >50 years old (Male)'] : 0
                                    local['DPAN644'] = items['New Hires Employees who are < 30 years old (Female)'] ? items['New Hires Employees who are < 30 years old (Female)'] : 0
                                    local['DPAN645'] = items['New Hires Employees who are 30-50 years old (Female)'] ? items['New Hires Employees who are 30-50 years old (Female)'] : 0
                                    local['DPAN646'] = items['New Hires Employees who are >50 years old (Female)'] ? items['New Hires Employees who are >50 years old (Female)'] : 0


                                    local['DPAN672'] = items['Employee Turnover Employees who are < 30 years old (Male)'] ? items['Employee Turnover Employees who are < 30 years old (Male)'] : 0
                                    local['DPAN673'] = items['Employee Turnover Employees who are 30-50 years old (Male)'] ? items['Employee Turnover Employees who are 30-50 years old (Male)'] : 0
                                    local['DPAN674'] = items['Employee Turnover Employees who are >50 years old (Male)'] ? items['Employee Turnover Employees who are >50 years old (Male)'] : 0
                                    local['DPAN675'] = items['Employee Turnover Employees who are < 30 years old (Female)'] ? items['Employee Turnover Employees who are < 30 years old (Female)'] : 0
                                    local['DPAN676'] = items['Employee Turnover Employees who are 30-50 years old (Female)'] ? items['Employee Turnover Employees who are 30-50 years old (Female)'] : 0
                                    local['DPAN677'] = items['Employee Turnover Employees who are >50 years old (Female)'] ? items['Employee Turnover Employees who are >50 years old (Female)'] : 0


                                    local2.push(newObj);
                                    newObj['response'] = [local];
                                    newObj['excel_data'] = [items]
                                    res(newObj);
                                } else {
                                    rejected.push({ ...items, "Rejection Remarks": errorArray.join(',') })
                                    res(rejected)
                                }

                            } else if (items['Null Report(Yes/No)'].toString().toLowerCase().trim() === 'yes' && (!items['Type of Gas Refilled'] && !items['Type of AC/ Chiller / Freezer used'])) {
                                newObj['edit'] = 1
                                newObj['response'] = []
                                newObj['excel_data'] = [items]
                                newObj['Attachment(Yes/No)-Default will be No'] = items['Attachment(Yes/No)-Default will be No'] ? items['Attachment(Yes/No)-Default will be No'].toString() : 'no'
                                res(newObj);
                                local2.push(newObj);
                            } else {

                                rejected.push({ ...items, "Rejection Remarks": errorArray.join(',') })
                                res(rejected)
                            }
                        } else if (!found && assObj[0].id) {
                            let oldIndex = quantitativesubmission.find(i => i.id === assObj[0].id)
                            let refObjData = assObj.filter(i => i.refobj)
                            if (oldIndex && refObjData.length !== 0) {
                                let refObj = refObjData[0].refobj
                                console.log(refObjData[0])
                                newObj['level'] = level.toString().toLowerCase().trim() === 'corporate' ? 0 : level.toString().toLowerCase().trim() === 'country' ? 1 : level.toString().toLowerCase().trim() === 'region' ? 2 : level.toString().toLowerCase().trim() === 'business unit' ? 3 : null;
                                newObj['reporting_period'] = getRPLuxon(items['Reporting Period']);
                                newObj['rp'] = items['Reporting Period'];
                                newObj['locationId'] = newObj[`tier${newObj['level']}_id`]
                                newObj['form_type'] = 1
                                newObj['user_type'] = login_data.role === 'clientuser' ? 1 : login_data.role === 'clientadmin' ? 0 : 2;
                                newObj['logs'] = [];
                                newObj['submitted_by'] = login_data.id;
                                newObj['submitted_on'] = dt;
                                newObj['last_modified_on'] = dt;
                                newObj['reporter_modified_on'] = dt;
                                newObj['reporter_modified_by'] = login_data.id;
                                newObj['last_modified_by'] = login_data.id;
                                newObj['entityUserAssId'] = refObj.id;
                                newObj['entityAssId'] = refObj.entityAssId;
                                newObj['standard'] = refObj.standard;
                                newObj['dcfId'] = refineddata[0].dcfId
                                if (items['Null Report(Yes/No)'].toString().toLowerCase().trim() === 'no') {
                                    if (count === 0) {
                                        local['DPAN641'] = items['New Hires Employees who are < 30 years old (Male)'] ? items['New Hires Employees who are < 30 years old (Male)'] : 0
                                        local['DPAN642'] = items['New Hires Employees who are 30-50 years old (Male)'] ? items['New Hires Employees who are 30-50 years old (Male)'] : 0
                                        local['DPAN643'] = items['New Hires Employees who are >50 years old (Male)'] ? items['New Hires Employees who are >50 years old (Male)'] : 0
                                        local['DPAN644'] = items['New Hires Employees who are < 30 years old (Female)'] ? items['New Hires Employees who are < 30 years old (Female)'] : 0
                                        local['DPAN645'] = items['New Hires Employees who are 30-50 years old (Female)'] ? items['New Hires Employees who are 30-50 years old (Female)'] : 0
                                        local['DPAN646'] = items['New Hires Employees who are >50 years old (Female)'] ? items['New Hires Employees who are >50 years old (Female)'] : 0


                                        local['DPAN672'] = items['Employee Turnover Employees who are < 30 years old (Male)'] ? items['Employee Turnover Employees who are < 30 years old (Male)'] : 0
                                        local['DPAN673'] = items['Employee Turnover Employees who are 30-50 years old (Male)'] ? items['Employee Turnover Employees who are 30-50 years old (Male)'] : 0
                                        local['DPAN674'] = items['Employee Turnover Employees who are >50 years old (Male)'] ? items['Employee Turnover Employees who are >50 years old (Male)'] : 0
                                        local['DPAN675'] = items['Employee Turnover Employees who are < 30 years old (Female)'] ? items['Employee Turnover Employees who are < 30 years old (Female)'] : 0
                                        local['DPAN676'] = items['Employee Turnover Employees who are 30-50 years old (Female)'] ? items['Employee Turnover Employees who are 30-50 years old (Female)'] : 0
                                        local['DPAN677'] = items['Employee Turnover Employees who are >50 years old (Female)'] ? items['Employee Turnover Employees who are >50 years old (Female)'] : 0


                                        newObj['id'] = oldIndex.id
                                        local2.push(newObj);

                                        newObj['response'] = [local];
                                        newObj['excel_data'] = [items]
                                        res(newObj);
                                    } else {
                                        rejected.push({ ...items, "Rejection Remarks": errorArray.join(',') })
                                        res(rejected)
                                    }

                                } else if (items['Null Report(Yes/No)'].toString().toLowerCase().trim() === 'yes' && (!items['Type of Gas Refilled'] && !items['Type of AC/ Chiller / Freezer used'])) {
                                    newObj['edit'] = 1
                                    newObj['id'] = oldIndex.id
                                    newObj['response'] = []
                                    newObj['excel_data'] = [items]
                                    newObj['Attachment(Yes/No)-Default will be No'] = items['Attachment(Yes/No)-Default will be No'] ? items['Attachment(Yes/No)-Default will be No'].toString() : 'no'
                                    res(newObj);

                                    local2.push(newObj);
                                } else {

                                    rejected.push({ ...items, "Rejection Remarks": errorArray.join(',') })
                                    res(rejected)
                                }

                            } else {

                                rejected.push({ ...items, "Rejection Remarks": 'You are not assigned to this entity on this form' })
                                res(rejected)
                            }
                        } else {

                            rejected.push({ ...items, "Rejection Remarks": 'You are not assigned to this entity on this form' })
                            res(rejected)
                        }

                    } else {



                        rejected.push({ ...items, "Rejection Remarks": errorArray.join(',') })
                        res(rejected)
                    }
                } else {


                    rejected.push({ ...items, "Rejection Remarks": errorArray.join(',') })
                    res(rejected)
                }
                res(rejected)
            } catch (e) {


                if (!e.found) {
                    rejected.push({ ...items, "Rejection Remarks": 'Ensure Imported Data Is For The Selected Year & Entities' })

                } else {
                    rejected.push({ ...items, "Rejection Remarks": 'Either Data Approved / Reviewed ' })
                }
                res(rejected)
                console.log(e)
            }

        }

        );

        return promise
    } else if (dcfId === 285) {

        const DPAN0048Options = [
            { name: "Self Generated Water", id: 6 },

            { name: "Third-Party Water", id: 5 },
        ];
        const DPAN1158Options = [
            { name: "Groundwater Wells Operated", id: 1 },

            { name: "Effluent/ Sewage Treatment Recycle", id: 2 },
            { name: 'Rainwater Harvesting', id: 3 },
            { name: 'Others', id: 99 }
        ];
        const DPAN1200Options = [
            { name: "Surface water", id: 1 },
            { name: "Ground Water", id: 2 },
            { name: "Sea Water", id: 3 },
            { name: 'Imported Water from Industrial District', id: 8 },
            { name: 'Unknown', id: 9 }
        ];
        const DPAN0050Options = [
            { name: "(≤1,000 mg/L Total Dissolved Solids)", id: 1 },
            { name: "(>1,000 mg/L Total Dissolved Solids)", id: 2 },
        ];
        let promise = new Promise(async (res, rej) => {
            try {

                if (!items['Entity'] || !items['Entity'].toString().trim().length) {
                    errorArray.push('Entity Field is Required')
                }
                if (!items['Entity Level'] || !items['Entity Level'].toString().trim().length) {
                    errorArray.push('Entity Level Field is Required')
                }
                if (!items['Null Report(Yes/No)'] || !items['Null Report(Yes/No)'].toString().trim().length) {
                    errorArray.push('Null Report(Yes/No) Field is Required')
                }
                if (!items['Reporting Period'] || !items['Reporting Period'].toString().trim().length) {
                    errorArray.push('Reporting Period Field is Required')
                } else if (items['Reporting Period'].toString().trim().length) {
                    if (!validateDateString(items['Reporting Period'])) {
                        errorArray.push('Reporting Period Field Format is Incorrect')
                    }
                }
                let count = 0
                if (typeof items['Quantity of Water Generated/Third-Party Withdrawal (m3)'] !== 'number') {
                    count++
                    if (!items['Quantity of Water Generated/Third-Party Withdrawal (m3)']) {
                        errorArray.push('Quantity of Water Generated/Third-Party Withdrawal (m3) Field is Required')
                    } else {
                        errorArray.push('Quantity of Water Generated/Third-Party Withdrawal (m3) Field Should Be Number Format')
                    }

                }
                if (!items['Method'] || !items['Method'].toString().length) {
                    count++
                    errorArray.push('Method Field is Required')

                }
                if (!items['Water Source'] || !items['Water Source'].toString().length) {
                    count++
                    errorArray.push('Water Source Field is Required')

                } else if (items['Water Source'] && !DPAN0048Options.find(i => i.name.toLowerCase().trim() === items['Water Source'].toString().toLowerCase().trim())) {
                    count++
                    errorArray.push('Water Source Field Has Invalid Value')
                } else if (items['Water Source'] && DPAN0048Options.find(i => i.name.toLowerCase().trim() === items['Water Source'].toString().toLowerCase().trim())) {
                    if (DPAN0048Options.find(i => i.name.toLowerCase().trim() === items['Water Source'].toString().toLowerCase().trim()).id === 6) {
                        if (items['Method'] && !DPAN1158Options.find(i => i.name.toLowerCase().trim() === items['Method'].toString().toLowerCase().trim())) {
                            errorArray.push('Method Field Has Invalid Value')
                            count++

                        }
                    } else {
                        if (items['Method'] && !DPAN1200Options.find(i => i.name.toLowerCase().trim() === items['Method'].toString().toLowerCase().trim())) {
                            errorArray.push('Method Field Has Invalid Value')
                            count++
                        }

                    }
                }




                let local = {};
                let newObj = {};
                let level = items['Entity Level'];
                if (level && items['Entity'] && items['Null Report(Yes/No)'] && items['Reporting Period'] && validateDateString(items['Reporting Period'])) {
                    const { result, obj } = getCoverageIdByText({
                        entity: items['Entity'],
                        level: level.toString().toLowerCase().trim() === 'corporate' ? 0 : level.toString().toLowerCase().trim() === 'country' ? 1 : level.toString().toLowerCase().trim() === 'region' ? 2 : level.toString().toLowerCase().trim() === 'business unit' ? 3 : null
                    }, rawsitelist);

                    if (result) {
                        newObj = { ...newObj, ...obj };
                        let dt = DateTime.utc();
                        const { found, assObj } = await getAssignment(items['Reporting Period'], obj, null, refineddata).then((assObj) => { return assObj });
                        console.log(found, assObj)
                        if (found) {
                            newObj['level'] = level.toString().toLowerCase().trim() === 'corporate' ? 0 : level.toString().toLowerCase().trim() === 'country' ? 1 : level.toString().toLowerCase().trim() === 'region' ? 2 : level.toString().toLowerCase().trim() === 'business unit' ? 3 : null;
                            newObj['reporting_period'] = getRPLuxon(items['Reporting Period']);
                            newObj['rp'] = items['Reporting Period'];
                            newObj['locationId'] = newObj[`tier${newObj['level']}_id`]
                            newObj['form_type'] = 1
                            newObj['user_type'] = login_data.role === 'clientuser' ? 1 : login_data.role === 'clientadmin' ? 0 : 2;
                            newObj['logs'] = [];
                            newObj['submitted_by'] = login_data.id;
                            newObj['submitted_on'] = dt;
                            newObj['last_modified_on'] = dt;
                            newObj['reporter_modified_on'] = dt;
                            newObj['reporter_modified_by'] = login_data.id;
                            newObj['last_modified_by'] = login_data.id;
                            newObj['entityUserAssId'] = assObj[0].entityUserAssId;
                            newObj['entityAssId'] = assObj[0].entityAssId;
                            newObj['standard'] = assObj[0].standard;
                            newObj['dcfId'] = refineddata[0].dcfId
                            if (items['Null Report(Yes/No)'].toString().toLowerCase().trim() === 'no') {
                                if (count === 0) {


                                    let index1 = DPAN0048Options.findIndex(i => i.name.toLowerCase().trim() === items['Water Source'].toString().toLowerCase().trim())

                                    if (index1 !== -1) {
                                        let water_id = DPAN0048Options[index1].id
                                        if (water_id === 6) {
                                            let index2 = DPAN1158Options.findIndex(i => i.name.toLowerCase().trim() === items['Method'].toString().toLowerCase().trim())
                                            if (index2 !== -1) {
                                                if (items['Water Used in Cooling System (m3)'] && parseFloat(items['Water Used in Cooling System (m3)'].toString()) >= 0) {
                                                    local['DPAN1160'] = parseFloat(items['Water Used in Cooling System (m3)'].toString())
                                                } else {
                                                    local['DPAN1160'] = 0
                                                }
                                                local['id'] = getID()
                                                local['DPAN1200'] = null
                                                local['DPAN0050'] = null
                                                local['DPAN1161'] = 0
                                                local['DPAN1162'] = 0
                                                local['attachment'] = null

                                                local['DPAN0048'] = water_id
                                                local['DPAN1158'] = DPAN1158Options[index2].id
                                                local['DPAN1159'] = parseFloat(items['Quantity of Water Generated/Third-Party Withdrawal (m3)'].toString())


                                            }
                                        } else {

                                            let index2 = DPAN1200Options.findIndex(i => i.name.toLowerCase().trim() === items['Method'].toString().toLowerCase().trim())
                                            if (index2 !== -1) {
                                                if (items['Water Used in Cooling System (m3)'] && parseFloat(items['Water Used in Cooling System (m3)'].toString()) >= 0) {
                                                    local['DPAN1162'] = parseFloat(items['Water Used in Cooling System (m3)'].toString())
                                                } else {
                                                    local['DPAN1162'] = 0
                                                }

                                                if (items['Total Dissolved Solids of Water Withdrawal'] && items['Total Dissolved Solids of Water Withdrawal'].toString().trim().length !== 0) {
                                                    let index2 = DPAN0050Options.findIndex(i => i.name.toLowerCase().trim() === items['Total Dissolved Solids of Water Withdrawal'].toString().toLowerCase().trim())
                                                    if (index2 !== -1) {
                                                        local["DPAN0050"] = DPAN0050Options[index2].id
                                                    } else {
                                                        local["DPAN0050"] = null
                                                    }


                                                } else {
                                                    local["DPAN0050"] = null
                                                }
                                                local['id'] = getID()
                                                local['DPAN1158'] = null
                                                local['DPAN1160'] = 0
                                                local['DPAN1159'] = 0
                                                local['attachment'] = null

                                                local['DPAN0048'] = water_id
                                                local['DPAN1200'] = DPAN1200Options[index2].id
                                                local['DPAN1161'] = parseFloat(items['Quantity of Water Generated/Third-Party Withdrawal (m3)'].toString())


                                            }


                                        }


                                    }




                                    local2.push(newObj);
                                    newObj['response'] = [local];
                                    newObj['excel_data'] = [items]
                                    res(newObj);
                                } else {
                                    rejected.push({ ...items, "Rejection Remarks": errorArray.join(',') })
                                    res(rejected)
                                }

                            } else if (items['Null Report(Yes/No)'].toString().toLowerCase().trim() === 'yes' && (!items['Type of Gas Refilled'] && !items['Type of AC/ Chiller / Freezer used'])) {
                                newObj['edit'] = 1
                                newObj['response'] = []
                                newObj['excel_data'] = [items]
                                newObj['Attachment(Yes/No)-Default will be No'] = items['Attachment(Yes/No)-Default will be No'] ? items['Attachment(Yes/No)-Default will be No'].toString() : 'no'
                                res(newObj);
                                local2.push(newObj);
                            } else {

                                rejected.push({ ...items, "Rejection Remarks": errorArray.join(',') })
                                res(rejected)
                            }
                        } else if (!found && assObj[0].id) {
                            let oldIndex = quantitativesubmission.find(i => i.id === assObj[0].id)
                            let refObjData = assObj.filter(i => i.refobj)
                            if (oldIndex && refObjData.length !== 0) {
                                let refObj = refObjData[0].refobj
                                console.log(refObjData[0])
                                newObj['level'] = level.toString().toLowerCase().trim() === 'corporate' ? 0 : level.toString().toLowerCase().trim() === 'country' ? 1 : level.toString().toLowerCase().trim() === 'region' ? 2 : level.toString().toLowerCase().trim() === 'business unit' ? 3 : null;
                                newObj['reporting_period'] = getRPLuxon(items['Reporting Period']);
                                newObj['rp'] = items['Reporting Period'];
                                newObj['locationId'] = newObj[`tier${newObj['level']}_id`]
                                newObj['form_type'] = 1
                                newObj['user_type'] = login_data.role === 'clientuser' ? 1 : login_data.role === 'clientadmin' ? 0 : 2;
                                newObj['logs'] = [];
                                newObj['submitted_by'] = login_data.id;
                                newObj['submitted_on'] = dt;
                                newObj['last_modified_on'] = dt;
                                newObj['reporter_modified_on'] = dt;
                                newObj['reporter_modified_by'] = login_data.id;
                                newObj['last_modified_by'] = login_data.id;
                                newObj['entityUserAssId'] = refObj.id;
                                newObj['entityAssId'] = refObj.entityAssId;
                                newObj['standard'] = refObj.standard;
                                newObj['dcfId'] = refineddata[0].dcfId
                                if (items['Null Report(Yes/No)'].toString().toLowerCase().trim() === 'no') {
                                    if (count === 0) {
                                        let index1 = DPAN0048Options.findIndex(i => i.name.toLowerCase().trim() === items['Water Source'].toString().toLowerCase().trim())

                                        if (index1 !== -1) {
                                            let water_id = DPAN0048Options[index1].id
                                            if (water_id === 6) {
                                                let index2 = DPAN1158Options.findIndex(i => i.name.toLowerCase().trim() === items['Method'].toString().toLowerCase().trim())
                                                if (index2 !== -1) {
                                                    if (items['Water Used in Cooling System (m3)'] && parseFloat(items['Water Used in Cooling System (m3)'].toString()) >= 0) {
                                                        local['DPAN1160'] = parseFloat(items['Water Used in Cooling System (m3)'].toString())
                                                    } else {
                                                        local['DPAN1160'] = 0
                                                    }
                                                    local['id'] = getID()
                                                    local['DPAN1200'] = null
                                                    local['DPAN0050'] = null
                                                    local['DPAN1161'] = 0
                                                    local['DPAN1162'] = 0
                                                    local['attachment'] = null

                                                    local['DPAN0048'] = water_id
                                                    local['DPAN1158'] = DPAN1158Options[index2].id
                                                    local['DPAN1159'] = parseFloat(items['Quantity of Water Generated/Third-Party Withdrawal (m3)'].toString())


                                                }
                                            } else {

                                                let index2 = DPAN1200Options.findIndex(i => i.name.toLowerCase().trim() === items['Method'].toString().toLowerCase().trim())
                                                if (index2 !== -1) {
                                                    if (items['Water Used in Cooling System (m3)'] && parseFloat(items['Water Used in Cooling System (m3)'].toString()) >= 0) {
                                                        local['DPAN1162'] = parseFloat(items['Water Used in Cooling System (m3)'].toString())
                                                    } else {
                                                        local['DPAN1162'] = 0
                                                    }

                                                    if (items['Total Dissolved Solids of Water Withdrawal'] && items['Total Dissolved Solids of Water Withdrawal'].toString().trim().length !== 0) {
                                                        let index2 = DPAN0050Options.findIndex(i => i.name.toLowerCase().trim() === items['Total Dissolved Solids of Water Withdrawal'].toString().toLowerCase().trim())
                                                        if (index2 !== -1) {
                                                            local["DPAN0050"] = DPAN0050Options[index2].id
                                                        } else {
                                                            local["DPAN0050"] = null
                                                        }


                                                    } else {
                                                        local["DPAN0050"] = null
                                                    }
                                                    local['id'] = getID()
                                                    local['DPAN1158'] = null
                                                    local['DPAN1160'] = 0
                                                    local['DPAN1159'] = 0
                                                    local['attachment'] = null

                                                    local['DPAN0048'] = water_id
                                                    local['DPAN1200'] = DPAN1200Options[index2].id
                                                    local['DPAN1161'] = parseFloat(items['Quantity of Water Generated/Third-Party Withdrawal (m3)'].toString())


                                                }


                                            }


                                        }

                                        newObj['id'] = oldIndex.id
                                        local2.push(newObj);

                                        newObj['response'] = [local];
                                        newObj['excel_data'] = [items]
                                        res(newObj);
                                    } else {
                                        rejected.push({ ...items, "Rejection Remarks": errorArray.join(',') })
                                        res(rejected)
                                    }

                                } else if (items['Null Report(Yes/No)'].toString().toLowerCase().trim() === 'yes' && (!items['Type of Gas Refilled'] && !items['Type of AC/ Chiller / Freezer used'])) {
                                    newObj['edit'] = 1
                                    newObj['id'] = oldIndex.id
                                    newObj['response'] = []
                                    newObj['excel_data'] = [items]
                                    newObj['Attachment(Yes/No)-Default will be No'] = items['Attachment(Yes/No)-Default will be No'] ? items['Attachment(Yes/No)-Default will be No'].toString() : 'no'
                                    res(newObj);

                                    local2.push(newObj);
                                } else {

                                    rejected.push({ ...items, "Rejection Remarks": errorArray.join(',') })
                                    res(rejected)
                                }

                            } else {

                                rejected.push({ ...items, "Rejection Remarks": 'You are not assigned to this entity on this form' })
                                res(rejected)
                            }
                        } else {

                            rejected.push({ ...items, "Rejection Remarks": 'You are not assigned to this entity on this form' })
                            res(rejected)
                        }

                    } else {



                        rejected.push({ ...items, "Rejection Remarks": errorArray.join(',') })
                        res(rejected)
                    }
                } else {


                    rejected.push({ ...items, "Rejection Remarks": errorArray.join(',') })
                    res(rejected)
                }
                res(rejected)
            } catch (e) {


                if (!e.found) {
                    rejected.push({ ...items, "Rejection Remarks": 'Ensure Imported Data Is For The Selected Year & Entities' })

                } else {
                    rejected.push({ ...items, "Rejection Remarks": 'Either Data Approved / Reviewed ' })
                }
                res(rejected)
                console.log(e)
            }

        }

        );

        return promise
    }

}
const getCoverageIdByText = (rowData, rawsitelist) => {
    let obj = { tier0_id: null, tier1_id: null, tier2_id: null, tier3_id: null }
    let result = false
    console.log(rowData);
    if (rowData.level === 0) {

        obj['tier0_id'] = 0
        result = true
    } else if (rowData.level === 1) {
        let country_index = rawsitelist.findIndex(
            (i) => i.name.toLowerCase().trim() === rowData.entity.toLowerCase().trim()
        );
        if (country_index !== -1) {

            obj['tier1_id'] = rawsitelist[country_index].id
            result = true

        }
    } else if (rowData.level === 2) {
        let city_index = rawsitelist
            .flatMap((i) =>
                i.locationTwos.flatMap((j) =>
                    j.locationThrees.map((k) => {
                        return {
                            site_id: k.id,
                            site_name: k.name,
                            city_id: j.id,
                            city_name: j.name,
                            country_id: i.id,
                            country_name: i.name,
                        };
                    })
                )
            )
            .findIndex((i) => {
                return i.city_name.toLowerCase().trim() === rowData.entity.toLowerCase().trim()
            });
        if (city_index !== -1) {
            result = true
            obj['tier2_id'] = rawsitelist.flatMap((i) =>
                i.locationTwos.flatMap((j) =>
                    j.locationThrees.map((k) => {
                        return {
                            site_id: k.id,
                            site_name: k.name,
                            city_id: j.id,
                            city_name: j.name,
                            country_id: i.id,
                            country_name: i.name,
                        };
                    })
                )
            )[city_index].city_id;
        }
    } else if (rowData.level === 3) {
        let site_index = rawsitelist
            .flatMap((i) =>
                i.locationTwos.flatMap((j) =>
                    j.locationThrees.map((k) => {
                        return {
                            site_id: k.id,
                            site_name: k.name,
                            city_id: j.id,
                            city_name: j.name,
                            country_id: i.id,
                            country_name: i.name,
                        };
                    })
                )
            )
            .findIndex((i) => {
                return i.site_name.toLowerCase().trim() === rowData.entity.toLowerCase().trim()
            });
        if (site_index !== -1) {
            result = true
            obj['tier3_id'] = rawsitelist.flatMap((i) =>
                i.locationTwos.flatMap((j) =>
                    j.locationThrees.map((k) => {
                        return {
                            site_id: k.id,
                            site_name: k.name,
                            city_id: j.id,
                            city_name: j.name,
                            country_id: i.id,
                            country_name: i.name,
                        };
                    })
                )
            )[site_index].site_id;
        }
    }
    return { result, obj };
};
const getAssignment = async (rp, obj, err, refineddata) => {
    console.log(rp)
    return new Promise((resolve, reject) => {
        (async () => {
            try {
                for (const item of refineddata) {
                    console.log(obj, item)
                    if (obj.tier0_id === item.tier0_id && obj.tier1_id === item.tier1_id && obj.tier2_id === item.tier2_id && obj.tier3_id === item.tier3_id) {

                        for (const card of item.dp_array) {
                            console.log(card, rp)
                            if (Object.keys(card)[0] !== 'name' && Object.keys(card)[0] === rp && Object.values(card)[0]) {

                                resolve({ found: Object.values(card)[0].filter(i => i.id !== undefined).length === 0, assObj: Object.values(card)[0] });
                                return; // Exit after resolving
                            }
                        }
                    }
                }
                if (err) {
                    err.push('Import Data Which Is Valid for Selected Year Reporting Period')
                }

                reject({ found: false }); // Reject if no match found
            } catch (error) {
                console.log(error)
                reject(error);
            }
        })();
    });
};
const getRPLuxon = (months) => {
    if (months.includes('to')) {
        let startDate = DateTime.fromFormat(months.split('to')[0].trim(), 'LLL-yyyy')
        let endDate = DateTime.fromFormat(months.split('to')[1].trim(), 'LLL-yyyy')
        let rp = []
        while (startDate <= endDate) {
            rp.push(startDate.toFormat('LL-yyyy'));
            startDate = startDate.plus({ months: 1 })
        }
        return rp
    } else {
        return [DateTime.fromFormat(months, 'LLL-yyyy').toFormat('LL-yyyy')]
    }
}
const randomIntFromInterval = (min, max) => {
    // min and max included
    return Math.floor(Math.random() * (max - min + 1) + min);
};
const getID = () => {
    return (
        parseInt(Date.now() * Math.random()) +
        moment().unix() +
        randomIntFromInterval(1, 1000)
    );
};
function validateDateString(dateString) {
    const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

    // Helper function to parse month and year
    function parseMonthYear(monthYear) {
        const [month, year] = monthYear.split("-");
        const monthIndex = months.indexOf(month);
        if (monthIndex === -1) return null;
        return { monthIndex, year: parseInt(year, 10) };
    }

    // Check single month format
    if (/^[A-Z][a-z]{2}-\d{4}$/.test(dateString)) {
        return true;
    }

    // Check range format
    const rangeMatch = dateString.match(/^([A-Z][a-z]{2}-\d{4}) to ([A-Z][a-z]{2}-\d{4})$/);
    if (!rangeMatch) return false;

    const start = parseMonthYear(rangeMatch[1]);
    const end = parseMonthYear(rangeMatch[2]);

    if (!start || !end) return false;

    const { monthIndex: startMonth, year: startYear } = start;
    const { monthIndex: endMonth, year: endYear } = end;

    if (startYear > endYear || (startYear === endYear && startMonth >= endMonth)) {
        return false; // Backward range or same month
    }

    const monthDifference = (endYear - startYear) * 12 + (endMonth - startMonth) + 1;
    return [2, 3, 6, 12].includes(monthDifference);
}
function findMatchingIds(array, ...titles) {
    let currentArray = array;
    const matchingIds = [];

    for (let i = 0; i < titles.length; i++) {
        const titleToMatch = titles[i].trim().toLowerCase();

        if (!currentArray) {
            // If currentArray is undefined, return the result and matchingIds
            return { result: false, id: matchingIds };
        }

        const item = currentArray.find(
            (obj) => obj.title.trim().toLowerCase() === titleToMatch
        );

        if (!item) {
            // If the title is not found, return the result and matchingIds
            return { result: false, id: matchingIds };
        }

        matchingIds.push(item.id);

        // Move to the next level if available, otherwise, set currentArray to undefined
        currentArray =
            item[`newEfSubcategory${i + 2}s`] !== undefined
                ? item[`newEfSubcategory${i + 2}s`]
                : undefined;
    }

    return { result: true, id: matchingIds };
}
export { importData }