import React, { useState, useEffect } from 'react';
import { Dropdown } from 'primereact/dropdown';
import { Checkbox } from 'primereact/checkbox';
import { InputText } from "primereact/inputtext";
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { InputTextarea } from "primereact/inputtextarea";
import './styles/approver.css';
import 'primeicons/primeicons.css';
import APIServices from '../../../service/APIService';
import { API } from '../../../components/constants/api_url';
import { useSelector } from 'react-redux';
import useForceUpdate from 'use-force-update';
import { DateTime } from 'luxon';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Chart, } from 'chart.js';
import { Tooltip as ToolTip } from 'primereact/tooltip';
import { Bar, BarChart, CartesianGrid, LabelList, ReferenceLine, ResponsiveContainer, Tooltip, XAxis, YAxis, Legend } from 'recharts';
import { TabView, TabPanel } from 'primereact/tabview';
import { checkRoleAccessByRoleIds, filterSubmissionsByFiscalYear, groupArrayByKeys, removeDuplicatesFromArrayByKey } from '../../../components/BGHF/helper';
import Swal from 'sweetalert2';
import { BlockUI } from 'primereact/blockui'
import { Accordion, AccordionTab } from 'primereact/accordion';
import { SplitButton } from 'react-bootstrap';
import { ReadMoreComponent } from '../../../components/Forms/ReadMoreComponent';
const barColors1 = ['#B13409', '#DA6036', '#FF6F3E', '#FF9D7C', '#FFAF3D', '#F5C37B', '#FFD22E', '#FFEEAE', '#C0FFDC', '#00B08C80', '#00B08C', '#C0FFDC', '#B13409', '#DA6036', '#FF6F3E', '#FF9D7C', '#FFAF3D', '#F5C37B', '#FFD22E', '#FFEEAE', '#C0FFDC', '#00B08C80', '#00B08C', '#C0FFDC']
const barColors2 = ['#0041C3', '#216BFF', '#D1E1FF', '#475467', '#00B08C', '#C0FFDC', '#5B8FF7', '#BDD1F7', '#0041C3', '#216BFF', '#D1E1FF', '#475467', '#00B08C', '#C0FFDC', '#5B8FF7', '#BDD1F7']

const ApproverDB = () => {

    const [block, setBlock] = useState(false)
    const login_data = useSelector((state) => state.user.userdetail);
    const admin_data = useSelector((state) => state.userlist.admindetail);
    const [quantitativesubmission, setQuantitativeSubmission] = useState([])
    const [overallQuantitativeSubmission, setOverallQuantitativeSubmission] = useState([])
    const userList = useSelector(state => state.userlist.userList)
    const [isDrilledDown, setIsDrilledDown] = useState(false);
    const [labels, setLabels] = useState([])
    const fymonth = 1
    const forceUpdate = useForceUpdate()
    const stackBar = ['rgb(22, 96, 130)', 'rgb(233, 113, 50)']
    const [apief, setApiEF] = useState([])
    const [assignment, setAssignment] = useState([])
    const [filter, setFilter] = useState({ year: null, category: 0, country: null, city: null, location: null })
    const [chartfilter, setChartFilter] = useState({ year: null, category: 0, country: 0, city: null, location: null })
    const [locList, setLocList] = useState({ country: [], city: [], location: [] })
    const [locList2, setLocList2] = useState({ country: [], city: [], location: [] })
    const [graphData, setGraphData] = useState({ data: [], fields: [] })
    const [data, setData] = useState({ data: [], fields: [] })


    const [graphData2, setGraphData2] = useState({ sc_ghg: [], sc_usage: [], fields: [] })
    const [graphData3, setGraphData3] = useState({ fg_ghg: [], fg_usage: [], fields: [] })
    const [methodologydialog, setMethodologyDialog] = useState(false)
    const [methodology, setMethodology] = useState({ text: '', indicator: '' })
    const [approvedindicator, setApprovedIndicator] = useState([])
    const [xasixlabel, setXAxisLabels] = useState([])
    const [note, setNote] = useState('');
    const [commentlist, setCommentList] = useState([]);
    const [subcat, setSubCat] = useState({ one: [], two: [], three: [], four: [] })
    const [sourcedialog, setSourceDialog] = useState(false)
    const [dcflist, setDcfList] = useState([])
    const [selectedIndicator, setSelectedIndicator] = useState(null)
    const [selectednoteindex, setSelectedNoteIndex] = useState(null)

    const [sourcelist, setSourceList] = useState([])
    const [actindicator, setActInidicator] = useState(null)
    const [yearoptions, setYearOptions] = useState([])
    const [visible, setVisible] = useState(false);
    const [notevisible, setNoteVisible] = useState(false);
    const [chartdialog, setChartDialog] = useState(false)
    const [value, setValue] = useState('');
    const [visibleBars, setVisibleBars] = useState({
        scope1: true,
        scope2: true
    });

    useEffect(() => {
        console.log(visibleBars)
    }, [visibleBars])
    const status = [{ name: 'Approved and Locked', id: 1 }, { name: 'Pending Approval', id: 2 }, { name: 'Pending Submissions', id: 3 }, { name: 'Unlocked for Changes and Comments', id: 4 }]
    const getCheckboxLabels = (frequency, header) => {

        if (frequency === 1) return [{ name: 'Annually', checked: true }];
        if (frequency === 2) return [{ name: 'H1', checked: true }, { name: 'H2', checked: true }];
        return [{ name: 'Q1', checked: true }, { name: 'Q2', checked: true }, { name: 'Q3', checked: true }, { name: 'Q4', checked: true }].slice(0, frequency);
    };

    const [indicator, setindicator] = useState([])
    const [indicatorbk, setindicatorBk] = useState([])
    const [rawsitelist, setRawSiteList] = useState([])
    const getValidTierIds = (locationData, tier1_id, tier2_id, tier3_id) => {
        const countries = new Set();
        const regions = new Set();
        const businessUnits = new Set();

        locationData.forEach(country => {
            if (tier1_id === 0 || tier1_id === country.id) {
                countries.add(country.id);

                country.locationTwos.forEach(region => {
                    if (tier2_id === 0 || tier2_id === region.id) {
                        regions.add(region.id);

                        region.locationThrees.forEach(businessUnit => {
                            if (tier3_id === 0 || (tier2_id === 0 && tier3_id === null) || tier3_id === businessUnit.id) {
                                businessUnits.add(businessUnit.id);
                            }
                        });
                    }
                });
            }
        });

        return { countries: Array.from(countries), regions: Array.from(regions), businessUnits: Array.from(businessUnits) };
    };

    const filterDataByTierAndLocation = (data, locationData, tier1_id, tier2_id, tier3_id) => {
        if (tier1_id === 0 && tier2_id === null && tier3_id === null) {
            return data; // If tier is 0, null, null return the given data
        }

        const { countries, regions, businessUnits } = getValidTierIds(locationData, tier1_id, tier2_id, tier3_id);


        return data.filter(item => {
            if (tier1_id !== 0 && tier2_id === 0 && tier3_id === null) {
                // Case when we want all regions and sites under a country
                return (item.level === 1 && countries.includes(item.locationId)) ||
                    (item.level === 2 && regions.includes(item.locationId)) ||
                    (item.level === 3 && businessUnits.includes(item.locationId));
            } else if (tier1_id !== 0 && tier2_id !== 0 && tier3_id === 0) {
                // Case when we want a specific region and all its sites
                return (item.level === 2 && regions.includes(item.locationId)) ||
                    (item.level === 3 && businessUnits.includes(item.locationId));
            } else if (tier1_id !== 0 && tier2_id !== 0 && tier3_id !== 0) {
                // Case when we want a specific site
                return item.level === 3 && businessUnits.includes(item.locationId);
            } else {
                // Case when we want only the specific country
                return item.level === 1 && countries.includes(item.locationId);
            }
        });
    };
    const updateLocationFilter = (obj, val) => {
        setSelectedIndicator(null);
        let item = { ...filter, [obj]: val }
        let selected_item = { country: 0, city: 0, location: 0 }
        let country_list = []
        let city_list = [{ name: 'All Regions', id: 0 }]
        let location_list = [{ name: 'All Business Unit', id: 0 }]
        setSelectedIndicator(null);
        rawsitelist.forEach((country) => {
            country_list.push({ name: country.name, id: country.id })
            if (country.id === item.country || item.country === 0) {
                if (country.locationTwos) {
                    country.locationTwos.forEach((city) => {
                        city_list.push({ name: city.name, id: city.id })
                        if (city.id === item.city || item.city === 0) {
                            if (city.locationThrees) {
                                city.locationThrees.forEach((site) => {
                                    location_list.push({ name: site.name, id: site.id })

                                })
                            }
                        }
                    })

                }

            }

        })

        renderIndicatorValues(item, JSON.parse(JSON.stringify(quantitativesubmission)), JSON.parse(JSON.stringify(overallQuantitativeSubmission)))
        setFilter(item)
        setLocList((prev) => ({ ...prev, 'country': country_list, 'city': city_list, 'location': location_list }))

    }
    const updateGraphLocationFilter = (obj, val, topindex) => {
        let item = { ...chartfilter, [obj]: val }
        let selected_item = { country: 0, city: 0, location: 0 }
        let country_list = []
        let city_list = [{ name: 'All Regions', id: 0 }]
        let location_list = [{ name: 'All Business Unit', id: 0 }]
        rawsitelist.forEach((country) => {
            country_list.push({ name: country.name, id: country.id })
            if (country.id === item.country || item.country === 0) {
                if (country.locationTwos) {
                    country.locationTwos.forEach((city) => {
                        city_list.push({ name: city.name, id: city.id })
                        if (city.id === item.city || item.city === 0) {
                            if (city.locationThrees) {
                                city.locationThrees.forEach((site) => {
                                    location_list.push({ name: site.name, id: site.id })

                                })
                            }
                        }
                    })

                }

            }

        })
        if (obj === 'country') {
            item.city = val === 0 ? null : 0
            item.location = null
        }
        else if (obj === 'city') {

            item.location = val === 0 ? null : 0
        }

        setChartFilter(item)
        setLocList2((prev) => ({ ...prev, 'country': country_list, 'city': city_list, 'location': location_list }))
        if ((topindex !== null && topindex !== undefined)) {
            let index = indicator[topindex].indicatorId

            if (index === 1) {
                renderScope12Graph1(item, quantitativesubmission)
            } else if (index === 2) {
                renderScope1Graph1(item, quantitativesubmission)
            } else if (index === 3) {
                setIsDrilledDown(false)
                renderWomenMidSen(item, quantitativesubmission)
            } else if (index === 4) {
                renderWomenSen(item, quantitativesubmission)
            } else if (index === 5) {
                renderScope2Graph1(item, quantitativesubmission)

            } else if (index === 6) {
                renderWaterWithdrawGraph(item, quantitativesubmission)

            } else if (index === 7) {
                renderWaterConsumedGraph(item, quantitativesubmission)

            }
            else if (index === 8) {
                renderWaterDischargeGraph(item, quantitativesubmission)

            } else if (index === 9) {
                renderWUEGraph(item, quantitativesubmission)

            } else if (index === 10) {
                rendernonHazardousGraph(item, quantitativesubmission)

            }else if (index === 11) {
                rendernonHazardousDisposalGraph(item, quantitativesubmission)

            } else if (index === 12) {
                renderHazardousGraph(item, quantitativesubmission)

            }else if (index === 13) {
                renderHazardousDisposalGraph(item, quantitativesubmission)

            }
        }
        forceUpdate()




    }
    function sumByKey(array) {
        return array.reduce((accumulator, current) => {
            // Iterate over each key in the current object
            for (let key in current) {
                // Add the value to the accumulator, or initialize it if not already present
                if (accumulator[key]) {
                    accumulator[key] += current[key];
                } else {
                    accumulator[key] = current[key];
                }
            }
            return accumulator;
        }, {});
    }
    const renderWomenMidSen = (locfilter, submissions) => {
        const year = DateTime.utc().toLocal().year
        let currentYear = submissions.filter(i => i.dcfId === 275 && i.level === 1 && i.locationId === filter.country).filter(i => getRPTextFormat(i.reporting_period) === `Jan-${year} to Jun-${year}`)
        let prevYear1 = submissions.filter(i => i.dcfId === 275 && i.level === 1 && i.locationId === filter.country).filter(i => getRPTextFormat(i.reporting_period) === `Jul-${year - 3} to Dec-${year - 3}`)
        let prevYear2 = submissions.filter(i => i.dcfId === 275 && i.level === 1 && i.locationId === filter.country).filter(i => getRPTextFormat(i.reporting_period) === `Jul-${year - 2} to Dec-${year - 2}`)
        let prevYear3 = submissions.filter(i => i.dcfId === 275 && i.level === 1 && i.locationId === filter.country).filter(i => getRPTextFormat(i.reporting_period) === `Jul-${year - 1} to Dec-${year - 1}`)
        console.log(currentYear, prevYear1, prevYear2, prevYear3)
        let smg1 = sumByKey(prevYear1.map(i => i.response[0]).filter(i => i).map(i => ({ Men: i.DPAN613 + i.DPAN615, Women: i.DPAN614 + i.DPAN616 })))
        let smg2 = sumByKey(prevYear2.map(i => i.response[0]).filter(i => i).map(i => ({ Men: i.DPAN613 + i.DPAN615, Women: i.DPAN614 + i.DPAN616 })))
        let smg3 = sumByKey(prevYear3.map(i => i.response[0]).filter(i => i).map(i => ({ Men: i.DPAN613 + i.DPAN615, Women: i.DPAN614 + i.DPAN616 })))
        let smg4 = sumByKey(currentYear.map(i => i.response[0]).filter(i => i).map(i => ({ Men: i.DPAN613 + i.DPAN615, Women: i.DPAN614 + i.DPAN616 })))
        let dd4 = sumByKey(currentYear.map(i => i.response[0]).filter(i => i).map(i => (calculatePercentage(i))))
        let dd3 = sumByKey(prevYear3.map(i => i.response[0]).filter(i => i).map(i => (calculatePercentage(i))))
        let dd2 = sumByKey(prevYear2.map(i => i.response[0]).filter(i => i).map(i => (calculatePercentage(i))))
        let dd1 = sumByKey(prevYear1.map(i => i.response[0]).filter(i => i).map(i => (calculatePercentage(i))))


        console.log(dd1)
        setData({ fields: [{ key: 'Men', color: '#008080' }, { key: 'Women', color: '#FF8042' }], data: [{ month: 'H2 ' + (year - 3), Men: 0, Women: 0, ...smg1, ['H2 ' + (year - 3)]: Object.values(dd1) }, { month: 'H2 ' + (year - 2), Men: 0, Women: 0, ...smg2, ['H2 ' + (year - 2)]: Object.values(dd2) }, { month: 'H2 ' + (year - 1), Men: 0, Women: 0, ...smg3, ['H2 ' + (year - 1)]: Object.values(dd3) }, { month: 'H1 ' + year, Men: 0, Women: 0, ...smg4, ['H1 ' + (year)]: Object.values(dd4) }] })
        setGraphData({ fields: [{ key: 'Men', color: '#008080' }, { key: 'Women', color: '#FF8042' }], data: [{ month: 'H2 ' + (year - 3), Men: 0, Women: 0, ...smg1, ['H2 ' + (year - 3)]: Object.values(dd1) }, { month: 'H2 ' + (year - 2), Men: 0, Women: 0, ...smg2, ['H2 ' + (year - 2)]: Object.values(dd2) }, { month: 'H2 ' + (year - 1), Men: 0, Women: 0, ...smg3, ['H2 ' + (year - 1)]: Object.values(dd3) }, { month: 'H1 ' + year, Men: 0, Women: 0, ...smg4, ['H1 ' + (year)]: Object.values(dd4) }] })

        setChartDialog(true)

    }
    function calculatePercentage(data) {
        // Extract relevant values for each management level
        const seniorManagementMale = data.DPAN613;
        const seniorManagementFemale = data.DPAN614;
        const middleManagementMale = data.DPAN615;
        const middleManagementFemale = data.DPAN616;
        const nonManagementMale = data.DPAN617;
        const nonManagementFemale = data.DPAN618;

        // Helper function to calculate percentage
        const calculateMaleFemalePercentage = (male, female) => {
            const total = male + female;
            if (total === 0) {
                return { Men: 0, Women: 0 };
            }
            const malePercentage = parseFloat(((male / total) * 100).toFixed(0));
            const femalePercentage = parseFloat(((female / total) * 100).toFixed(0));
            return { Men: malePercentage, Women: femalePercentage };
        };

        // Calculate percentages for each management level
        const seniorManagement = calculateMaleFemalePercentage(seniorManagementMale, seniorManagementFemale);
        const middleManagement = calculateMaleFemalePercentage(middleManagementMale, middleManagementFemale);
        const nonManagement = calculateMaleFemalePercentage(nonManagementMale, nonManagementFemale);

        // Return the result in the required format
        return [
            { month: "Senior Management", Men: seniorManagement.Men, Women: seniorManagement.Women },
            { month: "Middle Management", Men: middleManagement.Men, Women: middleManagement.Women },
            { month: "Non-management", Men: nonManagement.Men, Women: nonManagement.Women }
        ];
    }

    const renderWomenSen = (locfilter, submissions) => {
        const year = DateTime.utc().toLocal().year
        let currentYear = submissions.filter(i => i.dcfId === 275 && i.level === 1 && i.locationId === filter.country).filter(i => getRPTextFormat(i.reporting_period) === `Jan-${year} to Jun-${year}`)
        let prevYear1 = submissions.filter(i => i.dcfId === 275 && i.level === 1 && i.locationId === filter.country).filter(i => getRPTextFormat(i.reporting_period) === `Jul-${year - 3} to Dec-${year - 3}`)
        let prevYear2 = submissions.filter(i => i.dcfId === 275 && i.level === 1 && i.locationId === filter.country).filter(i => getRPTextFormat(i.reporting_period) === `Jul-${year - 2} to Dec-${year - 2}`)
        let prevYear3 = submissions.filter(i => i.dcfId === 275 && i.level === 1 && i.locationId === filter.country).filter(i => getRPTextFormat(i.reporting_period) === `Jul-${year - 1} to Dec-${year - 1}`)
        console.log(currentYear, prevYear1, prevYear2, prevYear3)
        let smg1 = sumByKey(prevYear1.map(i => i.response[0]).filter(i => i).map(i => ({ Men: i.DPAN613, Women: i.DPAN614 })))
        let smg2 = sumByKey(prevYear2.map(i => i.response[0]).filter(i => i).map(i => ({ Men: i.DPAN613, Women: i.DPAN614 })))
        let smg3 = sumByKey(prevYear3.map(i => i.response[0]).filter(i => i).map(i => ({ Men: i.DPAN613, Women: i.DPAN614 })))
        let smg4 = sumByKey(currentYear.map(i => i.response[0]).filter(i => i).map(i => ({ Men: i.DPAN613, Women: i.DPAN614 })))

        setGraphData({ fields: [{ key: 'Men', color: '#008080' }, { key: 'Women', color: '#FF8042' }], data: [{ month: 'H2 ' + (year - 3), Men: 0, Women: 0, ...smg1 }, { month: 'H2 ' + (year - 2), Men: 0, Women: 0, ...smg2 }, { month: 'H2 ' + (year - 1), Men: 0, Women: 0, ...smg3 }, { month: 'H1 ' + year, Men: 0, Women: 0, ...smg4 }] })

        setChartDialog(true)
    }
    const renderScope12Graph1 = (locfilter, submissions, dcfIds) => {
        let currentYear = filterDataByTierAndLocation(filterSubmissionsByFiscalYear(submissions, filter.year, fymonth), rawsitelist, locfilter.country, locfilter.city, locfilter.location)
        let previousYear = filterDataByTierAndLocation(filterSubmissionsByFiscalYear(submissions, (filter.year - 1), fymonth), rawsitelist, locfilter.country, locfilter.city, locfilter.location)
        const curMTDMonths_ = formatDateStrings(generateMTDArray(filter.year, fymonth), 1)
        const curMTDMonths = splitArrayByFrequency(curMTDMonths_, 3).reduce((a, b) => [...a, ...b], [])
        const pyMTDMonths = reduceYearByOne(curMTDMonths)
        const curMTDMonthHeader = splitArrayByFrequency(curMTDMonths, 3).reduce((a, b) => [...a, ...b], [])
        const pyMTDMonthHeader = splitArrayByFrequency(pyMTDMonths, 3).reduce((a, b) => [...a, ...b], [])
        const curMTDMonthValue = groupByFrequency(curMTDMonthHeader, 1).map(i => { let data = getValueAsObject(i, currentYear, filter.year, [304, 305, 287]); console.log(data); return ({ rp: getRPTextFormat(i), scope1: data.filter(x => [304, 305].includes(x.dcfId)).reduce((a, b) => { return a + b.ghg }, 0), scope2: data.filter(x => x.dcfId === 287).reduce((a, b) => { return a + b.ghg }, 0) }) })
        const prevMTDMonthValue = groupByFrequency(pyMTDMonthHeader, 1).map(i => { let data = getValueAsObject(i, previousYear, (filter.year - 1), [304, 305, 287]); console.log(data); return ({ rp: getRPTextFormat(i), scope1: data.filter(x => [304, 305].includes(x.dcfId)).reduce((a, b) => { return a + b.ghg }, 0), scope2: data.filter(x => x.dcfId === 287).reduce((a, b) => { return a + b.ghg }, 0) }) })
        let requireRp = curMTDMonths
        let oldRp = pyMTDMonths
        let graphData1 = [], count = 0, curs1 = [], curs2 = [], prevs1 = [], prevs2 = [], graphData2 = [
            {
                label: 'Scope 1 -' + filter.year,
                data: [],
                backgroundColor: stackBar[0],
                stack: 'Stack 0',
            },
            {
                label: 'Scope 2 -' + filter.year,
                data: [],
                backgroundColor: stackBar[1],
                stack: 'Stack 0',
            },
            {
                label: 'Scope 1 -' + (filter.year - 1),
                data: [],
                backgroundColor: stackBar[0],
                stack: 'Stack 1',
            },
            {
                label: 'Scope 2 -' + (filter.year - 1),
                data: [],
                backgroundColor: stackBar[1],
                stack: 'Stack 1',
            },
        ]
        for (const rps of requireRp) {
            // graphData2[0].data.push(curMTDMonthValue[count].scope1)
            // graphData2[1].data.push(curMTDMonthValue[count].scope2)
            // graphData2[2].data.push(prevMTDMonthValue[count].scope1)
            // graphData2[3].data.push(prevMTDMonthValue[count].scope2)
            graphData1.push({ month: oldRp[count], 'Scope 1': prevMTDMonthValue[count].scope1.toFixed(3), 'Scope 2': prevMTDMonthValue[count].scope2.toFixed(3) })
            graphData1.push({ month: rps, 'Scope 1': curMTDMonthValue[count].scope1.toFixed(3), 'Scope 2': curMTDMonthValue[count].scope2.toFixed(3) })

            graphData1.push({ month: '', 'Scope 1': 0, 'Scope 2': 0 })
            // graphData1.push({
            //     name: rps.split('-')[0],
            //     ['Scope 1 :'+filter.year]: curMTDMonthValue[count].scope1,
            //     ['Scope 2 :'+filter.year]: curMTDMonthValue[count].scope2,
            //     ['Scope 1 :'+(filter.year-1)]: prevMTDMonthValue[count].scope1,
            //     ['Scope 2 :'+(filter.year-1)]: prevMTDMonthValue[count].scope2
            // })
            count++
        }

        console.log(graphData1, requireRp, oldRp)
        setTimeout(() => {
            // showStackedBarInGroupChartWithoutDrill( graphData2, 'scope1_2',{measure:'tCO2e',label:requireRp,legend:['Scope 1','Scope 2']},0 )
        }, 500)

        console.log(graphData1)

        setGraphData({ fields: [{ key: 'Scope 1', color: '#008080' }, { key: 'Scope 2', color: '#FF8042' }], data: graphData1 })
        setChartDialog(true)

    }
    const renderScope1Graph1 = (locfilter, submissions) => {
        let currentYear = filterDataByTierAndLocation(filterSubmissionsByFiscalYear(submissions, filter.year, fymonth), rawsitelist, locfilter.country, locfilter.city, locfilter.location)
        let previousYear = filterDataByTierAndLocation(filterSubmissionsByFiscalYear(submissions, (filter.year - 1), fymonth), rawsitelist, locfilter.country, locfilter.city, locfilter.location)
        const curMTDMonths_ = formatDateStrings(generateMTDArray(filter.year, fymonth), 1)
        const curMTDMonths = splitArrayByFrequency(curMTDMonths_, 3).reduce((a, b) => [...a, ...b], [])
        const pyMTDMonths = reduceYearByOne(curMTDMonths)
        const curMTDMonthHeader = splitArrayByFrequency(curMTDMonths, 3).reduce((a, b) => [...a, ...b], [])
        const pyMTDMonthHeader = splitArrayByFrequency(pyMTDMonths, 3).reduce((a, b) => [...a, ...b], [])
        const curMTDMonthValue = groupByFrequency(curMTDMonthHeader, 1).map(i => { let data = getValueAsObject(i, currentYear, filter.year, [304, 305]); console.log(data); return ({ rp: i[0], scope1: data.filter(x => x.dcfId === 304).reduce((a, b) => { return a + b.ghg }, 0), scope2: data.filter(x => x.dcfId === 305).reduce((a, b) => { return a + b.ghg }, 0) }) })
        const prevMTDMonthValue = groupByFrequency(pyMTDMonthHeader, 1).map(i => { let data = getValueAsObject(i, previousYear, (filter.year - 1), [304, 305]); console.log(data); return ({ rp: i[0], scope1: data.filter(x => x.dcfId === 304).reduce((a, b) => { return a + b.ghg }, 0), scope2: data.filter(x => x.dcfId === 305).reduce((a, b) => { return a + b.ghg }, 0) }) })
        const curMTDMonthObjectValue = groupByFrequency(curMTDMonthHeader, 1).map(i => { let data = getValueAsUnShapedObject(i, currentYear, filter.year, [304, 305]); console.log(data); return ({ rp: i[0], scope1: data.filter(x => x.dcfId === 304), scope2: data.filter(x => x.dcfId === 305) }) })

        let requireRp = curMTDMonths
        let oldRp = pyMTDMonths
        let graphData1 = [], count = 0, curs1 = [], curs2 = [], prevs1 = [], prevs2 = [], graphData2 = [
            {
                label: 'Emissions from fuel consumption -' + filter.year,
                data: [],
                backgroundColor: stackBar[0],
                stack: 'Stack 0',
            },
            {
                label: 'Emissions from refrigerant -' + filter.year,
                data: [],
                backgroundColor: stackBar[1],
                stack: 'Stack 0',
            },
            {
                label: 'Emissions from fuel consumption -' + (filter.year - 1),
                data: [],
                backgroundColor: stackBar[0],
                stack: 'Stack 1',
            },
            {
                label: 'Emissions from refrigerant -' + (filter.year - 1),
                data: [],
                backgroundColor: stackBar[1],
                stack: 'Stack 1',
            },
        ], fuel_ghg_Data = [], fuel_usage_Data = [], gas_ghg_Data = [], gas_usage_Data = []
        for (const rps of requireRp) {
            console.log(curMTDMonthObjectValue[count])
            // graphData2[0].data.push(curMTDMonthValue[count].scope1)
            // graphData2[1].data.push(curMTDMonthValue[count].scope2)
            // graphData2[2].data.push(prevMTDMonthValue[count].scope1)
            // graphData2[3].data.push(prevMTDMonthValue[count].scope2)
            let fuel_ghg = { month: rps }, fuel_usage = { month: rps }, gas_ghg = { month: rps }, gas_usage = { month: rps }
            if (curMTDMonthObjectValue[count].scope1.length && curMTDMonthObjectValue[count].scope1.some(x => x.ghg && x.ghg.length)) {
                curMTDMonthObjectValue[count].scope1.forEach((ele) => {
                    ele.ghg.forEach((item) => {
                        fuel_ghg[item.fuel_type] = parseFloat(item.ghg.toFixed(2))
                        fuel_usage[item.fuel_type] = parseFloat(item.usage.toFixed(2))
                    })



                })
                curMTDMonthObjectValue[count].scope2.forEach((ele) => {
                    ele.ghg.forEach((item) => {
                        gas_ghg[item.gas_type] = parseFloat(item.ghg.toFixed(2))
                        gas_usage[item.gas_type] = parseFloat(item.usage.toFixed(2))
                    })



                })
            }
            fuel_ghg_Data.push(fuel_ghg)
            fuel_usage_Data.push(fuel_usage)
            gas_ghg_Data.push(gas_ghg)
            gas_usage_Data.push(gas_usage)
            graphData1.push({ month: oldRp[count], 'Emissions from fuel consumption': prevMTDMonthValue[count].scope1.toFixed(3), 'Emissions from refrigerant': prevMTDMonthValue[count].scope2.toFixed(3) })
            graphData1.push({ month: rps, 'Emissions from fuel consumption': curMTDMonthValue[count].scope1.toFixed(3), 'Emissions from refrigerant': curMTDMonthValue[count].scope2.toFixed(3) })

            graphData1.push({ month: '', 'Emissions from fuel consumption': 0, 'Emissions from refrigerant': 0 })

            count++
        }

        console.log(fuel_ghg_Data, fuel_usage_Data, gas_ghg_Data, gas_usage_Data)
        setTimeout(() => {
            // showStackedBarInGroupChartWithoutDrill( graphData2, 'scope1_2',{measure:'tCO2e',label:requireRp,legend:['Scope 1','Scope 2']},0 )
        }, 500)
        let scfields = []
        let fgfields = []
        fuel_ghg_Data.forEach((i) => {
            Object.keys(i).forEach((key) => {
                if (key !== 'month') {
                    if (!scfields.map(i => i.key).includes(key)) {
                        scfields.push({ key })
                    }
                }
            })
        })
        gas_ghg_Data.forEach((i) => {
            Object.keys(i).forEach((key) => {
                if (key !== 'month') {
                    if (!fgfields.map(i => i.key).includes(key)) {
                        fgfields.push({ key })
                    }
                }
            })
        })
        console.log(graphData1)

        setGraphData({ fields: [{ key: 'Emissions from fuel consumption', color: '#008080' }, { key: 'Emissions from refrigerant', color: '#FF8042' }], data: graphData1 })
        setGraphData2({ fields: scfields.map((i, j) => ({ ...i, color: barColors1[j] })), sc_ghg: fuel_ghg_Data, sc_usage: fuel_usage_Data })
        setGraphData3({ fields: fgfields.map((i, j) => ({ ...i, color: barColors2[j] })), fg_ghg: gas_ghg_Data, fg_usage: gas_usage_Data })
        setChartDialog(true)
    }
    const renderWaterWithdrawGraph = (locfilter, submissions) => {
        let currentYear = filterDataByTierAndLocation(filterSubmissionsByFiscalYear(submissions, filter.year, fymonth), rawsitelist, locfilter.country, locfilter.city, locfilter.location)
        let previousYear = filterDataByTierAndLocation(filterSubmissionsByFiscalYear(submissions, (filter.year - 1), fymonth), rawsitelist, locfilter.country, locfilter.city, locfilter.location)
        const curMTDMonths_ = formatDateStrings(generateMTDArray(filter.year, fymonth), 1)
        const curMTDMonths = splitArrayByFrequency(curMTDMonths_, 3).reduce((a, b) => [...a, ...b], [])
        const pyMTDMonths = reduceYearByOne(curMTDMonths)
        const curMTDMonthHeader = splitArrayByFrequency(curMTDMonths, 3).reduce((a, b) => [...a, ...b], [])
        const pyMTDMonthHeader = splitArrayByFrequency(pyMTDMonths, 3).reduce((a, b) => [...a, ...b], [])
        const curMTDMonthValue = groupByFrequency(curMTDMonthHeader, 1).map(i => {
            let data = getValueAsRawObject(i, currentYear, filter.year, [285]); console.log(data); return ({
                rp: i[0], withdraw: data.reduce((totalSum, obj) => {
                    return totalSum + ['DPAN1159', 'DPAN1161'].reduce((sum, key) => sum + (obj[key] || 0), 0);
                }, 0)
            })
        })
        const prevMTDMonthValue = groupByFrequency(pyMTDMonthHeader, 1).map(i => {
            let data = getValueAsRawObject(i, previousYear, filter.year, [285]); console.log(data); return ({
                rp: i[0], withdraw: data.reduce((totalSum, obj) => {
                    return totalSum + ['DPAN1159', 'DPAN1161'].reduce((sum, key) => sum + (obj[key] || 0), 0);
                }, 0)
            })
        })
        const curMTDMonthObjectValue = groupByFrequency(curMTDMonthHeader, 1).map(i => { let data = getValueAsRawObject(i, currentYear, filter.year, [285]); console.log(data); return ({ rp: i[0], withdraw: data }) })

        let requireRp = curMTDMonths
        let oldRp = pyMTDMonths
        let graphData1 = [], count = 0, curs1 = [], curs2 = [], prevs1 = [], prevs2 = [], graphData2 = [
            {
                label: 'Emissions from fuel consumption -' + filter.year,
                data: [],
                backgroundColor: stackBar[0],
                stack: 'Stack 0',
            },
            {
                label: 'Emissions from refrigerant -' + filter.year,
                data: [],
                backgroundColor: stackBar[1],
                stack: 'Stack 0',
            },
            {
                label: 'Emissions from fuel consumption -' + (filter.year - 1),
                data: [],
                backgroundColor: stackBar[0],
                stack: 'Stack 1',
            },
            {
                label: 'Emissions from refrigerant -' + (filter.year - 1),
                data: [],
                backgroundColor: stackBar[1],
                stack: 'Stack 1',
            },
        ], withdrawCategory = []
        const DPAN1158Options = [
            { name: "Groundwater Wells Operated", id: 1 },

            { name: "Effluent/ Sewage Treatment Recycle", id: 2 },
            { name: 'Rainwater Harvesting', id: 3 },
            { name: 'Others', id: 99 }
        ];
        const DPAN1200Options = [
            { name: "Surface water", id: 1 },
            { name: "Ground Water", id: 2 },
            { name: "Sea Water", id: 3 },
            { name: 'Imported Water from Industrial District', id: 8 },
            { name: 'Unknown', id: 9 }
        ];
        for (const rps of requireRp) {
            console.log(curMTDMonthObjectValue[count])
            let withdrawType = { month: rps }
            if (curMTDMonthObjectValue[count].withdraw.length) {

                curMTDMonthObjectValue[count].withdraw.forEach((ele) => {
                    console.log(ele)
                    if (ele.DPAN0048 === 6) {
                        if (withdrawType[DPAN1158Options.find(x => x.id === ele.DPAN1158).name]) {
                            withdrawType[DPAN1158Options.find(x => x.id === ele.DPAN1158).name] += ele.DPAN1159
                        } else {
                            withdrawType[DPAN1158Options.find(x => x.id === ele.DPAN1158).name] = ele.DPAN1159
                        }
                    } else if (ele.DPAN0048 === 5) {
                        if (withdrawType[DPAN1200Options.find(x => x.id === ele.DPAN1200).name]) {
                            withdrawType[DPAN1200Options.find(x => x.id === ele.DPAN1200).name] += ele.DPAN1161
                        } else {
                            withdrawType[DPAN1200Options.find(x => x.id === ele.DPAN1200).name] = ele.DPAN1161
                        }

                    }



                })
            }
            withdrawCategory.push(withdrawType)

            graphData1.push({ month: oldRp[count], [`${filter.year - 1}`]: prevMTDMonthValue[count].withdraw.toFixed(3) })
            graphData1.push({ month: rps, [`${filter.year}`]: curMTDMonthValue[count].withdraw.toFixed(3) })

            graphData1.push({ month: '', '': 0 })

            count++
        }
        let waterwithdrawlfields = []

        withdrawCategory.forEach((i) => {
            Object.keys(i).forEach((key) => {
                if (key !== 'month') {
                    if (!waterwithdrawlfields.map(i => i.key).includes(key)) {
                        waterwithdrawlfields.push({ key })
                    }
                }
            })
        })
        console.log(withdrawCategory, graphData1)
        setGraphData({ fields: [{ key: `${filter.year - 1}`, color: '#008080' }, { key: `${filter.year}`, color: '#FF8042' }], data: graphData1 })
        setGraphData2({ fields: waterwithdrawlfields.map((i, j) => ({ ...i, color: barColors1[j] })), data: withdrawCategory, unit: 'm3' })

        // setGraphData3({ fields: fgfields.map((i, j) => ({ ...i, color: barColors2[j] })), fg_ghg: gas_ghg_Data, fg_usage: gas_usage_Data })
        setChartDialog(true)

    }
    const rendernonHazardousGraph = (locfilter, submissions) => {
        let currentYear = filterDataByTierAndLocation(filterSubmissionsByFiscalYear(submissions, filter.year, fymonth), rawsitelist, locfilter.country, locfilter.city, locfilter.location)
        let previousYear = filterDataByTierAndLocation(filterSubmissionsByFiscalYear(submissions, (filter.year - 1), fymonth), rawsitelist, locfilter.country, locfilter.city, locfilter.location)
        const curMTDMonths_ = formatDateStrings(generateMTDArray(filter.year, fymonth), 1)
        const curMTDMonths = splitArrayByFrequency(curMTDMonths_, 3).reduce((a, b) => [...a, ...b], [])
        const pyMTDMonths = reduceYearByOne(curMTDMonths)
        const curMTDMonthHeader = splitArrayByFrequency(curMTDMonths, 3).reduce((a, b) => [...a, ...b], [])
        const pyMTDMonthHeader = splitArrayByFrequency(pyMTDMonths, 3).reduce((a, b) => [...a, ...b], [])
        const curMTDMonthValue = groupByFrequency(curMTDMonthHeader, 1).map(i => {
            let data = getValueAsRawObject(i, currentYear, filter.year, [307]); console.log(data); return ({
                rp: i[0], nonhazard: getNonHazardousValue(data)
            })
        })
        const prevMTDMonthValue = groupByFrequency(pyMTDMonthHeader, 1).map(i => {
            let data = getValueAsRawObject(i, previousYear, filter.year, [307]); console.log(data); return ({
                rp: i[0],nonhazard: getNonHazardousValue(data)
            })
        })
        const curMTDMonthObjectValue = groupByFrequency(curMTDMonthHeader, 1).map(i => { let data = getValueAsRawObject(i, currentYear, filter.year, [307]); console.log(data); return ({ rp: i[0], nonhazard: data }) })

        let requireRp = curMTDMonths
        let oldRp = pyMTDMonths
        let graphData1 = [], count = 0, curs1 = [], curs2 = [], prevs1 = [], prevs2 = [], graphData2 = [
            {
                label: 'Emissions from fuel consumption -' + filter.year,
                data: [],
                backgroundColor: stackBar[0],
                stack: 'Stack 0',
            },
            {
                label: 'Emissions from refrigerant -' + filter.year,
                data: [],
                backgroundColor: stackBar[1],
                stack: 'Stack 0',
            },
            {
                label: 'Emissions from fuel consumption -' + (filter.year - 1),
                data: [],
                backgroundColor: stackBar[0],
                stack: 'Stack 1',
            },
            {
                label: 'Emissions from refrigerant -' + (filter.year - 1),
                data: [],
                backgroundColor: stackBar[1],
                stack: 'Stack 1',
            },
        ], nonhazardCategory = []
        const DPAH500Options = [
            { name: "Paper", id: 1 },
            { name: "Plastic", id: 2 },
            { name: "Metals", id: 3 },
            { name: "Glass", id: 11 },
            { name: "Wood", id: 13 },
            { name: "Electrical items - WEEE", id: 4 },
            { name: "Electrical items - Batteries", id: 5 },
            { name: "Mixed Commecial and industrial waste", id: 6 },
            { name: "Organic: mixed food and garden waste", id: 7 },
            { name: "Organic: garden waste", id: 8 },
            { name: "Organic: food and drink waste", id: 9 },
            { name: "Household residual waste", id: 10 },
            { name: "Others", id: 12 },
        ];
         
        for (const rps of requireRp) {
            console.log(curMTDMonthObjectValue[count])
            let nonhazardType = { month: rps }
            if (curMTDMonthObjectValue[count].nonhazard.length) {

                curMTDMonthObjectValue[count].nonhazard.forEach((ele) => {
                    console.log(ele)
                    let tonnes = 0
                    let amount = ele.DPAH502
                            let density = ele.DPAH503
                            if(ele.DPAH501 === 1){
                                tonnes+= (amount/1000)
                            }else if(ele.DPAH501 === 2){
                                tonnes+= (amount* density *  0.000001)
                            }else if(ele.DPAH501 === 1){
                                tonnes+= (amount* density) / 1000
                
                            }
                        if (nonhazardType[DPAH500Options.find(x => x.id === ele.DPAH500).name]) {
                            
                            nonhazardType[DPAH500Options.find(x => x.id === ele.DPAH500).name] += tonnes
                        } else {
                            nonhazardType[DPAH500Options.find(x => x.id === ele.DPAH500).name] = tonnes
                        }
                    



                })
            }
            nonhazardCategory.push(nonhazardType)

            graphData1.push({ month: oldRp[count], [`${filter.year - 1}`]: prevMTDMonthValue[count].nonhazard.toFixed(3) })
            graphData1.push({ month: rps, [`${filter.year}`]: curMTDMonthValue[count].nonhazard.toFixed(3) })

            graphData1.push({ month: '', '': 0 })

            count++
        }
        let wnonhazardfields = []

        nonhazardCategory.forEach((i) => {
            Object.keys(i).forEach((key) => {
                if (key !== 'month') {
                    if (!wnonhazardfields.map(i => i.key).includes(key)) {
                        wnonhazardfields.push({ key })
                    }
                }
            })
        })
        console.log(nonhazardCategory,wnonhazardfields,nonhazardCategory)
        setGraphData({ fields: [{ key: `${filter.year - 1}`, color: '#008080' }, { key: `${filter.year}`, color: '#FF8042' }], data: graphData1 })
        setGraphData2({ fields: wnonhazardfields.map((i, j) => ({ ...i, color: barColors1[j] })), data: nonhazardCategory, unit: 'tonnes' })

        // setGraphData3({ fields: fgfields.map((i, j) => ({ ...i, color: barColors2[j] })), fg_ghg: gas_ghg_Data, fg_usage: gas_usage_Data })
        setChartDialog(true)

    }
    const rendernonHazardousDisposalGraph = (locfilter, submissions) => {
        let currentYear = filterDataByTierAndLocation(filterSubmissionsByFiscalYear(submissions, filter.year, fymonth), rawsitelist, locfilter.country, locfilter.city, locfilter.location)
        let previousYear = filterDataByTierAndLocation(filterSubmissionsByFiscalYear(submissions, (filter.year - 1), fymonth), rawsitelist, locfilter.country, locfilter.city, locfilter.location)
        const curMTDMonths_ = formatDateStrings(generateMTDArray(filter.year, fymonth), 1)
        const curMTDMonths = splitArrayByFrequency(curMTDMonths_, 3).reduce((a, b) => [...a, ...b], [])
        const pyMTDMonths = reduceYearByOne(curMTDMonths)
        const curMTDMonthHeader = splitArrayByFrequency(curMTDMonths, 3).reduce((a, b) => [...a, ...b], [])
        const pyMTDMonthHeader = splitArrayByFrequency(pyMTDMonths, 3).reduce((a, b) => [...a, ...b], [])
        const curMTDMonthValue = groupByFrequency(curMTDMonthHeader, 1).map(i => {
            let data = getValueAsRawObject(i, currentYear, filter.year, [307]); console.log(data); return ({
                rp: i[0], nonhazard: getNonHazardousValue(data)
            })
        })
        const prevMTDMonthValue = groupByFrequency(pyMTDMonthHeader, 1).map(i => {
            let data = getValueAsRawObject(i, previousYear, filter.year, [307]); console.log(data); return ({
                rp: i[0],nonhazard: getNonHazardousValue(data)
            })
        })
        const curMTDMonthObjectValue = groupByFrequency(curMTDMonthHeader, 1).map(i => { let data = getValueAsRawObject(i, currentYear, filter.year, [307]); console.log(data); return ({ rp: i[0], nonhazard: data }) })

        let requireRp = curMTDMonths
        let oldRp = pyMTDMonths
        let graphData1 = [], count = 0, curs1 = [], curs2 = [], prevs1 = [], prevs2 = [], graphData2 = [
            {
                label: 'Emissions from fuel consumption -' + filter.year,
                data: [],
                backgroundColor: stackBar[0],
                stack: 'Stack 0',
            },
            {
                label: 'Emissions from refrigerant -' + filter.year,
                data: [],
                backgroundColor: stackBar[1],
                stack: 'Stack 0',
            },
            {
                label: 'Emissions from fuel consumption -' + (filter.year - 1),
                data: [],
                backgroundColor: stackBar[0],
                stack: 'Stack 1',
            },
            {
                label: 'Emissions from refrigerant -' + (filter.year - 1),
                data: [],
                backgroundColor: stackBar[1],
                stack: 'Stack 1',
            },
        ], nonhazardCategory = []
        const DPAH504Options = [
            { name: 'Incineration (w/o Energy Recovery)', id: 1 },
            { name: 'Incineration (with Energy Recovery)', id: 2 },
            { name: 'Landfill', id: 3 },
            { name: 'Recycle', id: 4 },
            { name: 'Composting', id: 5 },
            { name: 'Waste Recovery', id: 6 },
            { name: 'Unknown', id: 7 },
            { name: 'Others', id: 8 }
        ]
         
        for (const rps of requireRp) {
            console.log(curMTDMonthObjectValue[count])
            let nonhazardType = { month: rps }
            if (curMTDMonthObjectValue[count].nonhazard.length) {

                curMTDMonthObjectValue[count].nonhazard.forEach((ele) => {
                    console.log(ele)
                    let tonnes = 0
                    let amount = ele.DPAH502
                            let density = ele.DPAH503
                            if(ele.DPAH501 === 1){
                                tonnes+= (amount/1000)
                            }else if(ele.DPAH501 === 2){
                                tonnes+= (amount* density *  0.000001)
                            }else if(ele.DPAH501 === 1){
                                tonnes+= (amount* density) / 1000
                
                            }
                        if (nonhazardType[DPAH504Options.find(x => x.id === ele.DPAH504).name]) {
                            
                            nonhazardType[DPAH504Options.find(x => x.id === ele.DPAH504).name] += tonnes
                        } else {
                            nonhazardType[DPAH504Options.find(x => x.id === ele.DPAH504).name] = tonnes
                        }
                    



                })
            }
            nonhazardCategory.push(nonhazardType)

            graphData1.push({ month: oldRp[count], [`${filter.year - 1}`]: prevMTDMonthValue[count].nonhazard.toFixed(3) })
            graphData1.push({ month: rps, [`${filter.year}`]: curMTDMonthValue[count].nonhazard.toFixed(3) })

            graphData1.push({ month: '', '': 0 })

            count++
        }
        let wnonhazardfields = []

        nonhazardCategory.forEach((i) => {
            Object.keys(i).forEach((key) => {
                if (key !== 'month') {
                    if (!wnonhazardfields.map(i => i.key).includes(key)) {
                        wnonhazardfields.push({ key })
                    }
                }
            })
        })
        console.log(nonhazardCategory,wnonhazardfields,nonhazardCategory)
        setGraphData({ fields: [{ key: `${filter.year - 1}`, color: '#008080' }, { key: `${filter.year}`, color: '#FF8042' }], data: graphData1 })
        setGraphData2({ fields: wnonhazardfields.map((i, j) => ({ ...i, color: barColors1[j] })), data: nonhazardCategory, unit: 'tonnes' })

        // setGraphData3({ fields: fgfields.map((i, j) => ({ ...i, color: barColors2[j] })), fg_ghg: gas_ghg_Data, fg_usage: gas_usage_Data })
        setChartDialog(true)

    }
    const renderHazardousGraph = (locfilter, submissions) => {
        let currentYear = filterDataByTierAndLocation(filterSubmissionsByFiscalYear(submissions, filter.year, fymonth), rawsitelist, locfilter.country, locfilter.city, locfilter.location)
        let previousYear = filterDataByTierAndLocation(filterSubmissionsByFiscalYear(submissions, (filter.year - 1), fymonth), rawsitelist, locfilter.country, locfilter.city, locfilter.location)
        const curMTDMonths_ = formatDateStrings(generateMTDArray(filter.year, fymonth), 1)
        const curMTDMonths = splitArrayByFrequency(curMTDMonths_, 3).reduce((a, b) => [...a, ...b], [])
        const pyMTDMonths = reduceYearByOne(curMTDMonths)
        const curMTDMonthHeader = splitArrayByFrequency(curMTDMonths, 3).reduce((a, b) => [...a, ...b], [])
        const pyMTDMonthHeader = splitArrayByFrequency(pyMTDMonths, 3).reduce((a, b) => [...a, ...b], [])
        const curMTDMonthValue = groupByFrequency(curMTDMonthHeader, 1).map(i => {
            let data = getValueAsRawObject(i, currentYear, filter.year, [297]); console.log(data); return ({
                rp: i[0], hazard: getHazardousValue(data)
            })
        })
        const prevMTDMonthValue = groupByFrequency(pyMTDMonthHeader, 1).map(i => {
            let data = getValueAsRawObject(i, previousYear, filter.year, [297]); console.log(data); return ({
                rp: i[0],hazard: getHazardousValue(data)
            })
        })
        const curMTDMonthObjectValue = groupByFrequency(curMTDMonthHeader, 1).map(i => { let data = getValueAsRawObject(i, currentYear, filter.year, [297]); console.log(data); return ({ rp: i[0], hazard: data }) })

        let requireRp = curMTDMonths
        let oldRp = pyMTDMonths
        let graphData1 = [], count = 0, curs1 = [], curs2 = [], prevs1 = [], prevs2 = [], graphData2 = [
            {
                label: 'Emissions from fuel consumption -' + filter.year,
                data: [],
                backgroundColor: stackBar[0],
                stack: 'Stack 0',
            },
            {
                label: 'Emissions from refrigerant -' + filter.year,
                data: [],
                backgroundColor: stackBar[1],
                stack: 'Stack 0',
            },
            {
                label: 'Emissions from fuel consumption -' + (filter.year - 1),
                data: [],
                backgroundColor: stackBar[0],
                stack: 'Stack 1',
            },
            {
                label: 'Emissions from refrigerant -' + (filter.year - 1),
                data: [],
                backgroundColor: stackBar[1],
                stack: 'Stack 1',
            },
        ], hazardCategory = []
        const DPAH0013Options = [
            { name: 'Used Engine Oil', id: 1 },
            { name: 'Used Lubricating Oil', id: 2 },
            { name: 'Used Oil Filters', id: 3 },
            { name: 'Used Coolant', id: 4 },
            { name: 'Contaminated PPE', id: 5 },
            { name: 'Others', id: 6 }
        ]
         
        for (const rps of requireRp) {
            console.log(curMTDMonthObjectValue[count])
            let hazardType = { month: rps }
            if (curMTDMonthObjectValue[count].hazard.length) {

                curMTDMonthObjectValue[count].hazard.forEach((ele) => {
                    console.log(ele)
                    let tonnes = 0
                    let amount = ele.DPAH0015
                            let density = ele.DPAH0016
                            if(ele.DPAH0014 === 1){
                                tonnes+= (amount/1000)
                            }else if(ele.DPAH0014 === 2){
                                tonnes+= (amount* density *  0.000001)
                            }else if(ele.DPAH0014 === 1){
                                tonnes+= (amount* density) / 1000
                
                            }
                        if (hazardType[DPAH0013Options.find(x => x.id === ele.DPAH0013).name]) {
                            
                            hazardType[DPAH0013Options.find(x => x.id === ele.DPAH0013).name] += tonnes
                        } else {
                            hazardType[DPAH0013Options.find(x => x.id === ele.DPAH0013).name] = tonnes
                        }
                    



                })
            }
            hazardCategory.push(hazardType)

            graphData1.push({ month: oldRp[count], [`${filter.year - 1}`]: prevMTDMonthValue[count].hazard.toFixed(3) })
            graphData1.push({ month: rps, [`${filter.year}`]: curMTDMonthValue[count].hazard.toFixed(3) })

            graphData1.push({ month: '', '': 0 })

            count++
        }
        let whazardfields = []

        hazardCategory.forEach((i) => {
            Object.keys(i).forEach((key) => {
                if (key !== 'month') {
                    if (!whazardfields.map(i => i.key).includes(key)) {
                        whazardfields.push({ key })
                    }
                }
            })
        })
        console.log(hazardCategory,whazardfields,hazardCategory)
        setGraphData({ fields: [{ key: `${filter.year - 1}`, color: '#008080' }, { key: `${filter.year}`, color: '#FF8042' }], data: graphData1 })
        setGraphData2({ fields: whazardfields.map((i, j) => ({ ...i, color: barColors1[j] })), data: hazardCategory, unit: 'tonnes' })

        // setGraphData3({ fields: fgfields.map((i, j) => ({ ...i, color: barColors2[j] })), fg_ghg: gas_ghg_Data, fg_usage: gas_usage_Data })
        setChartDialog(true)

    }
    const renderHazardousDisposalGraph = (locfilter, submissions) => {
        let currentYear = filterDataByTierAndLocation(filterSubmissionsByFiscalYear(submissions, filter.year, fymonth), rawsitelist, locfilter.country, locfilter.city, locfilter.location)
        let previousYear = filterDataByTierAndLocation(filterSubmissionsByFiscalYear(submissions, (filter.year - 1), fymonth), rawsitelist, locfilter.country, locfilter.city, locfilter.location)
        const curMTDMonths_ = formatDateStrings(generateMTDArray(filter.year, fymonth), 1)
        const curMTDMonths = splitArrayByFrequency(curMTDMonths_, 3).reduce((a, b) => [...a, ...b], [])
        const pyMTDMonths = reduceYearByOne(curMTDMonths)
        const curMTDMonthHeader = splitArrayByFrequency(curMTDMonths, 3).reduce((a, b) => [...a, ...b], [])
        const pyMTDMonthHeader = splitArrayByFrequency(pyMTDMonths, 3).reduce((a, b) => [...a, ...b], [])
        const curMTDMonthValue = groupByFrequency(curMTDMonthHeader, 1).map(i => {
            let data = getValueAsRawObject(i, currentYear, filter.year, [297]); console.log(data); return ({
                rp: i[0], hazard: getHazardousValue(data)
            })
        })
        const prevMTDMonthValue = groupByFrequency(pyMTDMonthHeader, 1).map(i => {
            let data = getValueAsRawObject(i, previousYear, filter.year, [297]); console.log(data); return ({
                rp: i[0],hazard: getHazardousValue(data)
            })
        })
        const curMTDMonthObjectValue = groupByFrequency(curMTDMonthHeader, 1).map(i => { let data = getValueAsRawObject(i, currentYear, filter.year, [297]); console.log(data); return ({ rp: i[0], hazard: data }) })

        let requireRp = curMTDMonths
        let oldRp = pyMTDMonths
        let graphData1 = [], count = 0, curs1 = [], curs2 = [], prevs1 = [], prevs2 = [], graphData2 = [
            {
                label: 'Emissions from fuel consumption -' + filter.year,
                data: [],
                backgroundColor: stackBar[0],
                stack: 'Stack 0',
            },
            {
                label: 'Emissions from refrigerant -' + filter.year,
                data: [],
                backgroundColor: stackBar[1],
                stack: 'Stack 0',
            },
            {
                label: 'Emissions from fuel consumption -' + (filter.year - 1),
                data: [],
                backgroundColor: stackBar[0],
                stack: 'Stack 1',
            },
            {
                label: 'Emissions from refrigerant -' + (filter.year - 1),
                data: [],
                backgroundColor: stackBar[1],
                stack: 'Stack 1',
            },
        ], hazardCategory = []
        const DPAH0017Options = [
            { name: 'Incineration (w/o Energy Recovery)', id: 1 },
            { name: 'Incineration (with Energy Recovery)', id: 2 },
            { name: 'Landfill', id: 3 },
            { name: 'Recycle', id: 4 },
            { name: 'Composting', id: 5 },
            { name: 'Waste Recovery', id: 6 },
            { name: 'Unknown', id: 7 },
            { name: 'Others', id: 8 }
        ]
         
        for (const rps of requireRp) {
            console.log(curMTDMonthObjectValue[count])
            let hazardType = { month: rps }
            if (curMTDMonthObjectValue[count].hazard.length) {

                curMTDMonthObjectValue[count].hazard.forEach((ele) => {
                    console.log(ele)
                    let tonnes = 0
                    let amount = ele.DPAH0015
                            let density = ele.DPAH0016
                            if(ele.DPAH0014 === 1){
                                tonnes+= (amount/1000)
                            }else if(ele.DPAH0014 === 2){
                                tonnes+= (amount* density *  0.000001)
                            }else if(ele.DPAH0014 === 1){
                                tonnes+= (amount* density) / 1000
                
                            }
                        if (hazardType[DPAH0017Options.find(x => x.id === ele.DPAH0017).name]) {
                            
                            hazardType[DPAH0017Options.find(x => x.id === ele.DPAH0017).name] += tonnes
                        } else {
                            hazardType[DPAH0017Options.find(x => x.id === ele.DPAH0017).name] = tonnes
                        }
                    



                })
            }
            hazardCategory.push(hazardType)

            graphData1.push({ month: oldRp[count], [`${filter.year - 1}`]: prevMTDMonthValue[count].hazard.toFixed(3) })
            graphData1.push({ month: rps, [`${filter.year}`]: curMTDMonthValue[count].hazard.toFixed(3) })

            graphData1.push({ month: '', '': 0 })

            count++
        }
        let whazardfields = []

        hazardCategory.forEach((i) => {
            Object.keys(i).forEach((key) => {
                if (key !== 'month') {
                    if (!whazardfields.map(i => i.key).includes(key)) {
                        whazardfields.push({ key })
                    }
                }
            })
        })
        console.log(hazardCategory,whazardfields,hazardCategory)
        setGraphData({ fields: [{ key: `${filter.year - 1}`, color: '#008080' }, { key: `${filter.year}`, color: '#FF8042' }], data: graphData1 })
        setGraphData2({ fields: whazardfields.map((i, j) => ({ ...i, color: barColors1[j] })), data: hazardCategory, unit: 'tonnes' })

        // setGraphData3({ fields: fgfields.map((i, j) => ({ ...i, color: barColors2[j] })), fg_ghg: gas_ghg_Data, fg_usage: gas_usage_Data })
        setChartDialog(true)

    }
    const renderWaterConsumedGraph = (locfilter, submissions) => {
        let currentYear = filterDataByTierAndLocation(filterSubmissionsByFiscalYear(submissions, filter.year, fymonth), rawsitelist, locfilter.country, locfilter.city, locfilter.location)
        let previousYear = filterDataByTierAndLocation(filterSubmissionsByFiscalYear(submissions, (filter.year - 1), fymonth), rawsitelist, locfilter.country, locfilter.city, locfilter.location)
        const curMTDMonths_ = formatDateStrings(generateMTDArray(filter.year, fymonth), 1)
        const curMTDMonths = splitArrayByFrequency(curMTDMonths_, 3).reduce((a, b) => [...a, ...b], [])
        const pyMTDMonths = reduceYearByOne(curMTDMonths)
        const curMTDMonthHeader = splitArrayByFrequency(curMTDMonths, 3).reduce((a, b) => [...a, ...b], [])
        const pyMTDMonthHeader = splitArrayByFrequency(pyMTDMonths, 3).reduce((a, b) => [...a, ...b], [])
        const curMTDMonthValue = groupByFrequency(curMTDMonthHeader, 1).map(i => {
            let data = getValueAsRawObject(i, currentYear, filter.year, [285]); let data2 = getValueAsRawObject(i, currentYear, filter.year, [286]); console.log(data); return ({
                rp: i[0], withdraw: data.reduce((totalSum, obj) => {
                    return totalSum + ['DPAN1159', 'DPAN1161'].reduce((sum, key) => sum + (obj[key] || 0), 0);
                }, 0), discharge: data2.reduce((totalSum, obj) => {
                    return totalSum + ['DPAN0053'].reduce((sum, key) => sum + (obj[key] || 0), 0);
                }, 0)
            })
        })
        const prevMTDMonthValue = groupByFrequency(pyMTDMonthHeader, 1).map(i => {
            let data = getValueAsRawObject(i, previousYear, filter.year, [285]); let data2 = getValueAsRawObject(i, previousYear, filter.year, [286]); console.log(data); return ({
                rp: i[0], withdraw: data.reduce((totalSum, obj) => {
                    return totalSum + ['DPAN1159', 'DPAN1161'].reduce((sum, key) => sum + (obj[key] || 0), 0);
                }, 0), discharge: data2.reduce((totalSum, obj) => {
                    return totalSum + ['DPAN0053'].reduce((sum, key) => sum + (obj[key] || 0), 0);
                }, 0)
            })
        })
        const curMTDMonthObjectValue = groupByFrequency(curMTDMonthHeader, 1).map(i => { let data = getValueAsRawObject(i, currentYear, filter.year, [285]); let data2 = getValueAsRawObject(i, currentYear, filter.year, [286]); console.log(data); return ({ rp: i[0], withdraw: data, discharge: data2 }) })

        let requireRp = curMTDMonths
        let oldRp = pyMTDMonths
        let graphData1 = [], count = 0, curs1 = [], curs2 = [], prevs1 = [], prevs2 = [], graphData2 = [
            {
                label: 'Emissions from fuel consumption -' + filter.year,
                data: [],
                backgroundColor: stackBar[0],
                stack: 'Stack 0',
            },
            {
                label: 'Emissions from refrigerant -' + filter.year,
                data: [],
                backgroundColor: stackBar[1],
                stack: 'Stack 0',
            },
            {
                label: 'Emissions from fuel consumption -' + (filter.year - 1),
                data: [],
                backgroundColor: stackBar[0],
                stack: 'Stack 1',
            },
            {
                label: 'Emissions from refrigerant -' + (filter.year - 1),
                data: [],
                backgroundColor: stackBar[1],
                stack: 'Stack 1',
            },
        ], withdrawCategory = [], dischargeCategory = []
        const DPAN1158Options = [
            { name: "Groundwater Wells Operated", id: 1 },

            { name: "Effluent/ Sewage Treatment Recycle", id: 2 },
            { name: 'Rainwater Harvesting', id: 3 },
            { name: 'Others', id: 99 }
        ];
        const DPAN1200Options = [
            { name: "Surface water", id: 1 },
            { name: "Ground Water", id: 2 },
            { name: "Sea Water", id: 3 },
            { name: 'Imported Water from Industrial District', id: 8 },
            { name: 'Unknown', id: 9 }
        ];
        const DPAN0052Options = [
            { name: "Surface Water", id: 1 },
            { name: "Ground Water", id: 2 },
            { name: "Sea Water", id: 3 },
            { name: "Third-Party Water (E.g.- Industrial Sewer)", id: 5 },
            { name: "Water Reuse", id: 6 },
            { name: "Water Recycled", id: 7 },
        ];
        for (const rps of requireRp) {
            console.log(curMTDMonthObjectValue[count])
            let withdrawType = { month: rps }, dischargeType = { month: rps }
            if (curMTDMonthObjectValue[count].withdraw.length) {

                curMTDMonthObjectValue[count].withdraw.forEach((ele) => {
                    console.log(ele)
                    if (ele.DPAN0048 === 6) {
                        if (withdrawType[DPAN1158Options.find(x => x.id === ele.DPAN1158).name]) {
                            withdrawType[DPAN1158Options.find(x => x.id === ele.DPAN1158).name] += ele.DPAN1159
                        } else {
                            withdrawType[DPAN1158Options.find(x => x.id === ele.DPAN1158).name] = ele.DPAN1159
                        }
                    } else if (ele.DPAN0048 === 5) {
                        if (withdrawType[DPAN1200Options.find(x => x.id === ele.DPAN1200).name]) {
                            withdrawType[DPAN1200Options.find(x => x.id === ele.DPAN1200).name] += ele.DPAN1161
                        } else {
                            withdrawType[DPAN1200Options.find(x => x.id === ele.DPAN1200).name] = ele.DPAN1161
                        }

                    }



                })
                curMTDMonthObjectValue[count].discharge.forEach((ele) => {
                    console.log(ele)

                    if (dischargeType[DPAN0052Options.find(x => x.id === ele.DPAN0052).name]) {
                        dischargeType[DPAN0052Options.find(x => x.id === ele.DPAN0052).name] += ele.DPAN0053
                    } else {
                        dischargeType[DPAN0052Options.find(x => x.id === ele.DPAN0052).name] = ele.DPAN0053
                    }




                })
            }
            withdrawCategory.push(withdrawType)
            dischargeCategory.push(dischargeType)

            graphData1.push({ month: oldRp[count], [`${filter.year - 1}`]: (prevMTDMonthValue[count].withdraw - prevMTDMonthValue[count].discharge).toFixed(3) })
            graphData1.push({ month: rps, [`${filter.year}`]: (curMTDMonthValue[count].withdraw - curMTDMonthValue[count].discharge).toFixed(3) })

            graphData1.push({ month: '', '': 0 })

            count++
        }
        let waterwithdrawlfields = [], waterdischargefields = []

        withdrawCategory.forEach((i) => {
            Object.keys(i).forEach((key) => {
                if (key !== 'month') {
                    if (!waterwithdrawlfields.map(i => i.key).includes(key)) {
                        waterwithdrawlfields.push({ key })
                    }
                }
            })
        })


        dischargeCategory.forEach((i) => {
            Object.keys(i).forEach((key) => {
                if (key !== 'month') {
                    if (!waterdischargefields.map(i => i.key).includes(key)) {
                        waterdischargefields.push({ key })
                    }
                }
            })
        })
        console.log(withdrawCategory, graphData1)
        setGraphData({ fields: [{ key: `${filter.year - 1}`, color: '#008080' }, { key: `${filter.year}`, color: '#FF8042' }], data: graphData1 })
        setGraphData2({ fields: waterwithdrawlfields.map((i, j) => ({ ...i, color: barColors1[j] })), data: withdrawCategory, unit: 'm3' })
        setGraphData3({ fields: waterdischargefields.map((i, j) => ({ ...i, color: barColors1[j] })), data: dischargeCategory, unit: 'm3' })

        // setGraphData3({ fields: fgfields.map((i, j) => ({ ...i, color: barColors2[j] })), fg_ghg: gas_ghg_Data, fg_usage: gas_usage_Data })
        setChartDialog(true)

    }
    const renderWUEGraph =  (locfilter, submissions) => {
        let currentYear = filterDataByTierAndLocation(filterSubmissionsByFiscalYear(submissions, filter.year, fymonth), rawsitelist, locfilter.country, locfilter.city, locfilter.location)
        let previousYear = filterDataByTierAndLocation(filterSubmissionsByFiscalYear(submissions, (filter.year - 1), fymonth), rawsitelist, locfilter.country, locfilter.city, locfilter.location)
        const curMTDMonths_ = formatDateStrings(generateMTDArray(filter.year, fymonth), 1)
        const curMTDMonths = splitArrayByFrequency(curMTDMonths_, 3).reduce((a, b) => [...a, ...b], [])
        const pyMTDMonths = reduceYearByOne(curMTDMonths)
        const curMTDMonthHeader = splitArrayByFrequency(curMTDMonths, 3).reduce((a, b) => [...a, ...b], [])
        const pyMTDMonthHeader = splitArrayByFrequency(pyMTDMonths, 3).reduce((a, b) => [...a, ...b], [])
        const curMTDMonthValue = groupByFrequency(curMTDMonthHeader, 1).map(i => {
            let data = getValueAsRawObject(i, currentYear, filter.year, [285]); let data2 = getValueAsRawObject(i, currentYear, filter.year, [264]); console.log(data); return ({
                rp: i[0], withdraw: data.reduce((totalSum, obj) => {
                    return totalSum + ['DPAN1159', 'DPAN1161'].reduce((sum, key) => sum + (obj[key] || 0), 0);
                }, 0), itload: data2.reduce((totalSum, obj) => {
                    return totalSum + obj.value
                }, 0)
            })
        })
        const prevMTDMonthValue = groupByFrequency(pyMTDMonthHeader, 1).map(i => {
            let data = getValueAsRawObject(i, previousYear, filter.year, [285]); let data2 = getValueAsRawObject(i, previousYear, filter.year, [264]); console.log(data); return ({
                rp: i[0], withdraw: data.reduce((totalSum, obj) => {
                    return totalSum + ['DPAN1159', 'DPAN1161'].reduce((sum, key) => sum + (obj[key] || 0), 0);
                }, 0), itload: data2.reduce((totalSum, obj) => {
                    return totalSum + obj.value
                }, 0)
            })
        })
        const curMTDMonthObjectValue = groupByFrequency(curMTDMonthHeader, 1).map(i => { let data = getValueAsRawObject(i, currentYear, filter.year, [285]); let data2 = getValueAsRawObject(i, currentYear, filter.year, [264]); console.log(data); return ({ rp: i[0], withdraw: data, itload: data2 }) })

        let requireRp = curMTDMonths
        let oldRp = pyMTDMonths
        let graphData1 = [], count = 0, curs1 = [], curs2 = [], prevs1 = [], prevs2 = [], graphData2 = [
            {
                label: 'Emissions from fuel consumption -' + filter.year,
                data: [],
                backgroundColor: stackBar[0],
                stack: 'Stack 0',
            },
            {
                label: 'Emissions from refrigerant -' + filter.year,
                data: [],
                backgroundColor: stackBar[1],
                stack: 'Stack 0',
            },
            {
                label: 'Emissions from fuel consumption -' + (filter.year - 1),
                data: [],
                backgroundColor: stackBar[0],
                stack: 'Stack 1',
            },
            {
                label: 'Emissions from refrigerant -' + (filter.year - 1),
                data: [],
                backgroundColor: stackBar[1],
                stack: 'Stack 1',
            },
        ], withdrawCategory = [], itloadCategory = []
      
        for (const rps of requireRp) {
            console.log(curMTDMonthObjectValue[count])
            let load = curMTDMonthObjectValue[count].itload.length ? curMTDMonthObjectValue[count].itload.reduce((totalSum, obj) => {
                return totalSum + obj.value
            }, 0) : 0 ,withdraw=0;
            let withdrawType = { month: rps }, dischargeType = { month: rps }
            if (curMTDMonthObjectValue[count].withdraw.length) {

                curMTDMonthObjectValue[count].withdraw.forEach((ele) => {
                    console.log(ele)
                    if (ele.DPAN0048 === 6) {
                      
                        withdraw += ele.DPAN1159
                        
                    } else if (ele.DPAN0048 === 5) {

                            withdraw += ele.DPAN1161

                    }



                })
              
            }
      
            withdrawCategory.push({ month: oldRp[count], [`${filter.year}`]: (withdraw).toFixed(3) })
            itloadCategory.push({ month: oldRp[count], [`${filter.year}`]: (load).toFixed(3) })
let curValue = curMTDMonthValue[count].itload ?  (curMTDMonthValue[count].withdraw / curMTDMonthValue[count].itload) :0
let prevValue = prevMTDMonthValue[count].itload ?  (prevMTDMonthValue[count].withdraw / prevMTDMonthValue[count].itload) :0

            graphData1.push({ month: oldRp[count], [`${filter.year - 1}`]: (prevValue).toFixed(3) })
            graphData1.push({ month: rps, [`${filter.year}`]: (curValue).toFixed(3) })

            graphData1.push({ month: '', '': 0 })

            count++
        }
   console.log(graphData1)

        setGraphData({ fields: [{ key: `${filter.year - 1}`, color: '#008080' }, { key: `${filter.year}`, color: '#FF8042' }], data: graphData1 })
        setGraphData2({ fields: [{ key: `${filter.year}`, color: '#FF8042' }], data: withdrawCategory, unit: 'm3' })
        setGraphData3({ fields: [{ key: `${filter.year}`, color: '#FF8042' }], data: itloadCategory, unit: 'MWh' })

        // setGraphData3({ fields: fgfields.map((i, j) => ({ ...i, color: barColors2[j] })), fg_ghg: gas_ghg_Data, fg_usage: gas_usage_Data })
        setChartDialog(true)

    }
    const renderWaterDischargeGraph = (locfilter, submissions) => {
        let currentYear = filterDataByTierAndLocation(filterSubmissionsByFiscalYear(submissions, filter.year, fymonth), rawsitelist, locfilter.country, locfilter.city, locfilter.location)
        let previousYear = filterDataByTierAndLocation(filterSubmissionsByFiscalYear(submissions, (filter.year - 1), fymonth), rawsitelist, locfilter.country, locfilter.city, locfilter.location)
        const curMTDMonths_ = formatDateStrings(generateMTDArray(filter.year, fymonth), 1)
        const curMTDMonths = splitArrayByFrequency(curMTDMonths_, 3).reduce((a, b) => [...a, ...b], [])
        const pyMTDMonths = reduceYearByOne(curMTDMonths)
        const curMTDMonthHeader = splitArrayByFrequency(curMTDMonths, 3).reduce((a, b) => [...a, ...b], [])
        const pyMTDMonthHeader = splitArrayByFrequency(pyMTDMonths, 3).reduce((a, b) => [...a, ...b], [])
        const curMTDMonthValue = groupByFrequency(curMTDMonthHeader, 1).map(i => {
            let data = getValueAsRawObject(i, currentYear, filter.year, [286]); console.log(data); return ({
                rp: i[0], discharge: data.reduce((totalSum, obj) => {
                    return totalSum + ['DPAN0053'].reduce((sum, key) => sum + (obj[key] || 0), 0);
                }, 0)
            })
        })
        const prevMTDMonthValue = groupByFrequency(pyMTDMonthHeader, 1).map(i => {
            let data = getValueAsRawObject(i, previousYear, filter.year, [286]); console.log(data); return ({
                rp: i[0], discharge: data.reduce((totalSum, obj) => {
                    return totalSum + ['DPAN0053'].reduce((sum, key) => sum + (obj[key] || 0), 0);
                }, 0)
            })
        })
        const curMTDMonthObjectValue = groupByFrequency(curMTDMonthHeader, 1).map(i => { let data = getValueAsRawObject(i, currentYear, filter.year, [286]); console.log(data); return ({ rp: i[0], discharge: data }) })

        let requireRp = curMTDMonths
        let oldRp = pyMTDMonths
        let graphData1 = [], count = 0, curs1 = [], curs2 = [], prevs1 = [], prevs2 = [], graphData2 = [
            {
                label: 'Emissions from fuel consumption -' + filter.year,
                data: [],
                backgroundColor: stackBar[0],
                stack: 'Stack 0',
            },
            {
                label: 'Emissions from refrigerant -' + filter.year,
                data: [],
                backgroundColor: stackBar[1],
                stack: 'Stack 0',
            },
            {
                label: 'Emissions from fuel consumption -' + (filter.year - 1),
                data: [],
                backgroundColor: stackBar[0],
                stack: 'Stack 1',
            },
            {
                label: 'Emissions from refrigerant -' + (filter.year - 1),
                data: [],
                backgroundColor: stackBar[1],
                stack: 'Stack 1',
            },
        ], dischargeCategory = []
        const DPAN0052Options = [
            { name: "Surface Water", id: 1 },
            { name: "Ground Water", id: 2 },
            { name: "Sea Water", id: 3 },
            { name: "Third-Party Water (E.g.- Industrial Sewer)", id: 5 },
            { name: "Water Reuse", id: 6 },
            { name: "Water Recycled", id: 7 },
        ];
        for (const rps of requireRp) {
            console.log(curMTDMonthObjectValue[count])
            let dischargeType = { month: rps }
            if (curMTDMonthObjectValue[count].discharge.length) {

                curMTDMonthObjectValue[count].discharge.forEach((ele) => {
                    console.log(ele)

                    if (dischargeType[DPAN0052Options.find(x => x.id === ele.DPAN0052).name]) {
                        dischargeType[DPAN0052Options.find(x => x.id === ele.DPAN0052).name] += ele.DPAN0053
                    } else {
                        dischargeType[DPAN0052Options.find(x => x.id === ele.DPAN0052).name] = ele.DPAN0053
                    }




                })
            }
            dischargeCategory.push(dischargeType)

            graphData1.push({ month: oldRp[count], [`${filter.year - 1}`]: prevMTDMonthValue[count].discharge.toFixed(3) })
            graphData1.push({ month: rps, [`${filter.year}`]: curMTDMonthValue[count].discharge.toFixed(3) })

            graphData1.push({ month: '', '': 0 })

            count++
        }
        let waterdischargefields = []

        dischargeCategory.forEach((i) => {
            Object.keys(i).forEach((key) => {
                if (key !== 'month') {
                    if (!waterdischargefields.map(i => i.key).includes(key)) {
                        waterdischargefields.push({ key })
                    }
                }
            })
        })
        console.log(dischargeCategory, graphData1)
        setGraphData({ fields: [{ key: `${filter.year - 1}`, color: '#008080' }, { key: `${filter.year}`, color: '#FF8042' }], data: graphData1 })
        setGraphData2({ fields: waterdischargefields.map((i, j) => ({ ...i, color: barColors1[j] })), data: dischargeCategory, unit: 'm3' })

        // setGraphData3({ fields: fgfields.map((i, j) => ({ ...i, color: barColors2[j] })), fg_ghg: gas_ghg_Data, fg_usage: gas_usage_Data })
        setChartDialog(true)

    }
    const renderScope2Graph1 = (locfilter, submissions) => {
        let currentYear = filterDataByTierAndLocation(filterSubmissionsByFiscalYear(submissions, filter.year, fymonth), rawsitelist, locfilter.country, locfilter.city, locfilter.location)
        let previousYear = filterDataByTierAndLocation(filterSubmissionsByFiscalYear(submissions, (filter.year - 1), fymonth), rawsitelist, locfilter.country, locfilter.city, locfilter.location)
        const curMTDMonths_ = formatDateStrings(generateMTDArray(filter.year, fymonth), 1)
        const curMTDMonths = splitArrayByFrequency(curMTDMonths_, 3).reduce((a, b) => [...a, ...b], [])
        const pyMTDMonths = reduceYearByOne(curMTDMonths)
        const curMTDMonthHeader = splitArrayByFrequency(curMTDMonths, 3).reduce((a, b) => [...a, ...b], [])
        const pyMTDMonthHeader = splitArrayByFrequency(pyMTDMonths, 3).reduce((a, b) => [...a, ...b], [])
        const curMTDMonthValue = groupByFrequency(curMTDMonthHeader, 1).map(i => { let data = getValueAsObject(i, currentYear, filter.year, [287]); console.log(data); return ({ rp: i[0], scope2: data.filter(x => x.dcfId === 287).reduce((a, b) => { return a + b.ghg }, 0) }) })
        const prevMTDMonthValue = groupByFrequency(pyMTDMonthHeader, 1).map(i => { let data = getValueAsObject(i, previousYear, (filter.year - 1), [287]); console.log(data); return ({ rp: i[0], scope2: data.filter(x => x.dcfId === 287).reduce((a, b) => { return a + b.ghg }, 0) }) })
        const curMTDMonthObjectValue = groupByFrequency(curMTDMonthHeader, 1).map(i => { let data = getValueAsUnShapedObject(i, currentYear, filter.year, [287]); console.log(data); return ({ rp: i[0], scope2: data.filter(x => x.dcfId === 287) }) })

        let requireRp = curMTDMonths
        let oldRp = pyMTDMonths
        let graphData1 = [], count = 0, curs1 = [], curs2 = [], prevs1 = [], prevs2 = [], graphData2 = [
            {
                label: 'Emissions from fuel consumption -' + filter.year,
                data: [],
                backgroundColor: stackBar[0],
                stack: 'Stack 0',
            },
            {
                label: 'Emissions from refrigerant -' + filter.year,
                data: [],
                backgroundColor: stackBar[1],
                stack: 'Stack 0',
            },
            {
                label: 'Emissions from fuel consumption -' + (filter.year - 1),
                data: [],
                backgroundColor: stackBar[0],
                stack: 'Stack 1',
            },
            {
                label: 'Emissions from refrigerant -' + (filter.year - 1),
                data: [],
                backgroundColor: stackBar[1],
                stack: 'Stack 1',
            },
        ], renewable = [], non_renewable = []
        for (const rps of requireRp) {
            console.log(curMTDMonthObjectValue[count])
            let renewable_mwh = { month: rps }, non_renewable_mwh = { month: rps }
            if (curMTDMonthObjectValue[count].scope2.length && curMTDMonthObjectValue[count].scope2.some(x => x.ghg && x.ghg.length)) {

                curMTDMonthObjectValue[count].scope2.forEach((ele) => {
                    ele.ghg.forEach((item) => {
                        renewable_mwh['Renewable Energy (PPA + Green Tariff)'] = parseFloat(item.r.toFixed(2))
                        renewable_mwh['Grid Electricity'] = parseFloat(item.nr.toFixed(2))
                    })



                })
            }
            renewable.push(renewable_mwh)

            graphData1.push({ month: oldRp[count], [`${filter.year - 1}`]: prevMTDMonthValue[count].scope2.toFixed(3) })
            graphData1.push({ month: rps, [`${filter.year}`]: curMTDMonthValue[count].scope2.toFixed(3) })

            graphData1.push({ month: '', '': 0 })

            count++
        }



        setGraphData({ fields: [{ key: `${filter.year - 1}`, color: '#008080' }, { key: `${filter.year}`, color: '#FF8042' }], data: graphData1 })
        setGraphData2({ fields: [{ key: 'Renewable Energy (PPA + Green Tariff)', color: barColors1[0] }, { key: 'Grid Electricity', color: barColors1[1] }], data: renewable, unit: 'MWh' })
        // setGraphData3({ fields: fgfields.map((i, j) => ({ ...i, color: barColors2[j] })), fg_ghg: gas_ghg_Data, fg_usage: gas_usage_Data })
        setChartDialog(true)

    }
    const customtHeader = <div style={{
        fontWeight: '600',
        fontSize: '24px'
    }}>Approver Comments for Reporter/Reviewer if any </div>;

    const customPlaceholder = <div style={{
        fontWeight: '500',
        fontSize: '16px'
    }}>Enter your comment</div>;



    useEffect(() => {
        let selectedYear = null
        let yrOptions = getFiscalYearsFromStartDate(admin_data.information.startdate)
        if (yrOptions.length !== 0) {
            selectedYear = yrOptions[yrOptions.length - 1].name
            setFilter((prev) => ({ ...prev, year: selectedYear }))
        }
        console.log(yrOptions)
        setYearOptions(yrOptions)
        let uriString = {
            include: [
                {
                    relation: "locationTwos",
                    scope: { include: [{ relation: "locationThrees" }] },
                },
            ],
        };
        let ef_complete = API.EF_Std + `?filter=%7B%20%22include%22%3A%20%5B%7B%20%22relation%22%3A%20%22newEfDates%22,%20%22scope%22%3A%20%7B%20%22include%22%3A%20%5B%7B%20%22relation%22%3A%20%22newEfs%22,%20%22scope%22%3A%20%7B%20%22include%22%3A%20%5B%7B%20%22relation%22%3A%20%22newEfItems%22%20%7D%5D%20%7D%20%7D%5D%20%7D%20%7D%5D%7D`

        const promise1 = APIServices.get(API.QN_Submit_UP(login_data.clientId))

        const promise2 = APIServices.get(
            API.LocationOne_UP(login_data.information.cid) +
            `?filter=${encodeURIComponent(JSON.stringify(uriString))}`)
        const promise3 = APIServices.get(ef_complete)
        const promise4 = APIServices.get(API.EF_SC1)
        const promise5 = APIServices.get(API.EF_SC2)
        const promise6 = APIServices.get(API.EF_SC3)
        const promise7 = APIServices.get(API.EF_SC4)
        const promise8 = APIServices.get(API.DCF_Title_Only)
        const promise9 = APIServices.get(
            API.GetRoleByUserId(admin_data.id, login_data.id)
        );
        const promise10 = APIServices.get(API.DCF_Entity_UP(admin_data.id))
        const promise11 = APIServices.get(API.DCF_Entity_User_UP(admin_data.id))
        const promise12 = APIServices.get(API.QNIndicatorApprove_UP(admin_data.id))
        Promise.all([promise1, promise2, promise3, promise4, promise5, promise6, promise7, promise8, promise9, promise10, promise11, promise12]).then((values) => {
            let qnsubmissions = values[0].data

            setApiEF(values[2].data)
            setDcfList(values[7].data)
            setApprovedIndicator(values[11].data)
            const shapedSite = values[1].data
                .map((item) => {
                    if (item.locationTwos) {
                        item.locationTwos = item.locationTwos.filter(
                            (locationTwo) =>
                                locationTwo.locationThrees &&
                                locationTwo.locationThrees.length > 0
                        );
                    }
                    return item;
                })
                .filter((item) => item.locationTwos && item.locationTwos.length > 0);
            setRawSiteList(shapedSite)
            setOverallQuantitativeSubmission(qnsubmissions)
            setQuantitativeSubmission(qnsubmissions.filter(i => (i.type === 2 || i.type === 3)).filter(i => checkEntity(i, values[9].data, 'dcfId') && (login_data.role === 'clientadmin' || checkRoleAccessByRoleIds([login_data.id], [2], i.level, i[`tier${i.level}_id`], [{ tier1_id: 0, tier2_id: null, tier3_id: null, roles: [2, 6], user_id: login_data.id }], shapedSite).includes(login_data.id))))

            let filtered_qn_ass = values[10].data.filter(
                (i) => {
                    return (values[7].data.map((j) => j.id).includes(i.dcfId) && checkEntity(i, values[9].data, 'dcfId') &&
                        (login_data.role === 'clientadmin' || checkRoleAccessByRoleIds([login_data.id], [2], i.level, i[`tier${i.level}_id`], [{ tier1_id: 0, tier2_id: null, tier3_id: null, roles: [2, 6], user_id: login_data.id }], shapedSite).includes(login_data.id)))
                }
            );
            setAssignment(filtered_qn_ass)
            setLocList((prev) => ({ ...prev, country: [...shapedSite.map(i => ({ name: i.name, id: i.id }))] }))
            setFilter((prev) => ({ ...prev, country: shapedSite[0].id, city: 0 }))
            let loc_subcat = subcat
            loc_subcat.one = values[3].data
            loc_subcat.two = values[4].data
            loc_subcat.three = values[5].data
            loc_subcat.four = values[6].data
            setSubCat((prev) => ({ ...prev, ...loc_subcat }))
            if (selectedYear) {
                setFilter((prev) => ({ ...prev, year: selectedYear }))
            }


        })
    }, [])
    useEffect(() => {
        console.log(subcat)
        if (subcat.one.length) {

            renderIndicatorValues({ ...filter, country: rawsitelist[0].id, city: 0 }, JSON.parse(JSON.stringify(quantitativesubmission)), JSON.parse(JSON.stringify(overallQuantitativeSubmission)))
        }
    }, [subcat])
    const checkEntity = (rowData, entity_list, obj) => {
        let index = entity_list.findIndex((k) => k[obj] === rowData[obj]);
        if (index !== -1) {
            let entity = entity_list[index];
            console.log(entity);
            if (rowData.level === 0) {
                return entity.tier0_ids.includes(0);
            } else if (rowData.level === 1) {
                return (
                    entity.tier1_ids.includes(rowData.locationId)
                );
            } else if (rowData.level === 2) {
                return (
                    entity.tier2_ids.includes(rowData.locationId)
                );
            } else if (rowData.level === 3) {
                return (
                    entity.tier3_ids.includes(rowData.locationId)
                );
            }
        } else {
            return false;
        }
    };
    const getCoverageText = (rowData) => {
        let text = "";

        console.log(rowData);
        if (rowData.level === 0) {
            text = "Corporate";
        } else if (rowData.level === 1) {
            let country_index = rawsitelist.findIndex(
                (i) => i.id === rowData.locationId
            );
            if (country_index !== -1) {
                text = rawsitelist[country_index].name;
            }
        } else if (rowData.level === 2) {
            let city_index = rawsitelist
                .flatMap((i) =>
                    i.locationTwos.flatMap((j) =>
                        j.locationThrees.map((k) => {
                            return {
                                site_id: k.id,
                                site_name: k.name,
                                city_id: j.id,
                                city_name: j.name,
                                country_id: i.id,
                                country_name: i.name,
                            };
                        })
                    )
                )
                .findIndex((i) => {
                    return i.city_id === rowData.locationId;
                });
            if (city_index !== -1) {
                text = rawsitelist.flatMap((i) =>
                    i.locationTwos.flatMap((j) =>
                        j.locationThrees.map((k) => {
                            return {
                                site_id: k.id,
                                site_name: k.name,
                                city_id: j.id,
                                city_name: j.name,
                                country_id: i.id,
                                country_name: i.name,
                            };
                        })
                    )
                )[city_index].city_name;
            }
        } else if (rowData.level === 3) {
            let site_index = rawsitelist
                .flatMap((i) =>
                    i.locationTwos.flatMap((j) =>
                        j.locationThrees.map((k) => {
                            return {
                                site_id: k.id,
                                site_name: k.name,
                                city_id: j.id,
                                city_name: j.name,
                                country_id: i.id,
                                country_name: i.name,
                            };
                        })
                    )
                )
                .findIndex((i) => {
                    return i.site_id === rowData.locationId;
                });
            if (site_index !== -1) {
                text = rawsitelist.flatMap((i) =>
                    i.locationTwos.flatMap((j) =>
                        j.locationThrees.map((k) => {
                            return {
                                site_id: k.id,
                                site_name: k.name,
                                city_id: j.id,
                                city_name: j.name,
                                country_id: i.id,
                                country_name: i.name,
                            };
                        })
                    )
                )[site_index].site_name;
            }
        }
        return text;
    };



    const getBetweenMonthsByYear = (year, fiscalStartMonth = 1) => {
        const months = [];
        for (let i = 0; i < 12; i++) {
            const month = (fiscalStartMonth + i - 1) % 12 + 1;
            const fiscalYear = month >= fiscalStartMonth ? year : year + 1;
            const date = DateTime.fromObject({ year: fiscalYear, month: month });
            const formattedMonth = date.toFormat('MM-yyyy');
            months.push(formattedMonth);
        }
        return months;
    };
    const filterAssignmentsByFiscalYear = (assignments, year) => {
        const { startDate, endDate } = getFiscalYearRange(year, fymonth);
        const currentDate = DateTime.local().startOf('day');

        return assignments.filter(assignment => {
            console.log(assignment)
            const assignmentStartDate = assignment.start_date ? DateTime.fromISO(assignment.start_date, { zone: 'utc' }).startOf('day') : currentDate;
            const assignmentEndDate = assignment.end_date ? DateTime.fromISO(assignment.end_date, { zone: 'utc' }).startOf('day') : currentDate;

            return (assignmentStartDate >= startDate && assignmentStartDate <= endDate) ||
                (assignmentEndDate >= startDate && assignmentEndDate <= endDate) ||
                (assignmentStartDate <= startDate && assignmentEndDate >= endDate);
        });
    };
    const filterAssignmentsByRP = (assignments, rpArray) => {
        return assignments.filter(assignment => {
            const assignmentStartDate = assignment.start_date
                ? DateTime.fromISO(assignment.start_date, { zone: 'utc' }).plus({ months: 1 }).startOf('month')
                : null;
            const assignmentEndDate = assignment.end_date
                ? DateTime.fromISO(assignment.end_date, { zone: 'utc' }).startOf('month')
                : DateTime.local().startOf('month');

            if (!assignmentStartDate || !assignmentEndDate) {
                return false;
            }

            // Convert all rpArray elements to DateTime objects
            const rpDates = rpArray.map(rp => DateTime.fromFormat(rp, 'MM-yyyy', { zone: 'utc' }));

            // Check if all rpDates lie within the assignment start and end dates
            return rpDates.every(rpDate =>
                rpDate >= assignmentStartDate && rpDate <= assignmentEndDate
            );
        });
    };

    const getFiscalYearRange = (year, fymonth) => {
        let startDate, endDate;
        console.log(fymonth)

        if (fymonth === 1) {
            startDate = DateTime.fromObject({ year, month: 1, day: 1 }).startOf('day');
            endDate = DateTime.fromObject({ year, month: 12, day: 31 }).endOf('day');
        } else {
            startDate = DateTime.fromObject({ year: year - 1, month: fymonth, day: 1 }).startOf('day');
            endDate = DateTime.fromObject({ year, month: fymonth - 1, day: 1 }).endOf('month');
        }

        return { startDate, endDate };
    };
    function generateMonthArray(year, fymonth) {
        const months = ["01", "02", "03", "04", "05", "06", "07", "08", "09", "10", "11", "12"];
        let result = [];

        // Fiscal year starting from fymonth
        if (fymonth === 1) {
            // Calendar year starting from January
            for (let i = 0; i < 12; i++) {
                result.push(`${months[i]}-${year}`);
            }
        } else {
            // Fiscal year starting from the given month (not January)
            // Generate 12 months, adjusting the year if necessary
            for (let i = 0; i < 12; i++) {
                let monthIndex = (fymonth - 1 + i) % 12;
                let currentYear = year - 1;

                // Determine if we need to roll over to the next year
                if (monthIndex < fymonth - 1) {
                    currentYear++;
                }

                result.push(`${months[monthIndex]}-${currentYear}`);
            }
        }

        return result;
    }
    function formatDateStrings(dateArray, frequency = 1) {
        const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
        const result = [];

        for (let i = 0; i < dateArray.length; i += frequency) {
            const currentBatch = dateArray.slice(i, i + frequency);

            if (currentBatch.length === frequency) {
                if (frequency === 1) {
                    const [month, year] = currentBatch[0].split('-');
                    result.push(`${monthNames[parseInt(month) - 1]}-${year}`);
                } else {
                    const firstDate = currentBatch[0];
                    const lastDate = currentBatch[currentBatch.length - 1];
                    const [startMonth, startYear] = firstDate.split('-');
                    const [endMonth, endYear] = lastDate.split('-');
                    result.push(`${monthNames[parseInt(startMonth) - 1]}-${startYear} to ${monthNames[parseInt(endMonth) - 1]}-${endYear}`);
                }
            }
        }



        return result;
    }

    const generateMTDArray = (year, fymonth) => {
        const months = ["01", "02", "03", "04", "05", "06", "07", "08", "09", "10", "11", "12"];
        let result = [];

        // Determine the current month and year using Luxon
        const currentDate = DateTime.local();
        const currentMonth = currentDate.month;
        const currentYear = currentDate.year;

        if (year === currentYear) {
            // Current year case
            let startMonth = fymonth;
            let endMonth = currentMonth - 1;

            if (startMonth <= endMonth) {
                // Fiscal year does not cross year boundary
                for (let i = startMonth - 1; i < endMonth; i++) {
                    result.push(`${months[i]}-${year}`);
                }
            } else {
                // Fiscal year crosses year boundary
                for (let i = startMonth - 1; i < 12; i++) {
                    result.push(`${months[i]}-${year - 1}`);
                }
                for (let i = 0; i < endMonth; i++) {
                    result.push(`${months[i]}-${year}`);
                }
            }
        } else {
            // Not the current year case
            let startMonth = fymonth;
            for (let i = 0; i < 12; i++) {
                let monthIndex = (startMonth - 1 + i) % 12;
                let yearAdjustment = Math.floor((startMonth - 1 + i) / 12);
                let adjustedYear = year + yearAdjustment;
                result.push(`${months[monthIndex]}-${adjustedYear}`);
            }
        }

        return result;
    };





    const reduceYearByOne = (dateStrings) => {
        return dateStrings.map(dateString => {
            if (dateString.includes("to")) {
                // Handle range format "Jan-2022 to Feb-2022"
                const [start, end] = dateString.split(" to ");
                const [startMonth, startYear] = start.split('-');
                const [endMonth, endYear] = end.split('-');

                const newStartYear = (parseInt(startYear) - 1).toString();
                const newEndYear = (parseInt(endYear) - 1).toString();

                return `${startMonth}-${newStartYear} to ${endMonth}-${newEndYear}`;
            } else {
                // Handle single date format "Jan-2022"
                const [month, year] = dateString.split('-');
                const newYear = (parseInt(year) - 1).toString();

                return `${month}-${newYear}`;
            }
        });
    };



    const getDisplayStatus_rparray = (rp) => {


        console.log(rp)
        const [startMonth, endMonth] = rp[0].split(' to ');

        const month = endMonth ? endMonth : startMonth;
        const [monthValue, year] = month.split('-');
        const endOfMonth = DateTime.fromObject({ year: parseInt(year), month: DateTime.fromFormat(monthValue, 'LLL').month }).endOf('month');
        const currentDate = DateTime.local();
        console.log(month, endOfMonth.diff(currentDate, 'days').days)
        return endOfMonth.diff(currentDate, 'days').days <= 0;
    };
    const { DateTime } = require('luxon');

    const countMonths = (item) => {
        const rangeMatch = item.match(/^(\w{3}-\d{4})\s*to\s*(\w{3}-\d{4})$/);

        if (rangeMatch) {
            const [start, end] = rangeMatch.slice(1);
            const startDate = DateTime.fromFormat(start, 'MMM-yyyy');
            const endDate = DateTime.fromFormat(end, 'MMM-yyyy');

            // Calculate the number of months between start and end dates
            return Math.ceil(endDate.diff(startDate, 'months').months) + 1;
        } else {
            // If it's a single month string, return 1
            return 1;
        }
    };
    function generateDateStrings(year) {
        const months = [];
        for (let i = 1; i <= 12; i++) {
            const month = DateTime.fromObject({ year, month: i }).toFormat('MMM-yyyy');
            months.push(month);
        }
        return months;
    }




    function splitArrayByFrequency_(data, frequency = 1) {
        const result = [];
        let subArray = [];
        const currentDate = DateTime.local().startOf('month');

        for (let item of data) {
            const count = countMonths(item);

            // Check if any month string is greater than the current month
            const lastMonthInItem = item.split(' to ').pop();
            const itemDate = DateTime.fromFormat(lastMonthInItem, 'MMM-yyyy');

            if (itemDate > currentDate) {
                result.push([]); // Add empty array if date exceeds current date
                break; // Stop processing as remaining items are irrelevant
            } else if (subArray.length + count <= frequency) {
                subArray.push(item);
            }

            // Push subArray if it's complete
            if (subArray.length > 0 && subArray.length * count >= frequency) {
                result.push(subArray);
                subArray = [];
            }
        }

        // Ensure the last subArray is added if it's valid
        if (subArray.length > 0) {
            result.push(subArray);
        }

        // Fill remaining subArrays with empty arrays
        while (result.length < Math.ceil(12 / frequency)) {
            result.push([]);
        }

        return result;
    }

    function parseDateRange(dateRange) {
        const months = [];
        if (dateRange.includes(' to ')) {
            const [start, end] = dateRange.split(' to ').map(date => DateTime.fromFormat(date, 'MMM-yyyy'));
            let current = start;
            while (current <= end) {
                months.push(current.toFormat('MMM-yyyy'));
                current = current.plus({ months: 1 });
            }
        } else {
            months.push(DateTime.fromFormat(dateRange, 'MMM-yyyy').toFormat('MMM-yyyy'));
        }
        return months;
    }

    function processDateArray(dates, approverFrequency, submissionFrequency) {
        const now = DateTime.now().startOf('month'); // Start of the current month
        const parsedDates = dates.flatMap(parseDateRange);

        // Split the parsed dates into chunks of size `approverFrequency`
        const splitDates = [];
        for (let i = 0; i < parsedDates.length; i += approverFrequency) {
            splitDates.push(parsedDates.slice(i, i + approverFrequency));
        }

        // Filter out sub-arrays where any date is equal to or greater than the current month
        const filteredDates = splitDates.map(subArray => {
            if (subArray.some(date => DateTime.fromFormat(date, 'MMM-yyyy') >= now)) {
                return [];
            }
            return subArray;
        });

        // Format the result based on `submissionFrequency`
        if (submissionFrequency === 1) {
            return filteredDates;
        } else {
            return filteredDates.map(subArray => {
                if (subArray.length > 0) {
                    return [`${subArray[0]} to ${subArray[subArray.length - 1]}`];
                }
                return [];
            });
        }
    }
    function splitArrayByFrequency(data, frequency) {
        console.log(data)
        const result = [];
        for (let i = 0; i < 12; i += frequency) {
            const subArray = data.slice(i, i + frequency);
            if (subArray.length === frequency) {
                result.push(subArray);
            } else {
                result.push([]); // Add empty array if the subArray length is not equal to frequency
            }
        }
        console.log(data, frequency)
        return result;
    }
    const renderIndicatorValues = (filter, submissions, overallQuantitativeSubmission) => {
        console.log(filter)
        let filteredSubmissions = filterDataByTierAndLocation(filterSubmissionsByFiscalYear(submissions, filter.year, fymonth), rawsitelist, filter.country, filter.city, filter.location)
        let filteredSubmissionsPy = filterDataByTierAndLocation(filterSubmissionsByFiscalYear(submissions, filter.year - 1, fymonth), rawsitelist, filter.country, filter.city, filter.location)
        const fyMonthHeader = formatDateStrings(generateMonthArray(filter.year, fymonth))
        const curMTDMonths = formatDateStrings(generateMTDArray(filter.year, fymonth))
        const pyMTDMonths = reduceYearByOne(curMTDMonths)
        const curMTDMonthHeader = splitArrayByFrequency(curMTDMonths, 3).reduce((a, b) => [...a, ...b], [])
        const pyMTDMonthHeader = splitArrayByFrequency(pyMTDMonths, 3).reduce((a, b) => [...a, ...b], [])

        const curMTDMonthValue = groupByFrequency(curMTDMonthHeader, 1).map(i => (getValue(i, filteredSubmissions, filter.year, [304, 305, 287]))).reduce((a, b) => { return a + b }, 0)
        const prevMTDMonthValue = groupByFrequency(pyMTDMonthHeader, 1).map(i => (getValue(i, filteredSubmissionsPy, filter.year - 1, [304, 305, 287]))).reduce((a, b) => { return a + b }, 0)
        const curMTDMonthValue2 = groupByFrequency(curMTDMonthHeader, 1).map(i => (getValue(i, filteredSubmissions, filter.year, [304, 305]))).reduce((a, b) => { return a + b }, 0)
        const prevMTDMonthValue2 = groupByFrequency(pyMTDMonthHeader, 1).map(i => (getValue(i, filteredSubmissionsPy, filter.year - 1, [304, 305]))).reduce((a, b) => { return a + b }, 0)
        console.log(pyMTDMonthHeader)
        const deviation1 = getPercentage(curMTDMonthValue, prevMTDMonthValue)
        const deviation2 = getPercentage(curMTDMonthValue2, prevMTDMonthValue2)

        // 6month
        const fyMonthHeader3 = formatDateStrings(generateMonthArray(filter.year, fymonth), 6)
        const curMTDMonths3 = formatDateStrings(generateMTDArray(filter.year, fymonth), 6)
        const pyMTDMonths3 = reduceYearByOne(curMTDMonths3)
        const curMTDMonthHeader3 = splitArrayByFrequency(curMTDMonths3, 1).reduce((a, b) => [...a, ...b], [])
        const pyMTDMonthHeader3 = splitArrayByFrequency(pyMTDMonths3, 1).reduce((a, b) => [...a, ...b], [])
        const dps = [{ unit: 'Women in Senior Management (num)', value: props => getSumValueByDp(props), uom: '' }, { unit: 'Women in Senior Management (%)', value: props => getPercentageByDp(props), uom: '%' }, { unit: 'Women in Middle Management (num)', value: props => getSumValueByDp(props), uom: '' }, { unit: 'Women in Middle Management (%)', value: props => getPercentageByDp(props), uom: '%' }]
        const dps2 = [{ unit: 'Total Women Employees (num)', value: props => getSumValueByDp(props), uom: '' }, { unit: 'Total Women Employees (%)', value: props => getPercentageByDp(props), uom: '%' }]

        const curMTDMonthValue3 = groupByFrequency(curMTDMonthHeader3, 1).map(i => (getSumValueByDp({ i, filteredSubmissions, yr: filter.year, dcfIds: [275], dparray1: ['DPAN614', 'DPAN616'] }))).reduce((a, b) => { return a + b }, 0)
        const prevMTDMonthValue3 = groupByFrequency(pyMTDMonthHeader3, 1).map(i => (getSumValueByDp({ i, filteredSubmissions: filteredSubmissionsPy, yr: filter.year - 1, dcfIds: [275], dparray1: ['DPAN614', 'DPAN616'] }))).reduce((a, b) => { return a + b }, 0)
        const curMTDMonthValue4 = groupByFrequency(curMTDMonthHeader3, 1).map(i => (getSumValueByDp({ i, filteredSubmissions, yr: filter.year, dcfIds: [275], dparray1: ['DPAN601', 'DPAN603'] }))).reduce((a, b) => { return a + b }, 0)
        const prevMTDMonthValue4 = groupByFrequency(pyMTDMonthHeader3, 1).map(i => (getSumValueByDp({ i, filteredSubmissions: filteredSubmissionsPy, yr: filter.year - 1, dcfIds: [275], dparray1: ['DPAN601', 'DPAN603'] }))).reduce((a, b) => { return a + b }, 0)
        const curMTDMonthValue5 = groupByFrequency(curMTDMonthHeader, 1).map(i => (getValueBySumOfId(i, filteredSubmissions, filter.year, [285], ['DPAN1159', 'DPAN1161'], 0) )).reduce((a, b) => { return a + b }, 0)
        const prevMTDMonthValue5 = groupByFrequency(pyMTDMonthHeader, 1).map(i => (getValueBySumOfId(i, filteredSubmissionsPy, filter.year, [285], ['DPAN1159', 'DPAN1161'], 0) )).reduce((a, b) => { return a + b }, 0)
       
        const curMTDMonthValue6 = groupByFrequency(curMTDMonthHeader, 1).map(i => (getValueByComparison(i, filteredSubmissions, filter.year, [285, 286]))).reduce((a, b) => { return a + b }, 0)
        const prevMTDMonthValue6 = groupByFrequency(pyMTDMonthHeader, 1).map(i => (getValueByComparison(i, filteredSubmissionsPy, filter.year, [285, 286]))).reduce((a, b) => { return a + b }, 0)
       
        const curMTDMonthValue7 = groupByFrequency(curMTDMonthHeader, 1).map(i => (getValueBySumOfId(i, filteredSubmissions, filter.year, [286], ['DPAN0053'], 0))).reduce((a, b) => { return a + b }, 0)
        const prevMTDMonthValue7 = groupByFrequency(pyMTDMonthHeader, 1).map(i => (getValueBySumOfId(i, filteredSubmissionsPy, filter.year, [286], ['DPAN0053'], 0))).reduce((a, b) => { return a + b }, 0)
       
        const curMTDMonthValue8 = groupByFrequency(curMTDMonthHeader, 1).map(i => (getValueByDivision(i, filteredSubmissions, filter.year, [285, 264]))).reduce((a, b) => { return a + b }, 0)
        const prevMTDMonthValue8 = groupByFrequency(pyMTDMonthHeader, 1).map(i => (getValueByDivision(i, filteredSubmissionsPy, filter.year, [285, 264]))).reduce((a, b) => { return a + b }, 0)
       
        const curMTDMonthValue9 = groupByFrequency(curMTDMonthHeader, 1).map(i => (getValueByNonHazardousWaste(i, filteredSubmissions, filter.year, [307]))).reduce((a, b) => { return a + b }, 0)
        const prevMTDMonthValue9 = groupByFrequency(pyMTDMonthHeader, 1).map(i => (getValueByNonHazardousWaste(i, filteredSubmissionsPy, filter.year, [307]))).reduce((a, b) => { return a + b }, 0)
        
        const curMTDMonthValue10 = groupByFrequency(curMTDMonthHeader, 1).map(i => (getValueByHazardousWaste(i, filteredSubmissions, filter.year, [297]))).reduce((a, b) => { return a + b }, 0)
        const prevMTDMonthValue10 = groupByFrequency(pyMTDMonthHeader, 1).map(i => (getValueByHazardousWaste(i, filteredSubmissionsPy, filter.year, [297]))).reduce((a, b) => { return a + b }, 0)
        

        const deviation3 = getPercentage(curMTDMonthValue3, prevMTDMonthValue3)
        const deviation4 = getPercentage(curMTDMonthValue4, prevMTDMonthValue4)
        const deviation5 = getPercentage(curMTDMonthValue5, prevMTDMonthValue5)
        const deviation6 = getPercentage(curMTDMonthValue6, prevMTDMonthValue6)
        const deviation7 = getPercentage(curMTDMonthValue7, prevMTDMonthValue7)
        const deviation8 = getPercentage(curMTDMonthValue8, prevMTDMonthValue8)
        const deviation9 = getPercentage(curMTDMonthValue9, prevMTDMonthValue9)
        const deviation10 = getPercentage(curMTDMonthValue10, prevMTDMonthValue10)

        console.log(curMTDMonthHeader3, curMTDMonthHeader, processDateArray(fyMonthHeader3, 6, 6), curMTDMonths3, fyMonthHeader3, fyMonthHeader, curMTDMonths)
        let id1 = approvedindicator.findIndex(i => i.year === filter.year && i.indicatorId === 1 && i.tier1_id === filter.country && i.tier2_id === 0 && i.tier3_id === null)
        let id2 = approvedindicator.findIndex(i => i.year === filter.year && i.indicatorId === 2 && i.tier1_id === filter.country && i.tier2_id === 0 && i.tier3_id === null)
        let id3 = approvedindicator.findIndex(i => i.year === filter.year && i.indicatorId === 3 && i.tier1_id === filter.country && i.tier2_id === 0 && i.tier3_id === null)
        let id4 = approvedindicator.findIndex(i => i.year === filter.year && i.indicatorId === 4 && i.tier1_id === filter.country && i.tier2_id === 0 && i.tier3_id === null)
        let id5 = approvedindicator.findIndex(i => i.year === filter.year && i.indicatorId === 5 && i.tier1_id === filter.country && i.tier2_id === 0 && i.tier3_id === null)
        let id6 = approvedindicator.findIndex(i => i.year === filter.year && i.indicatorId === 6 && i.tier1_id === filter.country && i.tier2_id === 0 && i.tier3_id === null)
        let id7 = approvedindicator.findIndex(i => i.year === filter.year && i.indicatorId === 7 && i.tier1_id === filter.country && i.tier2_id === 0 && i.tier3_id === null)

        let id8 = approvedindicator.findIndex(i => i.year === filter.year && i.indicatorId === 8 && i.tier1_id === filter.country && i.tier2_id === 0 && i.tier3_id === null)
        let id9 = approvedindicator.findIndex(i => i.year === filter.year && i.indicatorId === 9 && i.tier1_id === filter.country && i.tier2_id === 0 && i.tier3_id === null)
        let id10 = approvedindicator.findIndex(i => i.year === filter.year && i.indicatorId === 10 && i.tier1_id === filter.country && i.tier2_id === 0 && i.tier3_id === null)
        let id11 = approvedindicator.findIndex(i => i.year === filter.year && i.indicatorId === 11 && i.tier1_id === filter.country && i.tier2_id === 0 && i.tier3_id === null)
        let id12 = approvedindicator.findIndex(i => i.year === filter.year && i.indicatorId === 12 && i.tier1_id === filter.country && i.tier2_id === 0 && i.tier3_id === null)
        let id13 = approvedindicator.findIndex(i => i.year === filter.year && i.indicatorId === 13 && i.tier1_id === filter.country && i.tier2_id === 0 && i.tier3_id === null)

        let obj = { methodology: 'Sum of GHG scope 1 [tCO2e] and GHG scope 2 market-based [tCO2e]', performanceCommentary: id1 === -1 ? Array.from({ length: 4 }).fill().map(i => []) : approvedindicator[id1].performanceCommentary, id: id1 === -1 ? 0 : approvedindicator[id1].id, indicatorId: 1, year: filter.year, months: fyMonthHeader, approverFrequency: 3, monthMTD: { cur: getYTQMonth([curMTDMonthHeader[curMTDMonthHeader.length - 1]]), prev: getYTQMonth([pyMTDMonthHeader[pyMTDMonthHeader.length - 1]]) }, title: 'GHG Scope 1 & 2 Market-based', selected: true, category: 1, data: [{ unit: 'tCO2e', values: groupByFrequency(fyMonthHeader, 1).map(i => (getDisplayStatus_rparray(i) ? getValue(i, filteredSubmissions, filter.year, [304, 305, 287]).toFixed(2) : '-')) }], dataSources: groupByFrequency(curMTDMonths, 1).map(i => getDataSources(i, overallQuantitativeSubmission, filter, [304, 305, 287])), previousMTD: prevMTDMonthValue.toFixed(2), currentMTD: curMTDMonthValue.toFixed(2), frequency: 1, statuses: [2, 2, 2, 2], labels: splitArrayByFrequency(curMTDMonthHeader, 3).map((i, index) => checkDataSources(i, filteredSubmissions, filteredSubmissionsPy, 3, 3, index, [304, 305, 287], filter)), deviation: (deviation1 === null ? 'NA' : Math.abs(deviation1).toFixed(2) + '%'), deviationDirection: deviation1 === null ? false : curMTDMonthValue >= prevMTDMonthValue ? false : true }
        let obj1 = { methodology: 'Emissions from refrigerant [tCO2e] and Emissions from fuel consumption [tCO2e]', performanceCommentary: id2 === -1 ? Array.from({ length: 4 }).fill().map(i => []) : approvedindicator[id2].performanceCommentary, id: id2 === -1 ? 0 : approvedindicator[id2].id, indicatorId: 2, year: filter.year, months: fyMonthHeader, approverFrequency: 3, monthMTD: { cur: getYTQMonth([curMTDMonthHeader[curMTDMonthHeader.length - 1]]), prev: getYTQMonth([pyMTDMonthHeader[pyMTDMonthHeader.length - 1]]) }, title: 'GHG Scope 1', selected: true, category: 1, data: [{ unit: 'tCO2e', values: groupByFrequency(fyMonthHeader, 1).map(i => (getDisplayStatus_rparray(i) ? getValue(i, filteredSubmissions, filter.year, [304, 305]).toFixed(2) : '-')) }], dataSources: groupByFrequency(curMTDMonths, 1).map(i => getDataSources(i, overallQuantitativeSubmission, filter, [304, 305])), previousMTD: prevMTDMonthValue.toFixed(2), currentMTD: curMTDMonthValue.toFixed(2), frequency: 1, statuses: [2, 2, 2, 2], labels: splitArrayByFrequency(curMTDMonthHeader, 3).map((i, index) => checkDataSources(i, filteredSubmissions, filteredSubmissionsPy, 3, 3, index, [304, 305], filter)), deviation: (deviation2 === null ? 'NA' : Math.abs(deviation2).toFixed(2) + '%'), deviationDirection: deviation2 === null ? false : curMTDMonthValue2 >= prevMTDMonthValue2 ? false : true }
        let obj2 = { methodology: '(Grid electricity consumption [MWh] + renewable energy from PPA [MWh] + renewable energy from green tariff [MWh]) x Grid emission factor [tCO2e/energy unit]', performanceCommentary: id5 === -1 ? Array.from({ length: 4 }).fill().map(i => []) : approvedindicator[id5].performanceCommentary, id: id5 === -1 ? 0 : approvedindicator[id5].id, indicatorId: 5, year: filter.year, months: fyMonthHeader, approverFrequency: 3, monthMTD: { cur: getYTQMonth([curMTDMonthHeader[curMTDMonthHeader.length - 1]]), prev: getYTQMonth([pyMTDMonthHeader[pyMTDMonthHeader.length - 1]]) }, title: 'GHG Scope 2 Location-based', selected: true, category: 1, data: [{ unit: 'tCO2e', values: groupByFrequency(fyMonthHeader, 1).map(i => (getDisplayStatus_rparray(i) ? getValue(i, filteredSubmissions, filter.year, [287]).toFixed(2) : '-')) }], dataSources: groupByFrequency(curMTDMonths, 1).map(i => getDataSources(i, overallQuantitativeSubmission, filter, [287])), previousMTD: prevMTDMonthValue.toFixed(2), currentMTD: curMTDMonthValue.toFixed(2), frequency: 1, statuses: [2, 2, 2, 2], labels: splitArrayByFrequency(curMTDMonthHeader, 3).map((i, index) => checkDataSources(i, filteredSubmissions, filteredSubmissionsPy, 3, 3, index, [287], filter)), deviation: (deviation2 === null ? 'NA' : Math.abs(deviation2).toFixed(2) + '%'), deviationDirection: deviation2 === null ? false : curMTDMonthValue2 >= prevMTDMonthValue2 ? false : true }

        let obj3 = {
            methodology: 'Women in x position (Mid-management and Senior management)  /Total people in x position (Mid-management and Senior management)',
            performanceCommentary: id3 === -1 ? Array.from({ length: 2 }).fill().map(i => []) : approvedindicator[id3].performanceCommentary,
            id: id3 === -1 ? 0 : approvedindicator[id3].id, indicatorId: 3, year: filter.year, months: fyMonthHeader3, approverFrequency: 6, monthMTD: { cur: getYTQMonth([curMTDMonthHeader3[curMTDMonthHeader3.length - 1]]), prev: getYTQMonth([pyMTDMonthHeader3[pyMTDMonthHeader3.length - 1]]) }, title: 'Total Women in Middle and Senior Management (Managers and above)', selected: true, category: 2, dataSources: groupByFrequency(curMTDMonths3, 1).map(i => getDataSources(i, overallQuantitativeSubmission, filter, [275])), data:
                dps.map((z, index) => {

                    return ({ unit: z.unit, values: groupByFrequency(fyMonthHeader3, 1).map(i => (getDisplayStatus_rparray(i) ? z.value({ i, filteredSubmissions, yr: filter.year, dcfIds: [275], dparray1: (index === 0 || index === 1) ? ['DPAN614'] : ['DPAN616'], dparray2: (index === 0 || index === 1) ? ['DPAN613', 'DPAN614'] : ['DPAN615', 'DPAN616'] }).toFixed(0) + z.uom : '-')) })

                }
                )
            ,
            previousMTD: prevMTDMonthValue3.toFixed(2), currentMTD: curMTDMonthValue3.toFixed(2), frequency: 6, statuses: [2, 2], labels: processDateArray(fyMonthHeader3, 6, 6).map((i, index) => checkDataSourcesByDp(i, filteredSubmissions, filteredSubmissionsPy, 1, 2, index, [275], ['DPAN614', 'DPAN616'], filter)), deviation: (deviation3 === null ? 'NA' : Math.abs(deviation3).toFixed(2) + '%'), deviationDirection: deviation3 === null ? false : curMTDMonthValue3 >= prevMTDMonthValue3 ? false : true
        }
        let obj4 = {
            methodology: 'Women in x position ( Senior management )  /Total people in x position ( Senior management )',
            performanceCommentary: id4 === -1 ? Array.from({ length: 2 }).fill().map(i => []) : approvedindicator[id4].performanceCommentary, id: id4 === -1 ? 0 : approvedindicator[id4].id, indicatorId: 4, year: filter.year, months: fyMonthHeader3, approverFrequency: 6, monthMTD: { cur: getYTQMonth([curMTDMonthHeader3[curMTDMonthHeader3.length - 1]]), prev: getYTQMonth([pyMTDMonthHeader3[pyMTDMonthHeader3.length - 1]]) }, title: 'Total Women Employees', selected: true, category: 2, dataSources: groupByFrequency(curMTDMonths3, 1).map(i => getDataSources(i, overallQuantitativeSubmission, filter, [275])), data:
                dps2.map((z, index) => {

                    return ({ unit: z.unit, values: groupByFrequency(fyMonthHeader3, 1).map(i => (getDisplayStatus_rparray(i) ? z.value({ i, filteredSubmissions, yr: filter.year, dcfIds: [275], dparray1: ['DPAN601', 'DPAN603'], dparray2: ['DPAN600', 'DPAN602', 'DPAN601', 'DPAN603'] }).toFixed(0) + z.uom : '-')) })

                }
                )
            ,
            previousMTD: prevMTDMonthValue4.toFixed(2), currentMTD: curMTDMonthValue4.toFixed(2), frequency: 6, statuses: [2, 2], labels: processDateArray(fyMonthHeader3, 6, 6).map((i, index) => checkDataSourcesByDp(i, filteredSubmissions, filteredSubmissionsPy, 1, 2, index, [275], ['DPAN601', 'DPAN603'], filter)), deviation: (deviation4 === null ? 'NA' : Math.abs(deviation4).toFixed(2) + '%'), deviationDirection: deviation4 === null ? false : curMTDMonthValue4 >= prevMTDMonthValue4 ? false : true
        }
        let obj5 = { methodology: 'Sum of water withdrawn [m3]', performanceCommentary: id6 === -1 ? Array.from({ length: 4 }).fill().map(i => []) : approvedindicator[id6].performanceCommentary, id: id6 === -1 ? 0 : approvedindicator[id6].id, indicatorId: 6, year: filter.year, months: fyMonthHeader, approverFrequency: 3, monthMTD: { cur: getYTQMonth([curMTDMonthHeader[curMTDMonthHeader.length - 1]]), prev: getYTQMonth([pyMTDMonthHeader[pyMTDMonthHeader.length - 1]]) }, title: 'Water Withdrawn', selected: true, category: 3, data: [{ unit: 'm3', values: groupByFrequency(fyMonthHeader, 1).map(i => (getDisplayStatus_rparray(i) ? getValueBySumOfId(i, filteredSubmissions, filter.year, [285], ['DPAN1159', 'DPAN1161'], 0).toFixed(2) : '-')) }], dataSources: groupByFrequency(curMTDMonths, 1).map(i => getDataSources(i, overallQuantitativeSubmission, filter, [285])), previousMTD: prevMTDMonthValue.toFixed(2), currentMTD: curMTDMonthValue.toFixed(2), frequency: 1, statuses: [2, 2, 2, 2], labels: splitArrayByFrequency(curMTDMonthHeader, 3).map((i, index) => checkDataSources(i, filteredSubmissions, filteredSubmissionsPy, 3, 3, index, [285], filter)), deviation: (deviation5 === null ? 'NA' : Math.abs(deviation5).toFixed(2) + '%'), deviationDirection: deviation5 === null ? false : curMTDMonthValue5 >= prevMTDMonthValue5 ? false : true }
        let obj6 = { methodology: 'Water withdrawn [m3] - Water discharged [m3]', performanceCommentary: id7 === -1 ? Array.from({ length: 4 }).fill().map(i => []) : approvedindicator[id7].performanceCommentary, id: id7 === -1 ? 0 : approvedindicator[id7].id, indicatorId: 7, year: filter.year, months: fyMonthHeader, approverFrequency: 3, monthMTD: { cur: getYTQMonth([curMTDMonthHeader[curMTDMonthHeader.length - 1]]), prev: getYTQMonth([pyMTDMonthHeader[pyMTDMonthHeader.length - 1]]) }, title: 'Water Consumed', selected: true, category: 3, data: [{ unit: 'm3', values: groupByFrequency(fyMonthHeader, 1).map(i => (getDisplayStatus_rparray(i) ? getValueByComparison(i, filteredSubmissions, filter.year, [285, 286]).toFixed(2) : '-')) }], dataSources: groupByFrequency(curMTDMonths, 1).map(i => getDataSources(i, overallQuantitativeSubmission, filter, [285, 286])), previousMTD: prevMTDMonthValue.toFixed(2), currentMTD: curMTDMonthValue.toFixed(2), frequency: 1, statuses: [2, 2, 2, 2], labels: splitArrayByFrequency(curMTDMonthHeader, 3).map((i, index) => checkDataSources(i, filteredSubmissions, filteredSubmissionsPy, 3, 3, index, [285, 286], filter)), deviation: (deviation6 === null ? 'NA' : Math.abs(deviation6).toFixed(2) + '%'), deviationDirection: deviation6 === null ? false : curMTDMonthValue6 >= prevMTDMonthValue6 ? false : true }
        let obj7 = { methodology: 'Sum of water discharged [m3]', performanceCommentary: id8 === -1 ? Array.from({ length: 4 }).fill().map(i => []) : approvedindicator[id8].performanceCommentary, id: id8 === -1 ? 0 : approvedindicator[id8].id, indicatorId: 8, year: filter.year, months: fyMonthHeader, approverFrequency: 3, monthMTD: { cur: getYTQMonth([curMTDMonthHeader[curMTDMonthHeader.length - 1]]), prev: getYTQMonth([pyMTDMonthHeader[pyMTDMonthHeader.length - 1]]) }, title: 'Water Discharged', selected: true, category: 3, data: [{ unit: 'm3', values: groupByFrequency(fyMonthHeader, 1).map(i => (getDisplayStatus_rparray(i) ? getValueBySumOfId(i, filteredSubmissions, filter.year, [286], ['DPAN0053'], 0).toFixed(2) : '-')) }], dataSources: groupByFrequency(curMTDMonths, 1).map(i => getDataSources(i, overallQuantitativeSubmission, filter, [286])), previousMTD: prevMTDMonthValue.toFixed(2), currentMTD: curMTDMonthValue.toFixed(2), frequency: 1, statuses: [2, 2, 2, 2], labels: splitArrayByFrequency(curMTDMonthHeader, 3).map((i, index) => checkDataSources(i, filteredSubmissions, filteredSubmissionsPy, 3, 3, index, [286], filter)), deviation: (deviation7  === null ? 'NA' : Math.abs(deviation7 ).toFixed(2) + '%'), deviationDirection: deviation7  === null ? false : curMTDMonthValue7 >= prevMTDMonthValue7 ? false : true }
        let obj8 = { methodology: 'Water withdrawn [m3] /Total electricity consumption by customer IT [kWh]', performanceCommentary: id9 === -1 ? Array.from({ length: 4 }).fill().map(i => []) : approvedindicator[id9].performanceCommentary, id: id9 === -1 ? 0 : approvedindicator[id9].id, indicatorId: 9, year: filter.year, months: fyMonthHeader, approverFrequency: 3, monthMTD: { cur: getYTQMonth([curMTDMonthHeader[curMTDMonthHeader.length - 1]]), prev: getYTQMonth([pyMTDMonthHeader[pyMTDMonthHeader.length - 1]]) }, title: 'WUE', selected: true, category: 3, data: [{ unit: 'no unit', values: groupByFrequency(fyMonthHeader, 1).map(i => (getDisplayStatus_rparray(i) ? getValueByDivision(i, filteredSubmissions, filter.year, [285, 264]).toFixed(2) : '-')) }], dataSources: groupByFrequency(curMTDMonths, 1).map(i => getDataSources(i, overallQuantitativeSubmission, filter, [285, 264])), previousMTD: prevMTDMonthValue.toFixed(2), currentMTD: curMTDMonthValue.toFixed(2), frequency: 1, statuses: [2, 2, 2, 2], labels: splitArrayByFrequency(curMTDMonthHeader, 3).map((i, index) => checkDataSources(i, filteredSubmissions, filteredSubmissionsPy, 3, 3, index, [285, 264], filter)), deviation: (deviation8 === null ? 'NA' : Math.abs(deviation8).toFixed(2) + '%'), deviationDirection: deviation8 === null ? false : curMTDMonthValue8 >= prevMTDMonthValue8 ? false : true }
        let obj9 = { methodology: 'sum of non-hazardous waste disposed [tonnes]', performanceCommentary: id10 === -1 ? Array.from({ length: 4 }).fill().map(i => []) : approvedindicator[id10].performanceCommentary, id: id10 === -1 ? 0 : approvedindicator[id10].id, indicatorId: 10, year: filter.year, months: fyMonthHeader, approverFrequency: 3, monthMTD: { cur: getYTQMonth([curMTDMonthHeader[curMTDMonthHeader.length - 1]]), prev: getYTQMonth([pyMTDMonthHeader[pyMTDMonthHeader.length - 1]]) }, title: 'Total Non-hazardous Waste Generated', selected: true, category: 1, data: [{ unit: 'tonnes', values: groupByFrequency(fyMonthHeader, 1).map(i => (getDisplayStatus_rparray(i) ? getValueByNonHazardousWaste(i, filteredSubmissions, filter.year, [307]).toFixed(2) : '-')) }], dataSources: groupByFrequency(curMTDMonths, 1).map(i => getDataSources(i, overallQuantitativeSubmission, filter, [307])), previousMTD: prevMTDMonthValue.toFixed(2), currentMTD: curMTDMonthValue.toFixed(2), frequency: 1, statuses: [2, 2, 2, 2], labels: splitArrayByFrequency(curMTDMonthHeader, 3).map((i, index) => checkDataSources(i, filteredSubmissions, filteredSubmissionsPy, 3, 3, index, [307], filter)), deviation: (deviation9 === null ? 'NA' : Math.abs(deviation9).toFixed(2) + '%'), deviationDirection: deviation9 === null ? false : curMTDMonthValue9 >= prevMTDMonthValue9 ? false : true }
        let obj10 = { methodology: 'sum of non-hazardous waste disposed through recycling, recovery, composting,... [tonnes]', performanceCommentary: id11 === -1 ? Array.from({ length: 4 }).fill().map(i => []) : approvedindicator[id11].performanceCommentary, id: id11 === -1 ? 0 : approvedindicator[id11].id, indicatorId: 11, year: filter.year, months: fyMonthHeader, approverFrequency: 3, monthMTD: { cur: getYTQMonth([curMTDMonthHeader[curMTDMonthHeader.length - 1]]), prev: getYTQMonth([pyMTDMonthHeader[pyMTDMonthHeader.length - 1]]) }, title: 'Non-hazardous Waste Diversion', selected: true, category: 1, data: [{ unit: 'tonnes', values: groupByFrequency(fyMonthHeader, 1).map(i => (getDisplayStatus_rparray(i) ? getValueByNonHazardousWaste(i, filteredSubmissions, filter.year, [307]).toFixed(2) : '-')) }], dataSources: groupByFrequency(curMTDMonths, 1).map(i => getDataSources(i, overallQuantitativeSubmission, filter, [307])), previousMTD: prevMTDMonthValue.toFixed(2), currentMTD: curMTDMonthValue.toFixed(2), frequency: 1, statuses: [2, 2, 2, 2], labels: splitArrayByFrequency(curMTDMonthHeader, 3).map((i, index) => checkDataSources(i, filteredSubmissions, filteredSubmissionsPy, 3, 3, index, [307], filter)), deviation: (deviation9 === null ? 'NA' : Math.abs(deviation9).toFixed(2) + '%'), deviationDirection: deviation9 === null ? false : curMTDMonthValue9 >= prevMTDMonthValue9 ? false : true }
        let obj11 = { methodology: 'sum of hazardous waste disposed [tonnes]', performanceCommentary: id12 === -1 ? Array.from({ length: 4 }).fill().map(i => []) : approvedindicator[id12].performanceCommentary, id: id12 === -1 ? 0 : approvedindicator[id12].id, indicatorId: 12, year: filter.year, months: fyMonthHeader, approverFrequency: 3, monthMTD: { cur: getYTQMonth([curMTDMonthHeader[curMTDMonthHeader.length - 1]]), prev: getYTQMonth([pyMTDMonthHeader[pyMTDMonthHeader.length - 1]]) }, title: 'Total Hazardous Waste Generated', selected: true, category: 1, data: [{ unit: 'tonnes', values: groupByFrequency(fyMonthHeader, 1).map(i => (getDisplayStatus_rparray(i) ? getValueByHazardousWaste(i, filteredSubmissions, filter.year, [297]).toFixed(2) : '-')) }], dataSources: groupByFrequency(curMTDMonths, 1).map(i => getDataSources(i, overallQuantitativeSubmission, filter, [297])), previousMTD: prevMTDMonthValue.toFixed(2), currentMTD: curMTDMonthValue.toFixed(2), frequency: 1, statuses: [2, 2, 2, 2], labels: splitArrayByFrequency(curMTDMonthHeader, 3).map((i, index) => checkDataSources(i, filteredSubmissions, filteredSubmissionsPy, 3, 3, index, [297], filter)), deviation: (deviation10 === null ? 'NA' : Math.abs(deviation10).toFixed(2) + '%'), deviationDirection: deviation10 === null ? false : curMTDMonthValue10 >= prevMTDMonthValue10 ? false : true }
        let obj12 = { methodology: ' sum of hazardous waste disposed through recycling, recovery, composting, waste-to-energy plants,... [tonnes]', performanceCommentary: id13 === -1 ? Array.from({ length: 4 }).fill().map(i => []) : approvedindicator[id13].performanceCommentary, id: id13 === -1 ? 0 : approvedindicator[id13].id, indicatorId: 13, year: filter.year, months: fyMonthHeader, approverFrequency: 3, monthMTD: { cur: getYTQMonth([curMTDMonthHeader[curMTDMonthHeader.length - 1]]), prev: getYTQMonth([pyMTDMonthHeader[pyMTDMonthHeader.length - 1]]) }, title: 'Hazardous Waste Recycling', selected: true, category: 1, data: [{ unit: 'tonnes', values: groupByFrequency(fyMonthHeader, 1).map(i => (getDisplayStatus_rparray(i) ? getValueByHazardousWaste(i, filteredSubmissions, filter.year, [297]).toFixed(2) : '-')) }], dataSources: groupByFrequency(curMTDMonths, 1).map(i => getDataSources(i, overallQuantitativeSubmission, filter, [297])), previousMTD: prevMTDMonthValue.toFixed(2), currentMTD: curMTDMonthValue.toFixed(2), frequency: 1, statuses: [2, 2, 2, 2], labels: splitArrayByFrequency(curMTDMonthHeader, 3).map((i, index) => checkDataSources(i, filteredSubmissions, filteredSubmissionsPy, 3, 3, index, [297], filter)), deviation: (deviation10 === null ? 'NA' : Math.abs(deviation10).toFixed(2) + '%'), deviationDirection: deviation10 === null ? false : curMTDMonthValue10 >= prevMTDMonthValue10 ? false : true }

        

        setindicator([obj, obj1, obj2, obj3, obj4, obj5, obj6, obj7,obj8,obj9,obj10,obj11,obj12].filter(i => (i.category === filter.category || filter.category === 0)))
        setindicatorBk([obj, obj1, obj2, obj3, obj4, obj5, obj6, obj7,obj8,obj9,obj10,obj11,obj12])
    }
    const getPercentage = (current, previous) => {

        if (previous === 0) {
            return null;
        }
        const percentage = ((current - previous) / previous) * 100;
        return percentage

    }

    const getValue = (rp, submissions, yr, dcfIds) => {

        let filteredSubmisionRp = submissions.filter(i => getRPTextFormat(i.reporting_period) === rp[0] && dcfIds.includes(i.dcfId)).map(i => getEmissionFactorCalculation(i, yr).reduce((a, b) => { return a + b.ghg }, 0)).reduce((a, b) => { return a + b }, 0)
        console.log(rp, submissions, yr, dcfIds, submissions.filter(i => getRPTextFormat(i.reporting_period) === rp[0] && dcfIds.includes(i.dcfId)), filteredSubmisionRp)
        return parseFloat(filteredSubmisionRp.toFixed(2))

    }
    const getValueBySumOfId = (rp, submissions, yr, dcfIds, dpids, method) => {

        let filteredSubmisionRp = submissions.filter(i => getRPTextFormat(i.reporting_period) === rp[0] && dcfIds.includes(i.dcfId)).flatMap(i => i.response).reduce((totalSum, obj) => {
            return totalSum + dpids.reduce((sum, key) => sum + (obj[key] || 0), 0);
        }, 0);
        console.log(submissions.filter(i => getRPTextFormat(i.reporting_period) === rp[0] && dcfIds.includes(i.dcfId)).flatMap(i => i.response))
        return parseFloat(filteredSubmisionRp.toFixed(2))

    }
  const  getValueByDivision = (rp, submissions, yr, dcfIds, dpids, method) => {

        let withdrawl = submissions.filter(i => getRPTextFormat(i.reporting_period) === rp[0] && dcfIds[0] ===  i.dcfId).flatMap(i => i.response).reduce((totalSum, obj) => {
            return totalSum + ['DPAN1159', 'DPAN1161'].reduce((sum, key) => sum + (obj[key] || 0), 0);
        }, 0);
        let customerLoad = submissions.filter(i => getRPTextFormat(i.reporting_period) === rp[0] && dcfIds[1] === i.dcfId).flatMap(i => i.response).filter(i=>i.type === 'number').reduce((totalSum, obj) => {
            return totalSum + ['value'].reduce((sum, key) => sum + (obj[key] || 0), 0);
        }, 0);

        return customerLoad ?  parseFloat((withdrawl / customerLoad).toFixed(2)) : 0

    }
    const getNonHazardousValue=(nhdata)=>{
      
    let tonnes = 0
    
        for(const item of nhdata){
            let amount = item.DPAH502
            let density = item.DPAH503
            if(item.DPAH501 === 1){
                tonnes+= (amount/1000)
            }else if(item.DPAH501 === 2){
                tonnes+= (amount* density *  0.000001)
            }else if(item.DPAH501 === 1){
                tonnes+= (amount* density) / 1000

            }
        }
        return tonnes
    }
    const getHazardousValue=(nhdata)=>{
      
        let tonnes = 0
        
            for(const item of nhdata){
                let amount = item.DPAH0015
                let density = item.DPAH0016
                if(item.DPAH0014 === 1){
                    tonnes+= (amount/1000)
                }else if(item.DPAH0014 === 2){
                    tonnes+= (amount* density *  0.000001)
                }else if(item.DPAH0014 === 1){
                    tonnes+= (amount* density) / 1000
    
                }
            }
            return tonnes
        }
    const getValueByNonHazardousWaste  = (rp, submissions, yr, dcfIds, dpids, method) => {

        let nonhazardous = getNonHazardousValue(submissions.filter(i => getRPTextFormat(i.reporting_period) === rp[0] && dcfIds.includes(i.dcfId)).flatMap(i => i.response))
        
   

        return parseFloat((nonhazardous).toFixed(2))

    }
    const getValueByHazardousWaste  = (rp, submissions, yr, dcfIds, dpids, method) => {

        let nonhazardous = getHazardousValue(submissions.filter(i => getRPTextFormat(i.reporting_period) === rp[0] && dcfIds.includes(i.dcfId)).flatMap(i => i.response))
        
   

        return parseFloat((nonhazardous).toFixed(2))

    }
    const getValueByComparison = (rp, submissions, yr, dcfIds, dpids, method) => {

        let withdrawl = submissions.filter(i => getRPTextFormat(i.reporting_period) === rp[0] && dcfIds.includes(i.dcfId)).flatMap(i => i.response).reduce((totalSum, obj) => {
            return totalSum + ['DPAN1159', 'DPAN1161'].reduce((sum, key) => sum + (obj[key] || 0), 0);
        }, 0);
        let discharge = submissions.filter(i => getRPTextFormat(i.reporting_period) === rp[0] && dcfIds.includes(i.dcfId)).flatMap(i => i.response).reduce((totalSum, obj) => {
            return totalSum + ['DPAN0053'].reduce((sum, key) => sum + (obj[key] || 0), 0);
        }, 0);

        return parseFloat((withdrawl - discharge).toFixed(2))

    }
    const getValueAsObject = (rp, submissions, yr, dcfIds) => {
        console.log(rp)
        let filteredSubmisionRp = submissions.filter(i => getRPTextFormat(i.reporting_period) === rp[0] && dcfIds.includes(i.dcfId)).map(i => ({ dcfId: i.dcfId, ghg: getEmissionFactorCalculation(i, yr).reduce((a, b) => { return a + b.ghg }, 0) }))

        return filteredSubmisionRp

    }
    const getValueAsRawObject = (rp, submissions, yr, dcfIds) => {
        console.log(rp)
        let filteredSubmisionRp = submissions.filter(i => getRPTextFormat(i.reporting_period) === rp[0] && dcfIds.includes(i.dcfId)).flatMap(i => i.response)

        return filteredSubmisionRp

    }
    const getValueAsUnShapedObject = (rp, submissions, yr, dcfIds) => {
        console.log(rp)
        let filteredSubmisionRp = submissions.filter(i => getRPTextFormat(i.reporting_period) === rp[0] && dcfIds.includes(i.dcfId)).map(i => ({ dcfId: i.dcfId, ghg: getEmissionFactorCalculation(i, yr) }))

        return filteredSubmisionRp

    }
    function getMonthsBetween(start_date, end_date, frequency, filterYear, fymonth) {
        let fyStartDate, fyEndDate;

        if (fymonth === 1) {
            // Calendar year: Jan to Dec of the filterYear
            fyStartDate = DateTime.fromObject({ year: filterYear, month: 1, day: 1 });
            fyEndDate = DateTime.fromObject({ year: filterYear, month: 12, day: 31 });
        } else {
            // Fiscal year: Apr (fymonth) of the previous year to Mar (fymonth - 1) of the filterYear
            fyStartDate = DateTime.fromObject({ year: filterYear - 1, month: fymonth, day: 1 });
            fyEndDate = DateTime.fromObject({ year: filterYear, month: fymonth - 1, day: 1 }).endOf('month');
        }

        // Override with provided start_date and end_date if available
        const userStartDate = start_date ? DateTime.fromISO(start_date, { zone: 'utc' }).startOf('month') : fyStartDate;
        const userEndDate = end_date ? DateTime.fromISO(end_date, { zone: 'utc' }).endOf('month') : DateTime.utc().toLocal();

        // Adjust the final start and end dates to be within the fiscal year range
        const finalStartDate = userStartDate < fyStartDate ? fyStartDate : userStartDate;
        const finalEndDate = userEndDate > fyEndDate ? fyEndDate : userEndDate;

        const months = [];
        let currentMonth = finalStartDate;

        while (currentMonth <= finalEndDate) {
            if (frequency === 1) {
                months.push(currentMonth.toFormat("LLL-yyyy"));
            } else {
                const periodEnd = currentMonth.plus({ months: frequency - 1 });
                if (periodEnd <= finalEndDate) {
                    months.push(
                        currentMonth.toFormat("LLL-yyyy") +
                        " to " +
                        periodEnd.toFormat("LLL-yyyy")
                    );
                }
            }
            currentMonth = currentMonth.plus({ months: frequency });
        }

        return months;
    }
    const getUser = (id) => {
        if (id === admin_data.id) {
            return "Enterprise Admin";
        }
        console.log(userLookup[id])
        return userLookup[id] ? userLookup[id].information.empname : ''
    };
    const userLookup = userList.reduce((acc, user) => {
        acc[user.id] = user;
        return acc;
    }, {});
    const getDueMonth = (dateStr) => {
        const [from, to] = dateStr.split(' to ')
        if (to) {
            return to
        }
        return from
    }
    const getYTQMonth = (dateStr) => {

        const [from, to] = dateStr[0].split(' to ')
        if (to) {
            return to
        }
        return from
    }

    function getOverdueDays(monthString) {
        console.log(monthString)
        const [startMonth, endMonth] = monthString.split(' to ');

        const month = endMonth ? endMonth : startMonth;
        const [monthValue, year] = month.split('-');
        const endOfMonth = DateTime.fromObject({ year: parseInt(year), month: DateTime.fromFormat(monthValue, 'LLL').month }).endOf('month');
        const currentDate = DateTime.local();
        console.log(month, endOfMonth.diff(currentDate, 'days').days)
        return endOfMonth.diff(currentDate, 'days').days;
    }
    const getDataSources = (rp, submissions, filter, dcfIds) => {
        let filteredQnAssByYear = filterDataByTierAndLocation(filterAssignmentsByFiscalYear(JSON.parse(JSON.stringify(assignment)).filter(i => dcfIds.includes(i.dcfId)), filter.year), rawsitelist, filter.country, filter.city, filter.location)
        let filteredSubmisionRp = submissions.filter(i => getRPTextFormat(i.reporting_period) === rp[0]).filter(i => dcfIds.includes(i.dcfId))

        let submissionStatus = []
        for (const item of filteredQnAssByYear) {
            let months = getMonthsBetween(item.start_date, item.end_date, item.frequency === 4 ? 12 : item.frequency === 5 ? 6 : item.frequency, filter.year, fymonth).filter(i => { console.log(rp); return i === rp[0] })

            for (const mn of months) {
                let index = filteredSubmisionRp.findIndex(i => (mn === getRPTextFormat(i.reporting_period) && i.entityAssId === item.entityAssId && i.entityUserAssId === item.id && i.dcfId === item.dcfId && i.tier0_id === item.tier0_id && i.tier1_id === item.tier1_id && i.tier2_id === item.tier2_id && i.tier3_id === item.tier3_id))
                if (index !== -1) {
                    let type = filteredSubmisionRp[index].type
                    let reject = filteredSubmisionRp[index].reject
                    let status = (type === 0 && !reject) ? 0 :
                        (type === 0 && (reject === 1 || reject === 2))
                            ? 1
                            : type === 1 && reject === 1
                                ? 2
                                : type === 1
                                    ? 3
                                    : type === 2
                                        ? 4
                                        : type === 3
                                            ? 5
                                            : null
                    if (status === 0 || status === 1) {

                        status = getOverdueDays(mn) <= -DateTime.utc().toLocal().daysInMonth ? 7 : getOverdueDays(mn) <= 0 ? 6 : 99


                    }


                    submissionStatus.push({
                        ...item, reporters: item.reporter_ids.map(id => getUser(id)).filter(Boolean),
                        reviewers: item.reviewer_ids.map(id => getUser(id)).filter(Boolean),
                        approvers: item.approver_ids.map(id => getUser(id)).filter(Boolean), dueMonth: getDueMonth(mn), reporting_period: mn, data: filteredSubmisionRp[index], status, coverage: getCoverageText(item, rawsitelist)
                    })
                } else {
                    let status = getOverdueDays(mn) <= -DateTime.utc().toLocal().daysInMonth ? 7 : getOverdueDays(mn) <= 0 ? 6 : 100

                    submissionStatus.push({
                        ...item, reporters: item.reporter_ids.map(id => getUser(id)).filter(Boolean),
                        reviewers: item.reviewer_ids.map(id => getUser(id)).filter(Boolean),
                        approvers: item.approver_ids.map(id => getUser(id)).filter(Boolean), dueMonth: getDueMonth(mn), reporting_period: mn, status, coverage: getCoverageText(item, rawsitelist)
                    })
                }

            }
        }
        console.log(rp, submissionStatus, filteredQnAssByYear, filteredSubmisionRp, filter)

        return submissionStatus

    }

    function getPreviousYear(dateArray) {
        return dateArray.map(dateStr => {
            if (dateStr.includes("to")) {
                // Handle range format "Jan-2024 to Feb-2024"
                const [startStr, endStr] = dateStr.split(" to ");
                const startDate = DateTime.fromFormat(startStr, 'MMM-yyyy').minus({ years: 1 });
                const endDate = DateTime.fromFormat(endStr, 'MMM-yyyy').minus({ years: 1 });

                return `${startDate.toFormat('MMM-yyyy')} to ${endDate.toFormat('MMM-yyyy')}`;
            } else {
                // Handle single date format "May-2024"
                const date = DateTime.fromFormat(dateStr, 'MMM-yyyy').minus({ years: 1 });
                return date.toFormat('MMM-yyyy');
            }
        });
    }


    function getPreviousMonths(dateArray) {
        return dateArray.map(dateStr => {
            if (dateStr.includes("to")) {
                // Handle range format "Jan-2022 to Feb-2022"
                const [startStr, endStr] = dateStr.split(" to ");
                const startDate = DateTime.fromFormat(startStr, 'MMM-yyyy').minus({ months: dateArray.length });
                const endDate = DateTime.fromFormat(endStr, 'MMM-yyyy').minus({ months: dateArray.length });

                return `${startDate.toFormat('MMM-yyyy')} to ${endDate.toFormat('MMM-yyyy')}`;
            } else {
                // Handle single date format "Jan-2022"
                const date = DateTime.fromFormat(dateStr, 'MMM-yyyy').minus({ months: dateArray.length });
                return date.toFormat('MMM-yyyy');
            }
        });
    }

    // Example usage:

    const getMultipleValue = (rp, submissions, yr, dcfIds) => {

        return 0

    }
    const getPercentageByDp = (props) => {
        console.log(props)
        const { i: rp, filteredSubmissions: submissions, yr, dcfIds, dparray1, dparray2 } = props
        let filteredSubmisionRp = submissions.filter(i => (getRPTextFormat(i.reporting_period) === rp[0] && dcfIds.includes(i.dcfId))).map(i => i.response).reduce((a, b) => { return [...a, ...b] }, [])
        const sum = dparray1.reduce((total, key) => {
            return total + filteredSubmisionRp.reduce((sum, obj) => sum + (obj[key] || 0), 0);
        }, 0);
        const total = dparray2.reduce((total, key) => {
            return total + filteredSubmisionRp.reduce((sum, obj) => sum + (obj[key] || 0), 0);
        }, 0);
        return !total ? 0 : parseFloat((sum / total) * 100)


    }
    const getSumValueByDp = (props) => {
        const { i: rp, filteredSubmissions: submissions, yr, dcfIds, dparray1, dparray2 } = props
        console.log(props, submissions)
        let filteredSubmisionRp = submissions.filter(i => getRPTextFormat(i.reporting_period) === rp[0] && dcfIds.includes(i.dcfId)).map(i => i.response).reduce((a, b) => { return [...a, ...b] }, [])
        const sum = dparray1.reduce((total, key) => {

            return total + filteredSubmisionRp.reduce((sum, obj) => sum + (obj[key] || 0), 0);
        }, 0);
        console.log(filteredSubmisionRp, submissions.filter(i => getRPTextFormat(i.reporting_period) === rp[0] && dcfIds.includes(i.dcfId)).map(i => i.response))
        return parseFloat(sum.toFixed(2))


    }
    const checkDataSources = (rps, submissions, submissionspy, split, approverFrequency, index, dcfIds, filter) => {
        console.log(filter)
        let filteredQnAssByYear = filterDataByTierAndLocation(filterAssignmentsByFiscalYear(JSON.parse(JSON.stringify(assignment)).filter(i => dcfIds.includes(i.dcfId)), filter.year), rawsitelist, filter.country, filter.city, filter.location)
        let AssCountByDcfIds = groupArrayByKeys(filteredQnAssByYear, ['dcfId', 'level', 'locationId'])
        let AssCountBySiteIds = groupArrayByKeys(filteredQnAssByYear, ['level', 'locationId'])

        console.log(AssCountByDcfIds, filteredQnAssByYear, filter)
        let maxCount = Math.max(...Object.values(AssCountByDcfIds).map(i => i.length))
        if (maxCount === -Infinity) {
            maxCount = 1

        }

        const values = { 3: 'Q', 1: 'Annually', 2: 'H' }
        let count = 0
        let dataAdded = []
        let prev = 0, current = 0
        let prevRps = getPreviousYear(rps)
        for (const rp of prevRps) {
            prev += getValue([rp], submissionspy, (filter.year - 1), dcfIds)
            console.log(getValue([rp], submissionspy, (filter.year - 1), dcfIds))

        }
        console.log(prev)
        for (const rp of rps) {
            current += getValue([rp], submissions, filter.year, dcfIds)
            console.log(rps)
            let filteredSubmisionRp = submissions.filter(i => getRPTextFormat(i.reporting_period) === rp).filter(i => dcfIds.includes(i.dcfId))
            if (filteredSubmisionRp.length) {
                dataAdded.push(...filteredSubmisionRp)
                count += filteredSubmisionRp.length
            }
        }
        console.log(Object.keys(AssCountBySiteIds).length, submissions, dcfIds.length, maxCount, dataAdded, split, (dataAdded.filter(i => i.type === 2 || i.type === 3).length === (Object.keys(AssCountBySiteIds).length * dcfIds.length * split)))
        console.log((Object.keys(AssCountBySiteIds).length * dcfIds.length * split), dataAdded.filter(i => i.type === 2 || i.type === 3).length)

        return { rp: formatDateRange(rps), status: !Object.keys(AssCountBySiteIds).length ? 3 : (dataAdded.filter(i => i.type === 3).length === (Object.keys(AssCountBySiteIds).length * dcfIds.length * split)) ? 1 : (dataAdded.filter(i => i.type === 2).length === (Object.keys(AssCountBySiteIds).length * dcfIds.length * split) || ((dataAdded.length === dataAdded.filter(i => i.type === 3 || i.type === 2).length) && dataAdded.filter(i => i.type === 3 || i.type === 2).length === (Object.keys(AssCountBySiteIds).length * dcfIds.length * split))) ? 2 : 3, performanceCommentary: [], direction: current <= prev, deviation: Math.abs(getPercentage(current, prev)), value: Math.abs(getPercentage(current, prev)).toFixed(2) + '%', name: approverFrequency === 1 ? values[approverFrequency] : values[approverFrequency] + (index + 1), disabled: (rps.length === 0 || dataAdded.length === 0 || dataAdded.filter(i => i.type === 3).length === (Object.keys(AssCountBySiteIds).length * dcfIds.length * split) || (dataAdded.every(i => i.type === 2) && dataAdded.filter(i => i.type === 2).length !== (Object.keys(AssCountBySiteIds).length * dcfIds.length * split))), checked: !Object.keys(AssCountBySiteIds).length ? false : (dataAdded.filter(i => i.type === 2 || i.type === 3).length === (Object.keys(AssCountBySiteIds).length * dcfIds.length * split)), new: !Object.keys(AssCountBySiteIds).length ? false : (dataAdded.filter(i => i.type === 2).length !== 0 && dataAdded.filter(i => i.type === 2 || i.type === 3).length === (Object.keys(AssCountBySiteIds).length * dcfIds.length * split)), approvedData: dataAdded.filter(i => i.type === 2 || i.type === 3), upcoming: rps.length === 0 }

        // return { rp: formatDateRange(rps), status: (dataAdded.filter(i => i.type === 3).length === (Object.keys(AssCountBySiteIds).length * dcfIds.length * split)) ? 1 : (dataAdded.filter(i => i.type === 2).length === (Object.keys(AssCountBySiteIds).length * dcfIds.length * split) || ((dataAdded.length === dataAdded.filter(i => i.type === 3 || i.type === 2).length) && dataAdded.filter(i => i.type === 3 || i.type === 2).length === (Object.keys(AssCountBySiteIds).length * dcfIds.length * split))) ? 2 : 3, performanceCommentary: [], direction: current <= prev, deviation: Math.abs(getPercentage(current, prev)), value: Math.abs(getPercentage(current, prev)).toFixed(2) + '%', name: approverFrequency === 1 ? values[approverFrequency] : values[approverFrequency] + (index + 1), disabled: (rps.length === 0 || dataAdded.length === 0 || dataAdded.filter(i => i.type === 3).length === (Object.keys(AssCountBySiteIds).length * dcfIds.length * split) || (dataAdded.every(i => i.type === 2) && dataAdded.filter(i => i.type === 2).length !== (Object.keys(AssCountBySiteIds).length * dcfIds.length * split))), checked: (dataAdded.filter(i => i.type === 2 || i.type === 3).length === (Object.keys(AssCountBySiteIds).length * dcfIds.length * split)), new: (dataAdded.filter(i => i.type === 2).length !== 0 && dataAdded.filter(i => i.type === 2 || i.type === 3).length === (Object.keys(AssCountBySiteIds).length * dcfIds.length * split)), approvedData: dataAdded.filter(i => i.type === 2 || i.type === 3), upcoming: rps.length === 0 }


    }
    const monthOrder = {
        "Jan": 1, "Feb": 2, "Mar": 3, "Apr": 4, "May": 5, "Jun": 6,
        "Jul": 7, "Aug": 8, "Sep": 9, "Oct": 10, "Nov": 11, "Dec": 12
    };

    function extractMonth(dateString) {
        if (dateString.includes(' to ')) {
            const [start, end] = dateString.split(' to ');
            const startMonth = start.split('-')[0];
            const endMonth = end.split('-')[0];
            return [startMonth, endMonth];
        } else {
            const month = dateString.split('-')[0];
            return [month, month];
        }
    }

    function formatDateRange(dates) {
        let startMonth = null;
        let endMonth = null;

        dates.forEach(dateString => {
            const [start, end] = extractMonth(dateString);

            if (!startMonth || !endMonth) {
                startMonth = start;
                endMonth = end;
            } else {
                // Compare month order instead of alphabetical order
                if (monthOrder[start] < monthOrder[startMonth]) startMonth = start;
                if (monthOrder[end] > monthOrder[endMonth]) endMonth = end;
            }
        });

        return startMonth === endMonth ? startMonth : `${startMonth} - ${endMonth}`;
    }

    const checkDataSourcesByDp = (rps, submissions, submissionspy, split, approverFrequency, index, dcfIds, dparray1, filter) => {
        let filteredQnAssByYear = filterDataByTierAndLocation(filterAssignmentsByFiscalYear(JSON.parse(JSON.stringify(assignment)).filter(i => dcfIds.includes(i.dcfId)), filter.year), rawsitelist, filter.country, filter.city, filter.location)
        let AssCountByDcfIds = groupArrayByKeys(filteredQnAssByYear, ['dcfId', 'level', 'locationId'])
        let AssCountBySiteIds = groupArrayByKeys(filteredQnAssByYear, ['level', 'locationId'])
        let maxCount = Math.max(...Object.values(AssCountByDcfIds).map(i => i.length))
        if (maxCount === -Infinity) {
            maxCount = 1
        }
        console.log(filter)
        const values = { 3: 'Q', 1: 'Annually', 2: 'H' }
        let count = 0
        let dataAdded = []
        let prev = 0, current = 0
        let prevRps = getPreviousYear(rps)
        for (const rp of prevRps) {
            prev += getSumValueByDp({ i: rp, filteredSubmissions: submissionspy, yr: filter.year, dcfIds, dparray1 })

        }
        for (const rp of rps) {
            current += getSumValueByDp({ i: rp, filteredSubmissions: submissions, yr: filter.year, dcfIds, dparray1 })
            console.log(submissions, rp)
            let filteredSubmisionRp = submissions.filter(i => dcfIds.includes(i.dcfId)).filter(i => getRPTextFormat(i.reporting_period) === rp)
            if (filteredSubmisionRp.length) {
                dataAdded.push(...filteredSubmisionRp)
                count += filteredSubmisionRp.length
            }
        }

        console.log(dataAdded, rps.length === 0, dataAdded.length === 0, dataAdded.filter(i => i.type === 3).length === (Object.keys(AssCountBySiteIds).length * dcfIds.length * split), ((rps.length === 0 || dataAdded.length === 0 || dataAdded.filter(i => i.type === 3).length === (Object.keys(AssCountBySiteIds).length * dcfIds.length * split) || (dataAdded.every(i => i.type === 2) && dataAdded.filter(i => i.type === 2).length !== (Object.keys(AssCountBySiteIds).length * dcfIds.length * split)))))
        // return { performanceCommentary: [], direction: current <= prev, value: Math.abs(getPercentage(current, prev)) + '%', name: split === 1 ? values[split] : values[split] + (index + 1), disabled: (rps.length === split && split === dataAdded.length && dataAdded.every(i => i.type === 3)), checked: (rps.length === split && split === dataAdded.length) && (dataAdded.every(i => i.type === 2) || dataAdded.every(i => i.type === 3)), upcoming: rps.length !== 0 ? !getDisplayStatus_rparray(rps) : true }
        // return { rp: formatDateRange(rps), status: (dataAdded.filter(i => i.type === 3).length === (Object.keys(AssCountBySiteIds).length * dcfIds.length * split)) ? 1 : (dataAdded.filter(i => i.type === 2).length === (Object.keys(AssCountBySiteIds).length * dcfIds.length * split)) ? 2 : 3, performanceCommentary: [], direction: current <= prev, value: Math.abs(getPercentage(current, prev)).toFixed(2) + '%', name: approverFrequency === 1 ? values[approverFrequency] : values[approverFrequency] + (index + 1), disabled: (rps.length === 0 || dataAdded.length === 0 || dataAdded.filter(i => i.type === 3).length === (Object.keys(AssCountBySiteIds).length * dcfIds.length * split)), checked: (dataAdded.filter(i => i.type === 3 || i.type === 2).length === (Object.keys(AssCountBySiteIds).length * dcfIds.length * split)), new: (dataAdded.filter(i => i.type === 2).length === (Object.keys(AssCountBySiteIds).length * dcfIds.length * split)), approvedData: dataAdded.filter(i => i.type === 2), upcoming: rps.length === 0 }
        // return { rp: formatDateRange(rps), status: !Object.keys(AssCountBySiteIds).length ? 3 : (dataAdded.filter(i => i.type === 3).length === (Object.keys(AssCountBySiteIds).length * dcfIds.length * split)) ? 1 : (dataAdded.filter(i => i.type === 2).length === (Object.keys(AssCountBySiteIds).length * dcfIds.length * split) || ((dataAdded.length === dataAdded.filter(i => i.type === 3 || i.type === 2).length) && dataAdded.filter(i => i.type === 3 || i.type === 2).length === (Object.keys(AssCountBySiteIds).length * dcfIds.length * split))) ? 2 : 3, performanceCommentary: [], direction: current <= prev, deviation: Math.abs(getPercentage(current, prev)), value: Math.abs(getPercentage(current, prev)).toFixed(2) + '%', name: approverFrequency === 1 ? values[approverFrequency] : values[approverFrequency] + (index + 1), disabled: (rps.length === 0 || dataAdded.length === 0 || dataAdded.filter(i => i.type === 3).length === (Object.keys(AssCountBySiteIds).length * dcfIds.length * split) || (dataAdded.every(i => i.type === 2) && dataAdded.filter(i => i.type === 2).length !== (Object.keys(AssCountBySiteIds).length * dcfIds.length * split))), checked: !Object.keys(AssCountBySiteIds).length ? false : (dataAdded.filter(i => i.type === 2 || i.type === 3).length === (Object.keys(AssCountBySiteIds).length * dcfIds.length * split)), new: !Object.keys(AssCountBySiteIds).length ? false : (dataAdded.filter(i => i.type === 2).length !== 0 && dataAdded.filter(i => i.type === 2 || i.type === 3).length === (Object.keys(AssCountBySiteIds).length * dcfIds.length * split)), approvedData: dataAdded.filter(i => i.type === 2 || i.type === 3), upcoming: rps.length === 0 }
        return { rp: formatDateRange(rps), status: !Object.keys(AssCountBySiteIds).length ? 3 : (dataAdded.filter(i => i.type === 3).length === (Object.keys(AssCountBySiteIds).length * dcfIds.length * split)) ? 1 : (dataAdded.filter(i => i.type === 2).length === (Object.keys(AssCountBySiteIds).length * dcfIds.length * split) || ((dataAdded.length === dataAdded.filter(i => i.type === 3 || i.type === 2).length) && dataAdded.filter(i => i.type === 3 || i.type === 2).length === (Object.keys(AssCountBySiteIds).length * dcfIds.length * split))) ? 2 : 3, performanceCommentary: [], direction: current <= prev, deviation: Math.abs(getPercentage(current, prev)), value: Math.abs(getPercentage(current, prev)).toFixed(2) + '%', name: approverFrequency === 1 ? values[approverFrequency] : values[approverFrequency] + (index + 1), disabled: (rps.length === 0 || dataAdded.length === 0 || dataAdded.filter(i => i.type === 3).length === (Object.keys(AssCountBySiteIds).length * dcfIds.length * split) || (dataAdded.every(i => i.type === 2) && dataAdded.filter(i => i.type === 2).length !== (Object.keys(AssCountBySiteIds).length * dcfIds.length * split))), checked: !Object.keys(AssCountBySiteIds).length ? false : (dataAdded.filter(i => i.type === 2 || i.type === 3).length === (Object.keys(AssCountBySiteIds).length * dcfIds.length * split)), new: !Object.keys(AssCountBySiteIds).length ? false : (dataAdded.filter(i => i.type === 2).length !== 0 && dataAdded.filter(i => i.type === 2 || i.type === 3).length === (Object.keys(AssCountBySiteIds).length * dcfIds.length * split)), approvedData: dataAdded.filter(i => i.type === 2 || i.type === 3), upcoming: rps.length === 0 }




    }
    const getEmissionFactorCalculation = (item, year) => {
        let betweenMonths = getBetweenMonthsByYear(year)
        console.log(item)
        let month_data = []
        if (item.dcfId === 304) {

            const { match, dates } = isDateWithinPeriod(item.reporting_period, betweenMonths)
            if (match) {

                const standard_index = apief.findIndex((i) => { return i.id === item.standard })
                console.log('Matched_SC', standard_index, item, apief)
                if (standard_index !== -1) {
                    item.response.forEach((sc) => {
                        if (standard_index !== -1 && apief[standard_index].newEfDates !== undefined && apief[standard_index].newEfDates.length !== 0) {
                            console.log('Dates_SC')
                            dates.forEach((rpm) => {
                                let date_index = findIndexByDate(apief[standard_index].newEfDates, rpm)

                                if (date_index !== -1 && apief[standard_index].newEfDates[date_index].newEfs !== undefined && apief[standard_index].newEfDates[date_index].newEfs.length !== 0) {
                                    let cat_index = apief[standard_index].newEfDates[date_index].newEfs.findIndex((i) => { return i.category === 1 })
                                    console.log(apief[standard_index].newEfDates[date_index].newEfs[cat_index])
                                    if (cat_index !== -1 && apief[standard_index].newEfDates[date_index].newEfs[cat_index].newEfItems !== undefined && apief[standard_index].newEfDates[date_index].newEfs[cat_index].newEfItems.length !== 0) {
                                        let sc_index = apief[standard_index].newEfDates[date_index].newEfs[cat_index].newEfItems.findIndex(i => { return i.subcategory1 === sc.DPA0130 && i.subcategory2 === sc.DPA0131 && i.subcategory3 === sc.DPA0132 })

                                        if (sc_index !== -1) {
                                            let ef = apief[standard_index].newEfDates[date_index].newEfs[cat_index].newEfItems[sc_index]
                                            let ghg = parseFloat((((parseFloat(sc.DPA0336) * ef.co2e) / dates.length) / 1000).toFixed(3))
                                            console.log(sc.DPA0336, ef)
                                            let fuel_type_index = subcat.two.findIndex((i) => { return i.id === sc.DPA0131 })
                                            let unit_index = subcat.three.findIndex((i) => { return i.id === sc.DPA0132 })
                                            let fuel_cat_index = subcat.one.findIndex((i) => { return i.id === sc.DPA0130 })

                                            let fuel_type = 'Other', usage = 0, r = 0, nr = 0
                                            if (fuel_type_index !== -1) {
                                                fuel_type = subcat.two[fuel_type_index].title
                                            }
                                            if (unit_index !== -1) {
                                                let unit_type = subcat.three[fuel_type_index].title

                                                if (unit_type.includes('ton')) {
                                                    if (fuel_cat_index !== -1) {
                                                        let cat_type = subcat.one[fuel_cat_index].title

                                                        if (cat_type.trim().toLowerCase().includes('bio')) {
                                                            r = parseFloat((((parseFloat(sc.DPA0336) * 0.82) * 10800 * 4.187) / 1000000000).toFixed(3))
                                                        } else {

                                                            nr = parseFloat((((parseFloat(sc.DPA0336) * 0.82) * 10800 * 4.187) / 1000000000).toFixed(3))

                                                        }
                                                    }
                                                    usage = parseFloat((sc.DPA0336 / 0.81).toFixed(3))

                                                } else {
                                                    if (fuel_cat_index !== -1) {

                                                        let cat_type = subcat.one[fuel_cat_index].title
                                                        if (cat_type.trim().toLowerCase().includes('bio')) {
                                                            r = parseFloat((((parseFloat(sc.DPA0336) * 0.82) * 10.8 * 4.187) / 1000000000).toFixed(3))
                                                        } else {

                                                            nr = parseFloat((((parseFloat(sc.DPA0336) * 0.82) * 10.8 * 4.187) / 1000000000).toFixed(3))
                                                        }
                                                    }
                                                    usage = parseFloat(sc.DPA0336)
                                                }
                                            }
                                            let month_index = month_data.findIndex((i) => { return i.month === rpm && i.scope === 'scope1' && i.fuel_type === fuel_type })
                                            if (month_index === -1) {
                                                month_data.push({ year: rpm.split('-')[1], month: rpm, ef, dcfId: item.dcfId, scope: 'scope1', ghg, fuel_type, usage, r, nr })
                                            } else {
                                                month_data[month_index].ghg += ghg
                                                month_data[month_index].usage += usage
                                                month_data[month_index].r += r
                                                month_data[month_index].nr += nr
                                            }

                                        }

                                    }
                                }

                            })
                        }
                    })
                }
            }

        } else if (item.dcfId === 305) {

            const { match, dates } = isDateWithinPeriod(item.reporting_period, betweenMonths)
            if (match) {
                const standard_index = apief.findIndex((i) => { return i.id === item.standard })
                if (standard_index !== -1) {
                    item.response.forEach((sc) => {
                        if (standard_index !== -1 && apief[standard_index].newEfDates !== undefined && apief[standard_index].newEfDates.length !== 0) {
                            dates.forEach((rpm) => {
                                let date_index = findIndexByDate(apief[standard_index].newEfDates, rpm)
                                console.log(date_index, apief[standard_index].newEfDates[date_index].newEfs)
                                if (date_index !== -1 && apief[standard_index].newEfDates[date_index].newEfs !== undefined && apief[standard_index].newEfDates[date_index].newEfs.length !== 0) {
                                    let cat_index = apief[standard_index].newEfDates[date_index].newEfs.findIndex((i) => { return i.category === 5 })
                                    console.log(apief[standard_index].newEfDates[date_index].newEfs[cat_index])
                                    if (cat_index !== -1 && apief[standard_index].newEfDates[date_index].newEfs[cat_index].newEfItems !== undefined && apief[standard_index].newEfDates[date_index].newEfs[cat_index].newEfItems.length !== 0) {
                                        let sc_index = apief[standard_index].newEfDates[date_index].newEfs[cat_index].newEfItems.findIndex(i => { return i.subcategory1 === sc.DPA0136 })
                                        console.log(sc_index, sc.DPA0136, apief[standard_index].newEfDates[date_index].newEfs[cat_index].newEfItems)
                                        if (sc_index !== -1) {
                                            console.log(apief[standard_index].newEfDates[date_index].newEfs[cat_index].newEfItems[sc_index], sc)
                                            let ef = apief[standard_index].newEfDates[date_index].newEfs[cat_index].newEfItems[sc_index]
                                            let ghg = parseFloat((((parseFloat(sc.DPA0138) * ef.co2e) / dates.length) / 1000).toFixed(3))
                                            console.log(sc.DPA0138, ef)
                                            let gas_type_index = subcat.one.findIndex((i) => { return i.id === sc.DPA0136 })
                                            let gas_type = 'Other'
                                            if (gas_type_index !== -1) {
                                                gas_type = subcat.one[gas_type_index].title
                                            }
                                            let month_index = month_data.findIndex((i) => { return i.month === rpm && i.scope === 'scope1' && i.gas_type === gas_type })
                                            if (month_index === -1) {
                                                month_data.push({ year: rpm.split('-')[1], month: rpm, dcfId: item.dcfId, ef, scope: 'scope1', ghg, gas_type, usage: parseFloat(sc.DPA0138) })
                                            } else {
                                                month_data[month_index].ghg += ghg
                                                month_data[month_index].usage += parseFloat(sc.DPA0138)
                                            }

                                        }

                                    }
                                }

                            })
                        }
                    })
                }
            }

        } else if (item.dcfId === 16) {

            const { match, dates } = isDateWithinPeriod(item.reporting_period, betweenMonths)
            if (match) {
                const standard_index = apief.findIndex((i) => { return i.id === item.standard })
                if (standard_index !== -1) {
                    item.response.forEach((sc) => {
                        if (standard_index !== -1 && apief[standard_index].newEfDates !== undefined && apief[standard_index].newEfDates.length !== 0) {
                            dates.forEach((rpm) => {
                                let date_index = findIndexByDate(apief[standard_index].newEfDates, rpm)

                                if (date_index !== -1 && apief[standard_index].newEfDates[date_index].newEfs !== undefined && apief[standard_index].newEfDates[date_index].newEfs.length !== 0) {
                                    let cat_index = apief[standard_index].newEfDates[date_index].newEfs.findIndex((i) => { return i.category === 11 })
                                    console.log(apief[standard_index].newEfDates[date_index].newEfs[cat_index])
                                    if (cat_index !== -1 && apief[standard_index].newEfDates[date_index].newEfs[cat_index].newEfItems !== undefined && apief[standard_index].newEfDates[date_index].newEfs[cat_index].newEfItems.length !== 0) {
                                        let sc_index = apief[standard_index].newEfDates[date_index].newEfs[cat_index].newEfItems.findIndex(i => { return i.subcategory1 === sc.DPA0287 })

                                        if (sc_index !== -1) {
                                            let ef = apief[standard_index].newEfDates[date_index].newEfs[cat_index].newEfItems[sc_index]
                                            let ghg = parseFloat((((parseFloat(sc.DPA0288) * parseFloat(sc.DPA0289) * ef.co2e) / dates.length) / 1000).toFixed(3))

                                            let item_class_index = subcat.one.findIndex((i) => { return i.id === sc.DPA0287 })
                                            let item_class = 'Other'
                                            if (item_class_index !== -1) {
                                                item_class = subcat.one[item_class_index].title
                                            }
                                            let month_index = month_data.findIndex((i) => { return i.month === rpm && i.scope === 'scope1' && i.item_class === item_class })
                                            if (month_index === -1) {
                                                month_data.push({ year: rpm.split('-')[1], month: rpm, ef, scope: 'scope1', ghg, item_class })
                                            } else {
                                                month_data[month_index].ghg += ghg
                                            }

                                        }

                                    }
                                }

                            })
                        }
                    })
                }
            }

        } else if (item.dcfId === 15) {

            const { match, dates } = isDateWithinPeriod(item.reporting_period, betweenMonths)
            if (match) {
                const standard_index = apief.findIndex((i) => { return i.id === item.standard })
                if (standard_index !== -1) {
                    item.response.forEach((sc) => {
                        if (standard_index !== -1 && apief[standard_index].newEfDates !== undefined && apief[standard_index].newEfDates.length !== 0) {
                            dates.forEach((rpm) => {
                                let date_index = findIndexByDate(apief[standard_index].newEfDates, rpm)

                                if (date_index !== -1 && apief[standard_index].newEfDates[date_index].newEfs !== undefined && apief[standard_index].newEfDates[date_index].newEfs.length !== 0) {
                                    if (!sc.DP_MODE) {
                                        let cat_index = apief[standard_index].newEfDates[date_index].newEfs.findIndex((i) => { return i.category === 1 })
                                        console.log(apief[standard_index].newEfDates[date_index].newEfs[cat_index])
                                        if (cat_index !== -1 && apief[standard_index].newEfDates[date_index].newEfs[cat_index].newEfItems !== undefined && apief[standard_index].newEfDates[date_index].newEfs[cat_index].newEfItems.length !== 0) {
                                            let sc_index = -1
                                            if (sc.DPA0141 === 2) {
                                                sc_index = apief[standard_index].newEfDates[date_index].newEfs[cat_index].newEfItems.findIndex(i => { return i.subcategory1 === 1 && i.subcategory2 === sc.DPA0141 && i.subcategory3 === 7 })
                                            } else if (sc.DPA0141 === 13) {
                                                sc_index = apief[standard_index].newEfDates[date_index].newEfs[cat_index].newEfItems.findIndex(i => { return i.subcategory1 === 4 && i.subcategory2 === sc.DPA0141 && i.subcategory3 === 51 })
                                            } else if (sc.DPA0141 === 19) {
                                                sc_index = apief[standard_index].newEfDates[date_index].newEfs[cat_index].newEfItems.findIndex(i => { return i.subcategory1 === 4 && i.subcategory2 === sc.DPA0141 && i.subcategory3 === 75 })

                                            }
                                            if (sc_index !== -1) {
                                                let ef = apief[standard_index].newEfDates[date_index].newEfs[cat_index].newEfItems[sc_index]
                                                let ghg = parseFloat((((parseFloat(sc.DPA0143) * ef.co2e) / dates.length) / 1000).toFixed(3))

                                                let fuel_type_index = subcat.two.findIndex((i) => { return i.id === sc.DPA0141 })
                                                let fuel_type = 'Other'
                                                if (fuel_type_index !== -1) {
                                                    fuel_type = subcat.two[fuel_type_index].title
                                                }
                                                let month_index = month_data.findIndex((i) => { return i.month === rpm && i.scope === 'scope1' && i.type === 'byFuel' && i.fuel_type === fuel_type })
                                                if (month_index === -1) {
                                                    month_data.push({ year: rpm.split('-')[1], month: rpm, ef, scope: 'scope1', ghg, fuel_type, type: 'byFuel' })
                                                } else {
                                                    month_data[month_index].ghg += ghg
                                                }

                                            }

                                        }
                                    } else {
                                        let cat_index = apief[standard_index].newEfDates[date_index].newEfs.findIndex((i) => { return i.category === sc.DPGMode })
                                        console.log(apief[standard_index].newEfDates[date_index].newEfs[cat_index])
                                        if (cat_index !== -1 && apief[standard_index].newEfDates[date_index].newEfs[cat_index].newEfItems !== undefined && apief[standard_index].newEfDates[date_index].newEfs[cat_index].newEfItems.length !== 0) {
                                            let sc_index = apief[standard_index].newEfDates[date_index].newEfs[cat_index].newEfItems.findIndex(i => { return i.subcategory1 === sc.DPA0140 && i.subcategory2 === sc.DPA0139 && i.subcategory3 === sc.DPA0141 && i.subcategory4 === sc.DPA0339 })

                                            if (sc_index !== -1) {
                                                let ef = apief[standard_index].newEfDates[date_index].newEfs[cat_index].newEfItems[sc_index]
                                                let ghg = parseFloat((((parseFloat(sc.DPA0144) * ef.co2e) / dates.length) / 1000).toFixed(3))

                                                let fuel_type_index = subcat.two.findIndex((i) => { return i.id === sc.DPA0139 })
                                                let fuel_type = 'Other'
                                                if (fuel_type_index !== -1) {
                                                    fuel_type = subcat.two[fuel_type_index].title
                                                }
                                                let month_index = month_data.findIndex((i) => { return i.month === rpm && i.scope === 'scope1' && i.fuel_type === fuel_type && i.type === 'byDistance' })
                                                if (month_index === -1) {
                                                    month_data.push({ year: rpm.split('-')[1], month: rpm, ef, scope: 'scope1', ghg, fuel_type, type: 'byDistance' })
                                                } else {
                                                    month_data[month_index].ghg += ghg
                                                }

                                            }

                                        }
                                    }

                                }

                            })
                        }
                    })
                }
            }

        } else if (item.dcfId === 287) {

            const { match, dates } = isDateWithinPeriod(item.reporting_period, betweenMonths)
            console.log(match, dates)
            if (match) {
                const standard_index = apief.findIndex((i) => { return i.id === 27 })
                if (standard_index !== -1) {
                    item.response.forEach((sc) => {

                        if (standard_index !== -1 && apief[standard_index].newEfDates !== undefined && apief[standard_index].newEfDates.length !== 0) {
                            dates.forEach((rpm) => {
                                let date_index = findIndexByDate(apief[standard_index].newEfDates, rpm)
                                console.log(sc, item.id)
                                if (date_index !== -1 && apief[standard_index].newEfDates[date_index].newEfs !== undefined && apief[standard_index].newEfDates[date_index].newEfs.length !== 0) {
                                    let cat_index = apief[standard_index].newEfDates[date_index].newEfs.findIndex((i, j) => { return i.category === 49 })
                                    console.log(apief[standard_index].newEfDates[date_index].newEfs)
                                    if (cat_index !== -1 && apief[standard_index].newEfDates[date_index].newEfs[cat_index].newEfItems !== undefined && apief[standard_index].newEfDates[date_index].newEfs[cat_index].newEfItems.length !== 0) {
                                        let sc_index = apief[standard_index].newEfDates[date_index].newEfs[cat_index].newEfItems.findIndex(i => { return i.subcategory1 === sc.DPAN095 })
                                        console.log(sc_index, apief[standard_index].newEfDates[date_index].newEfs[cat_index].newEfItems, sc)
                                        if (sc_index !== -1 && sc.DPAN095 === 335) {
                                            let ef = apief[standard_index].newEfDates[date_index].newEfs[cat_index].newEfItems[sc_index]
                                            let ghg = 0


                                            let type = 'nonrenewable', r = 0, nr = 0
                                            ghg = parseFloat((((parseFloat(sc.DPAN098A) * ef.co2e) / dates.length) / 1000).toFixed(3))
                                            nr = parseFloat(((parseFloat(sc.DPAN098A) / dates.length) / 1000).toFixed(3))


                                            let month_index = month_data.findIndex((i) => { return i.month === rpm && i.scope === 2 && i.source_type === 'Grid Import' && i.type === type })
                                            if (month_index === -1) {
                                                month_data.push({ year: rpm.split('-')[1], month: rpm, ef, scope: 2, ghg, type, source_type: 'Grid Import', r: 0, nr })
                                            } else {
                                                month_data[month_index].ghg += ghg

                                                month_data[month_index].nr += nr
                                            }

                                        } else {
                                            let nr = 0, type = 'renewable'
                                            let r = parseFloat(((parseFloat(sc.DPAN098B) / dates.length) / 1000).toFixed(3))


                                            let month_index = month_data.findIndex((i) => { return i.month === rpm && i.scope === 2 && i.source_type === 'Grid Import' && i.type === type })
                                            if (month_index === -1) {
                                                month_data.push({ year: rpm.split('-')[1], month: rpm, ef: null, scope: 2, ghg: 0, type, source_type: 'Grid Import', nr: 0, r })
                                            } else {


                                                month_data[month_index].r += r
                                            }
                                        }

                                    }
                                }

                            })
                        }
                    })
                }
            }

        }
        console.log(month_data, item.dcfId)
        if (item.dcfId === 287) {
            console.log(month_data)
        }
        return month_data
    }
    const isDateWithinPeriod = (rp, period) => {
        // Convert date strings to Luxon DateTime objects
        const rpDates = rp.map(dateString => DateTime.fromFormat(dateString, 'MM-yyyy'));
        const periodDates = period.map(dateString => DateTime.fromFormat(dateString, 'MM-yyyy'));

        // Find the matching dates in rp within the period
        const matchingDates = rpDates.filter(rpDate =>
            periodDates.some(periodDate => rpDate.toMillis() === periodDate.toMillis())
        );

        return matchingDates.length > 0 ? { match: true, dates: matchingDates.map(date => date.toFormat('MM-yyyy')) } : { match: false, dates: null };
    };
    const findIndexByDate_ = (array, dateToFind) => {
        const targetDate = DateTime.fromFormat(dateToFind, 'MM-yyyy');
        console.log(array, dateToFind, targetDate)
        if (array.length === 1) {
            return 0; // If array length is 1, return index 0
        }

        let foundIndex = -1;

        // Check for an object with both start and end not null and matching the passed date
        const matchedObject = array.find(obj => {
            const startDate = DateTime.fromISO(obj.start, { zone: 'utc' });
            const endDate = DateTime.fromISO(obj.end, { zone: 'utc' });

            return startDate <= targetDate && targetDate <= endDate;
        });

        if (matchedObject) {
            return array.indexOf(matchedObject);
        }

        // Check for an object with end as null and start date lesser than passed date
        const endIsNullObject = array.find(obj => {
            const startDate = DateTime.fromISO(obj.start, { zone: 'utc' });
            return obj.end === null && startDate <= targetDate;
        });

        if (endIsNullObject) {
            return array.indexOf(endIsNullObject);
        }

        // If none of the above conditions match, find the object with the lesser start date
        let lesserStartDateObject = array.reduce((prev, curr) => {
            const prevStartDate = DateTime.fromISO(prev.start, { zone: 'utc' });
            const currStartDate = DateTime.fromISO(curr.start, { zone: 'utc' });
            return prevStartDate < currStartDate ? prev : curr;
        });

        return array.indexOf(lesserStartDateObject);
    };
    const findIndexByDate = (array, dateToFind) => {
        const targetDate = DateTime.fromFormat(dateToFind, 'MM-yyyy', { zone: 'utc' }).endOf('month');

        if (array.length === 1) {
            return 0; // If array length is 1, return index 0
        }

        // Check for an object with both start and end not null and matching the passed date
        const matchedObject = array.find(obj => {
            const startDate = DateTime.fromISO(obj.start, { zone: 'utc' }).startOf('month');
            const endDate = obj.end ? DateTime.fromISO(obj.end, { zone: 'utc' }).endOf('month') : null;

            return startDate <= targetDate && (!endDate || targetDate <= endDate);
        });

        if (matchedObject) {
            return array.indexOf(matchedObject);
        }

        // Check for an object with end as null and start date less than or equal to passed date
        const endIsNullObject = array.find(obj => {
            const startDate = DateTime.fromISO(obj.start, { zone: 'utc' }).startOf('month');
            return obj.end === null && startDate <= targetDate;
        });

        if (endIsNullObject) {
            return array.indexOf(endIsNullObject);
        }

        // If none of the above conditions match, find the object with the lesser start date
        const lesserStartDateObject = array.reduce((prev, curr) => {
            const prevStartDate = DateTime.fromISO(prev.start, { zone: 'utc' }).startOf('month');
            const currStartDate = DateTime.fromISO(curr.start, { zone: 'utc' }).startOf('month');
            return prevStartDate < currStartDate ? prev : curr;
        });

        return array.indexOf(lesserStartDateObject);
    };

    function groupByFrequency(array, frequency) {
        const result = [];
        for (let i = 0; i < array.length; i += frequency) {
            result.push(array.slice(i, i + frequency));
        }
        return result;
    }
    function getFiscalYearsFromStartDate(start_date) {
        console.log(start_date);
        const startDate = DateTime.fromISO(start_date, { zone: 'utc' }).plus({ months: 1 });
        const currentDate = DateTime.now();

        let startFiscalYear, currentFiscalYear;
        const fiscalYears = [];

        if (fymonth === 1) {
            // When fiscal month is January, it's a single year
            startFiscalYear = startDate.year;
            currentFiscalYear = currentDate.year;

            for (let year = startFiscalYear; year <= currentFiscalYear; year++) {
                fiscalYears.push({ name: year, label: `${year}` });
            }
        } else {
            // Normal fiscal year spanning two calendar years
            startFiscalYear = startDate.month >= fymonth ? startDate.year : startDate.year - 1;
            currentFiscalYear = currentDate.month >= fymonth ? currentDate.year : currentDate.year - 1;

            for (let year = startFiscalYear; year <= currentFiscalYear; year++) {
                const label = `${year}-${(year + 1).toString().slice(-2)}`;
                fiscalYears.push({ name: year + 1, label });
            }

            // Include the current fiscal year only if the current month is before the fiscal year start month
            if (currentDate.month < fymonth) {
                fiscalYears.pop();
            }
        }

        return fiscalYears;
    }
    const getRPTextFormat = (item) => {
        if (item.length !== 0) {
            if (item.length >= 2) {
                const startDate = DateTime.fromFormat(item[0], "MM-yyyy").toFormat(
                    "LLL-yyyy"
                );
                const endDate = DateTime.fromFormat(
                    item[item.length - 1],
                    "MM-yyyy"
                ).toFormat("LLL-yyyy");
                return `${startDate} to ${endDate}`;
            } else {
                return DateTime.fromFormat(item[0], "MM-yyyy").toFormat("LLL-yyyy");
            }
        }
    };
    const formTemplate = (rowData) => {
        let txt = 'Not Found'
        let find = dcflist.find(i => i.id === rowData.dcfId)
        if (find) {
            txt = find.title
        }

        return <>
            <div className='clr-navy fw-6 fs-14 cur-pointer text-three-dot text-underline' onClick={() => { window.open(window.origin + '/data_input_view/' + rowData.dcfId + '/' + rowData.id) }} >{txt} </div>
        </>
    }
    const returnToReporter = async (obj) => {
        console.log(obj)
        let { value: return_remarks } = await Swal.fire({
            title: `<div style="overflow:visible;font-size:20px;font-weight:600;margin-top:0px">Alert</div>`,
            html: `<div style="overflow:auto;max-height:200px" >Please enter reason for returning to reporter on this parameter(s)</div>`,
            input: 'textarea',
            inputValue: '',
            allowOutsideClick: false,
            showCancelButton: true,
            inputValidator: (value) => {
                if (!value.trim()) {
                    return 'Enter Remarks'
                }
            }
        })

        if (return_remarks.trim()) {
            let newObj = {}
            let dt = DateTime.utc()
            newObj['type'] = 0
            newObj['reject'] = 1
            newObj['last_modified_on'] = dt
            newObj['last_modified_by'] = login_data.id
            newObj['logs'] = [{ user_id: login_data.id, user_type: 3, type: 1, category: 2, created_on: dt, formId: obj.dcfId, remarks: return_remarks }, ...obj.logs]
            if (obj.return_remarks === null) {
                newObj['return_remarks'] = [{ remarks: return_remarks, user_type: 3, type: 1, user_id: login_data.id, created_on: dt }]

            } else {
                let lt = obj.return_remarks
                lt.push({ remarks: return_remarks, user_type: 3, type: 1, user_id: login_data.id, created_on: dt })
                newObj['return_remarks'] = lt
            }
            APIServices.patch(API.QN_Submission_Edit(obj.id), newObj).then((res) => {
                let loc1 = JSON.parse(JSON.stringify(quantitativesubmission))
                let loc2 = JSON.parse(JSON.stringify(overallQuantitativeSubmission))
                let loc3 = JSON.parse(JSON.stringify(sourcelist))

                let index1 = loc1.findIndex(i => i.id === obj.id)
                let index2 = loc2.findIndex(i => i.id === obj.id)
                let index3 = loc3.findIndex(i => (i.data && (i.data.id === obj.id)))
                console.log(index3,)
                if (index1 !== -1) {
                    loc1[index1] = { ...loc1[index1], ...newObj }


                    setQuantitativeSubmission(loc1)
                }
                if (index3 !== -1) {
                    console.log(loc3[index3])
                    loc3[index3].data = { ...loc3[index3].data, ...newObj }
                    loc3[index3].status = 7

                    setSourceList(loc3)
                }
                if (index1 !== -1) {
                    loc2[index2] = { ...loc1[index2], ...newObj }
                    setOverallQuantitativeSubmission(loc2)
                }
                renderIndicatorValues(filter, loc1, loc2)
                Swal.fire({
                    title: "Data Submission Sent Back To Reporter For Correction",

                    confirmButtonText: 'Exit',
                    allowOutsideClick: false,
                })
            }).catch((e) => {
                console.log(e)
                Swal.fire({
                    title: "Something went wrong, try after some time. Contact admin if issue still persist",

                    confirmButtonText: 'Exit',
                    allowOutsideClick: false,
                })

            })

        }
    }
    const actionTemplate = (rowData) => {

        if (rowData.type === 2) {
            return (
                <div className=' fw-6 fs-14 cur-pointer  text-three-dot text-underline' onClick={() => { returnToReporter(rowData) }} >Reject </div>
            )
        }

    }
    const formTemplate_ = (rowData) => {
        let txt = 'Not Found'
        let find = dcflist.find(i => i.id === rowData.dcfId)
        if (find) {
            txt = find.title
        }

        return <>
            <div className=' fw-6 fs-14  text-three-dot '>{txt} </div>
        </>
    }
    const responsibilityTemplate = (rowData) => {
        let text = []
        if (rowData.status === 6 || rowData.status === 7 || rowData.status === 1) {
            text = rowData.reporters
        } else if (rowData.status === 3) {
            text = rowData.reviewers
        } else if (rowData.status === 4) {
            text = rowData.approvers
        }
        return <div><ToolTip target={'.reviewer'} position="top" />  <div className="text-three-dot reviewer" data-pr-tooltip={text.join(",")} style={{ maxWidth: 150 }}>{text.join(',')}</div></div>


    }
    const statusTemplate = (rowData) => {
        let txt = 'Not Found'
        if (rowData.status === 6) {
            txt = 'Submissions Due'
        } else if (rowData.status === 7) {
            txt = 'Submissions Overdue'
        } else if (rowData.status === 1) {
            txt = 'Resubmission Required'
        } else if (rowData.status === 3) {
            txt = 'Under Review'
        } else if (rowData.status === 4) {
            txt = 'Under Approval'
        } else if (rowData.status === 5) {
            txt = 'Approved'
        }
        return <>{txt}</>
    }
    const coverageTemplate = (rowData) => {
        let txt = 'Not Found'
        let find = getCoverageText(rowData)
        if (find) {
            txt = find
        }

        return <>
            <div >{txt} </div>
        </>
    }
    const rpTemplate = (rowData) => {


        return <>
            <div >{getRPTextFormat(rowData.reporting_period)} </div>
        </>
    }

    const updateCheckboxValue = (checked, topindex, index) => {
        let loc = JSON.parse(JSON.stringify(indicator))
        loc[topindex].labels[index].checked = checked
        setindicator(loc)
        forceUpdate()
    }
    const updateNotes = (ind) => {
        let loc = JSON.parse(JSON.stringify(indicator))
        let loc2 = JSON.parse(JSON.stringify(approvedindicator))


        if (note.trim().length) {
            loc[selectedIndicator].performanceCommentary[ind] = [{ comment: note, user_id: login_data.id, created_on: DateTime.utc() }, ...loc[selectedIndicator].performanceCommentary[ind]]

            let index = approvedindicator.findIndex(i => i.year === filter.year && i.indicatorId === loc[selectedIndicator].indicatorId && i.tier1_id === filter.country && i.tier2_id === 0 && i.tier3_id === null)
            if (!loc[selectedIndicator].id) {
                console.log({ year: filter.year, performanceCommentary: loc[selectedIndicator].performanceCommentary, created_on: DateTime.utc() })
                APIServices.post(API.QNIndicatorApprove_UP(admin_data.id), { tier1_id: filter.country, tier2_id: 0, tier3_id: null, year: filter.year, performanceCommentary: loc[selectedIndicator].performanceCommentary, indicatorId: loc[selectedIndicator].indicatorId, created_on: DateTime.utc() }).then((res) => {
                    setApprovedIndicator((prev) => ([...prev, res.data]))
                    loc[selectedIndicator].id = res.data.id
                    setindicator(loc)
                    setCommentList(loc[selectedIndicator].performanceCommentary)
                })
            } else {
                APIServices.patch(API.QNIndicatorApprove_Edit(loc[selectedIndicator].id), { performanceCommentary: loc[selectedIndicator].performanceCommentary, modified_on: DateTime.utc() }).then((res) => {
                    loc2[index].performanceCommentary = loc[selectedIndicator].performanceCommentary
                    setApprovedIndicator(loc2)
                    setindicator(loc)
                    setCommentList(loc[selectedIndicator].performanceCommentary)
                })
            }



            setNote('')
            forceUpdate()
        }


    }
    const showStackedBarInGroupChartWithoutDrill = (chartdata, id, custom, colorind) => {
        // Destroy existing chart instance if it exists
        const existingChart = Chart.getChart(id);
        if (existingChart) {
            existingChart.destroy();
        }

        // Get the context of the canvas element
        const scope1 = document.getElementById(id).getContext("2d");

        // Tooltip customization
        const toolTipTitleRender = () => "";
        const toolTipLabelRender = (items, a, b) => {
            console.log(items, a, b)
            return items.dataset.label + " - " + items.formattedValue + custom.measure;
        };

        // Custom plugin to add additional elements to the chart
        const DATA_COUNT = 7;


        const labels = custom.label.map(i => i.split('-')[0]);
        const data = {
            labels: labels,
            datasets: chartdata
        };

        // Chart configuration
        const scope1c = new Chart(scope1, {
            type: 'bar',
            data: data,
            options: {

                plugins: {
                    title: {
                        display: false,
                        text: 'Chart.js Bar Chart - Stacked'
                    }, legend: {
                        labels: {
                            // Filter function to show only Dataset 1 and Dataset 3 in the legend
                            generateLabels: function (chart) {
                                // Generate legend labels based on the customLegendLabels array
                                return custom.legend.map((label, index) => ({
                                    text: label,
                                    fillStyle: stackBar[index], // Default color for the legend item
                                    // strokeStyle: 'rgba(75, 192, 192, 1)', // Default border color
                                    lineWidth: 1 // Default border width
                                }));
                            }
                        },
                        onClick: function (e, legendItem, legend) {
                            console.log(e, legendItem, legend);
                        }
                    },
                    tooltip: {
                        mode: 'nearest', // Ensures the tooltip is displayed for the nearest element
                        intersect: true,

                        yAlign: "bottom",
                        displayColor: false,
                        callbacks: {
                            title: toolTipTitleRender,
                            label: toolTipLabelRender,
                        },
                    },
                },

                responsive: true,
                interaction: {
                    intersect: false,
                },
                scales: {
                    x: {
                        stacked: true,
                    },
                    y: {
                        title: {
                            display: true, // Show the title
                            text: custom.measure, // Text for the title
                            color: '#666', // Optional: color of the title
                            font: {
                                family: 'Arial', // Optional: font family
                                size: 16, // Optional: font size
                                style: 'bold' // Optional: font style
                            }
                        },
                        stacked: true
                    }
                },
                animation: {
                    x: {
                        duration: 2500,
                        from: 0
                    },
                    y: {
                        duration: 1000,
                        from: 500
                    }
                },
            }
        });
    };

    const expandAll = () => {
        let loc = JSON.parse(JSON.stringify(indicator))
        let find = indicator.every(i => i.selected)
        if (find) {
            loc.forEach((item, index) => {

                item.selected = false

            })
        } else {
            loc.forEach((item, index) => {

                item.selected = true

            })
        }
        setindicator(loc)
        forceUpdate()
    }
    const openGraphDialog = (topindex) => {
        console.log(selectedIndicator)
        setSelectedIndicator(() => topindex);
        updateGraphLocationFilter('country', filter.country, topindex)


    }
    const openDataSource = (datasources, topindex) => {
        if (datasources.length) {
            setSelectedIndicator(() => topindex);
            setSourceList(datasources)
            setSourceDialog(true)
        }

    }

    const revokeApproval = async (datasources) => {
        console.log(datasources)
        if (datasources.length && datasources.every(i => i.type === 3)) {
            const { value: accept } = await Swal.fire({
                title: `<div style="overflow:visible;font-size:20px;font-weight:600;margin-top:0px">Revoke Approval(s)</div>`,
                html: `<div style="overflow:auto;max-height:200px" >Are You Sure Want to Revoke Approval</div>`,
                showCancelButton: true,
                confirmButtonText: 'Revoke',

            })
            if (accept) {
                setBlock(true)
                let approvedDatas = datasources

                try {

                    // Map over the array and initiate API calls for each object
                    const promises = approvedDatas.map(async (obj) => {
                        // Replace with your API call, e.g., fetch or axios
                        const response = await revokeSubmission(obj);
                        return response;
                    });

                    // Wait until all promises are resolved
                    const results = await Promise.all(promises);

                    let newData = mergeSubmission(quantitativesubmission, results)
                    let newData2 = mergeSubmission(overallQuantitativeSubmission, results)
                    setQuantitativeSubmission(newData)
                    setOverallQuantitativeSubmission(newData2)

                    renderIndicatorValues(filter, newData, newData2)
                    setVisible(false)
                    setBlock(false)

                } catch (error) {
                    // Handle any errors that occur during the API calls
                    setVisible(false)
                    setBlock(false)


                }
            }
        }
    }

    const randomIntFromInterval = (min, max) => {
        // min and max included
        return Math.floor(Math.random() * (max - min + 1) + min);
    };
    const getID = () => {
        return (
            parseInt(Date.now() * Math.random()) +
            DateTime.utc().toUnixInteger() +
            randomIntFromInterval(1, 1000)
        );
    };

    const renderTable = (scopeData, topindex) => {
        console.log(scopeData.labels)
        const monthsPerPeriod = 12 / scopeData.frequency;

        return (
            <div className="data-table" key={scopeData.title}>
                <div className='col-12 grid m-0 header cur-pointer' onClick={() => { scopeData.selected = scopeData.selected ? !scopeData.selected : true; forceUpdate() }}>
                    <div className='col-11  grid m-0 p-0 align-items-center'>
                        <div className=' p-0 m-0     text-three-dot' style={{ maxWidth: '90%' }}>{scopeData.title}</div>
                        <div className='ml-1'> <i onClick={(e) => { e.stopPropagation(); setMethodology({ text: scopeData.methodology, indicator: scopeData.title }); setMethodologyDialog(true) }} className='pi pi-exclamation-circle' /> </div>
                    </div>
                    {/* <div className='col-6'>
                        <div className='flex justify-content-center'>
                            <label > MTD Previous Year </label>
                        </div>
                        <div className='flex justify-content-center'>
                            <span style={{ color: 'green', marginLeft: 2, marginRight: 2 }}>{scopeData.previousYTD}</span>  {scopeData.unit}
                        </div>
                    </div> */}

                    <div className='col-1 grid m-0 justify-content-end'>

                        <div><i className={scopeData.selected ? 'pi pi-angle-up' : 'pi pi-angle-down'} /></div>
                    </div>

                </div>

                {scopeData.selected && <table>
                    <thead style={{ fontWeight: "600", fontSize: "16px" }}>

                        <tr>
                            <th colSpan={1}></th>
                            {scopeData.labels.map((label, index) => {
                                console.log(label)
                                return (
                                    <th key={index} colSpan={scopeData.approverFrequency}>
                                        <div className='col-12 grid m-0 p-0 justify-content-between align-items-center' >
                                            <div className='col-4 m-0 p-0 flex' >
                                                {scopeData.indicatorId !== 1 && <>
                                                    <Checkbox inputId={label.name} disabled={label.disabled} value={label.checked} onChange={(e) => { updateCheckboxValue(e.checked, topindex, index) }} checked={(label.checked || label.status === 1)} style={{ marginRight: "4px" }} />
                                                    <label htmlFor={label}>{label.name}</label>
                                                </>}
                                            </div>
                                            {/* <div className='col-2 m-0 p-0'>
                                                {!label.upcoming && <div className='flex justify-content-center text-underline clr-navy fw-6 fs-14 cur-pointer' style={{ color: (!label.performanceCommentary || !label.performanceCommentary.length) ? 'red' : 'green' }}>  {(!label.performanceCommentary || !label.performanceCommentary.length) ? <div onClick={() => { setSelectedIndicator(topindex); setSelectedNoteIndex(index); setNote(''); setCommentList([]); setNoteVisible(true) }}> <i className='pi pi-file' /></div> : <div onClick={() => { setSelectedIndicator(topindex); setSelectedNoteIndex(index); setNote(''); setCommentList(label.performanceCommentary); setNoteVisible(true) }}><i className='pi pi-file' /></div>} </div>}
                                            </div> */}
                                            <div className='col-6 m-0 p-0'>
                                                {!label.upcoming && <><span className='fw-6'>{`${label.name.slice(0, 1)}o${label.name.slice(0, 1)} : `} </span><i className={"pi fs-10 " + (!label.direction ? 'pi-arrow-up' : 'pi-arrow-down')} style={{ marginRight: "2px", color: !label.direction ? 'red' : 'rgba(23, 178, 106, 1)' }} > <span className='fw-5 fs-14'>{label.value}</span> </i></>}
                                            </div>
                                        </div>
                                    </th>)
                            })}
                            <th colSpan={3}>
                                Performance Deviation
                                {/* { scopeData.deviation  ? <> <i style={{color: !scopeData.deviationDirection ? 'red':'green'}} className={ 'pi ' +(!scopeData.deviationDirection ? 'pi-arrow-up' :'pi-arrow-down')} /> {scopeData.deviation} change YoY </> : 'NA'} */}
                            </th>
                            <th colSpan={1} rowSpan={2}>
                                Commentary
                            </th>
                        </tr>
                        <tr>
                            <th className='no-border-right fixed-columns' >Unit</th>

                            {scopeData.months.map((month, index) => <th colSpan={scopeData.frequency} key={index}>{month}</th>)}
                            <th className='no-border-right  fixed-columns' >YTQ {scopeData.monthMTD.cur} </th>
                            <th className='no-border-right  fixed-columns' >YTQ {scopeData.monthMTD.prev} </th>
                            <th className='fixed-columns' >Change </th>

                        </tr>
                        <tr>

                        </tr>
                    </thead>
                    <tbody>
                        {scopeData.data.map((item, index) => {
                            console.log(scopeData.labels.some((i, j) => i.performanceCommentary && i.performanceCommentary.length !== 0))
                            return (
                                <tr>
                                    <td className='no-border-right fixed-columns' style={{ fontWeight: "400", fontSize: "16px" }}>{item.unit}</td>

                                    {item.values.map((value, index) => <td key={index} colSpan={scopeData.frequency} style={{ fontWeight: '700', fontSize: "16px" }}><div className='fs-12'><span className={(value === '-') ? 'fs-14 fw-7' : ' clr-navy fw-7 fs-14'}> {(value !== '-') ? scopeData.dataSources[index].filter(i => i.data && (i.data.type === 2 || i.data.type === 3)).length === scopeData.dataSources[index].length ? value : <>{value}<span className='mandatory'>*</span></> : value} </span></div></td>)}
                                    {index === 0 && <td className='no-border-right fixed-columns' style={{ fontWeight: "400", fontSize: "16px" }} rowSpan={scopeData.data.length + 2}>{scopeData.currentMTD}</td>}
                                    {index === 0 && <td className='no-border-right fixed-columns' style={{ fontWeight: "400", fontSize: "16px" }} rowSpan={scopeData.data.length + 2}>{scopeData.previousMTD}</td>}

                                    {index === 0 && <td className=' fixed-columns' style={{ fontWeight: "400", fontSize: "16px" }} rowSpan={scopeData.data.length + 2}><span style={{ color: !scopeData.deviationDirection ? 'red' : 'green' }} >{scopeData.deviation}</span></td>}
                                    {index === 0 && <td colSpan={1} rowSpan={scopeData.data.length + 3}> {scopeData.indicatorId !== 1 && <div className={'flex justify-content-center  clr-navy fw-6 fs-14' + (((scopeData.labels.filter((i, j) => i.new && i.checked && !i.disabled && i.deviation >= 10 && scopeData.performanceCommentary[j] && scopeData.performanceCommentary[j].length === 0).length !== 0) || scopeData.performanceCommentary.some((i, j) => i.length !== 0)) ? ' text-underline cur-pointer' : '')} style={{ color: (scopeData.labels.filter((i, j) => i.new && i.checked && !i.disabled && i.deviation >= 10 && scopeData.performanceCommentary[j].length === 0).length !== 0) ? 'red' : scopeData.performanceCommentary.some((i, j) => i.length !== 0) ? 'green' : 'gray' }}>  {(scopeData.labels.filter((i, j) => i.new && i.checked && !i.disabled && i.deviation >= 10 && scopeData.performanceCommentary[j] && scopeData.performanceCommentary[j].length === 0).length !== 0) ? <div onClick={() => { setSelectedIndicator(topindex); setNote(''); setLabels(scopeData.labels); setCommentList(scopeData.performanceCommentary); setNoteVisible(true) }}>Required</div> : scopeData.performanceCommentary.some((i, j) => i.length !== 0) ? <div onClick={() => { setSelectedIndicator(topindex); setNote(''); setLabels(scopeData.labels); setCommentList(scopeData.performanceCommentary); setNoteVisible(true) }}>Update</div> : 'Not Required'} </div>} </td>}
                                    {/* {index === 0 && <td colSpan={1} rowSpan={scopeData.data.length+2}> </td>} */}
                                    {/* {index === 0 && <td colSpan={1} rowSpan={scopeData.data.length + 2}>  <i className='material-icons'  > edit_note </i> </td>} */}
                                </tr>
                            )
                        })}
                        {/* <span onClick={() => { openDataSource(item.dataSources[index]) }} className={(value === '-' || !item.dataSources.length) ? 'fs-14 fw-7' : ' clr-navy fw-7 fs-14'}> {value} </span> */}
                        <tr>
                            <td colSpan={1} > Data Source(s)</td>
                            {scopeData.months.map((month, index) => <td colSpan={scopeData.frequency} key={index}> <span className={(scopeData.dataSources[index] && scopeData.dataSources[index].length) ? 'cur-pointer clr-navy text-underline' : ''} onClick={() => { scopeData.dataSources[index] ? openDataSource(scopeData.dataSources[index], topindex) : console.log('') }} >{(scopeData.dataSources[index] && scopeData.dataSources[index].length) ? 'View' : '-'}</span> </td>)}

                        </tr>
                        <tr>
                            <td colSpan={1} >Action</td>
                            {scopeData.labels.map((label, index) => <td colSpan={scopeData.approverFrequency} key={index}> {scopeData.indicatorId !== 1 && <span className={(label.status === 1) ? 'cur-pointer clr-navy text-underline' : ''} onClick={() => { (label.status === 1) ? revokeApproval(label.approvedData) : console.log('') }} >{(label.status === 1) ? 'Revert Approval' : ''}</span>} </td>)}

                        </tr>
                        <tr>
                            <td colSpan={1} />

                            {scopeData.labels.map((label, index) => (
                                <td colSpan={scopeData.approverFrequency} >
                                    {label.upcoming === false && <span key={index} className={`status ${status.find(i => i.id === label.status).name.toLowerCase().replace(/\s+/g, '-')}`}>
                                        {status.find(i => i.id === label.status).name}
                                    </span>}
                                </td>
                            ))}
                            <td colSpan={3}  > {scopeData.indicatorId <= 13 && <div className='justify-content-center flex'>  <div className='grid m-0  approver-chart-box' onClick={() => { openGraphDialog(topindex) }}> <div className='mr-2 clr-navy ' >View Graph</div>  <i className='pi pi- pi-chart-bar' /> </div></div>} </td>


                        </tr>
                    </tbody>
                </table>}
            </div>
        );
    };
    const saveAutomatedResponseType2InDP = async (resdata, obj) => {
        let result = []
        if (resdata.length) {
            let tempid = getID()
            for (const key in resdata[0]) {
                if (resdata[0].hasOwnProperty(key)) {
                    console.log(`Key: ${key}, Value: ${resdata[0][key]}`);

                    let value = resdata[0][key]
                    result.push({ userProfileId: admin_data.id, formId: tempid, submitId: parseFloat(obj.id), formType: 5, tier0_id: obj.tier0_id, tier1_id: obj.tier1_id, tier2_id: obj.tier2_id, tier3_id: obj.tier3_id, level: obj.level, frequency: obj.frequency, standard: obj.standard, dp: key, value: value, dataType: typeof value, entityUserAssId: obj.entityUserAssId, entityAssId: obj.entityAssId, dcfId: parseFloat(obj.dcfId), reporting_period: obj.reporting_period, submissionType: 1, approved_by: login_data.id, reviewed_by: obj.reviewer_modified_by, reported_by: obj.reporter_modified_by, created_by: login_data.id, created_on: DateTime.utc() })

                }
            }

        } else {
            result.push({ userProfileId: admin_data.id, submitId: parseFloat(obj.id), formType: 5, tier0_id: obj.tier0_id, tier1_id: obj.tier1_id, tier2_id: obj.tier2_id, tier3_id: obj.tier3_id, level: obj.level, frequency: obj.frequency, standard: obj.standard, dp: '$$$', entityUserAssId: obj.entityUserAssId, entityAssId: obj.entityAssId, dcfId: parseFloat(obj.dcfId), reporting_period: obj.reporting_period, submissionType: 2, approved_by: login_data.id, reviewed_by: obj.reviewer_modified_by, reported_by: obj.reporter_modified_by, created_by: login_data.id, created_on: DateTime.utc() })
        }

        for (let i = 0; i < result.length; i++) {

            try {

                const response = await APIServices.post(API.QNDP_Report_UP(admin_data.id), result[i]);


            } catch (error) {
                alert(`Error : 420-${obj.dcfId}-${obj.id}, Found an issue, close this window stop approving data.please contact support@eisqr.com with screenshot of this error`)

            }

        }
        return result
    }
    const mergeSubmission = (oldArr, newArr) => {
        const newMap = new Map(newArr.map(item => [item.id, item]));

        return oldArr.map(item => newMap.has(item.id) ? newMap.get(item.id) : item);
    };
    async function revokeSubmission(obj) {
        // Simulating an API call with a delay
        return new Promise((resolve) => {
            let newObj = {}
            let dt = DateTime.utc()

            newObj['type'] = 2
            newObj['reject'] = 0
            newObj['last_modified_on'] = dt
            newObj['last_modified_by'] = login_data.id
            if (!obj.return_remarks) {
                newObj['return_remarks'] = [{ remarks: value, user_type: 2, type: 1, user_id: login_data.id, created_on: dt }]

            } else {
                let lt = obj.return_remarks
                lt.push({ remarks: value, user_type: 2, type: 1, user_id: login_data.id, created_on: dt })
                newObj['return_remarks'] = lt
            }
            APIServices.patch(API.QN_Submission_Edit(obj.id), newObj).then((res) => {

                // let loc3 = JSON.parse(JSON.stringify(sourcelist))

                // let index3 = loc3.findIndex(i => (i.data && (i.data.id === obj.id)))
                // console.log(index3,)

                // if (index3 !== -1) {
                //     console.log(loc3[index3])
                //     loc3[index3].data = { ...loc3[index3].data, ...newObj }
                //     loc3[index3].status = 4

                //     setSourceList(loc3)
                // }

                resolve({ ...obj, ...newObj })
                // saveAutomatedResponseType2InDP(obj.response, obj)
            })


        })
    }
    async function approveSubmission(obj) {
        // Simulating an API call with a delay
        return new Promise((resolve) => {
            let newObj = {}
            let dt = DateTime.utc()

            newObj['type'] = 3
            newObj['reject'] = 0
            newObj['last_modified_on'] = dt
            newObj['approver_modified_on'] = dt
            newObj['approver_modified_by'] = login_data.id
            newObj['last_modified_by'] = login_data.id
            if (!obj.return_remarks) {
                newObj['return_remarks'] = [{ remarks: value.trim().length ? value : 'Data Approved', user_type: 3, type: 1, user_id: login_data.id, created_on: dt }]

            } else {
                let lt = obj.return_remarks
                lt.push({ remarks: value.trim().length ? value : 'Data Approved', user_type: 3, type: 1, user_id: login_data.id, created_on: dt })
                newObj['return_remarks'] = lt
            }
            APIServices.patch(API.QN_Submission_Edit(obj.id), newObj).then((res) => {
                resolve({ ...obj, ...newObj })

                // saveAutomatedResponseType2InDP(obj.response, obj)
            })


        })
    }
    const approveData = async () => {
        let count = indicator.filter(i => i.labels.some(x => x.checked && !x.disabled && x.new)).length

        let required = indicator.filter(i => i.indicatorId !== 1).filter(i => i.labels.some((x, y) => x.checked && !x.disabled && x.new && x.deviation >= 10 && i.performanceCommentary && i.performanceCommentary[y].length === 0))
        console.log(indicator, required)
        if (required.length === 0) {
            const { value: accept } = await Swal.fire({
                title: `<div style="overflow:visible;font-size:20px;font-weight:600;margin-top:0px">Approve</div>`,
                html: `<div style="overflow:auto;max-height:200px" >Are You Sure Want to Approve ${count} Indicators</div>`,
                showCancelButton: true,
                confirmButtonText: 'Approve',

            })
            if (accept) {
                setBlock(true)
                let approvedDatas = removeDuplicatesFromArrayByKey(indicator.flatMap(x => x.labels.filter(i => i.checked && !i.disabled).flatMap(i => i.approvedData)), 'id')

                try {

                    // Map over the array and initiate API calls for each object
                    const promises = approvedDatas.map(async (obj) => {
                        // Replace with your API call, e.g., fetch or axios
                        const response = await approveSubmission(obj);
                        return response;
                    });

                    // Wait until all promises are resolved
                    const results = await Promise.all(promises);
                    console.log(results)
                    let newData = mergeSubmission(quantitativesubmission, results)
                    let newData2 = mergeSubmission(overallQuantitativeSubmission, results)
                    console.log(newData, newData2)
                    setQuantitativeSubmission(newData)
                    setOverallQuantitativeSubmission(newData2)

                    renderIndicatorValues(filter, newData, newData2)
                    setVisible(false)
                    setBlock(false)

                } catch (error) {
                    // Handle any errors that occur during the API calls
                    setVisible(false)
                    setBlock(false)


                }
            }
        } else {
            Swal.fire({
                title: "Some Indicator Requires Comments, kindly check & try again",

                confirmButtonText: 'Exit',
                allowOutsideClick: false,
            })
        }



    }

    const scope1Tooltip = ({ active, payload, label }) => {
        if (active && payload && payload.length) {
            return (
                <div className="custom-tooltip" style={{ backgroundColor: '#fff', padding: '5px', border: '1px solid #ccc' }}>
                    <p className="label">{`${label} : ${payload[0].value}`}</p>
                    <p className="intro">{`Scope: ${payload[0].name}`}</p>
                </div>
            );
        }
        return null;
    };
    const getUserId = (userId) => {
        if (userId === admin_data.id) {
            return 'Enterprise Admin'
        }
        else {
            let index = userList.findIndex(i => i.id === userId)
            if (index !== -1) {
                return userList[index].information.empname
            } else {
                return 'Not Found'
            }
        }
    }
    const empContent = ({ active, payload, label }) => {

        if (active && payload && payload.length && label) {
            return (
                <div className="custom-tooltip" style={{ backgroundColor: '#fff', border: '1px solid #ccc', padding: '10px' }}>
                    <p className="label">{`${label}`}</p>
                    {graphData.fields.map((i, index) => {
                        return (
                            <>
                                <p className="intro">{`${i.key}: ${payload[index].value} %`}</p>
                            </>
                        )
                    })

                    }


                </div>
            );
        }

        return null;


    }
    const tooltipContent = ({ active, payload, label, fields, unit }) => {
        if (active && payload && payload.length && label) {
            return (
                <div className="custom-tooltip" style={{ backgroundColor: '#fff', border: '1px solid #ccc', padding: '10px' }}>
                    <p className="label">{`${label}`}</p>
                    {fields.map((i, index) => {

                        if (payload[index]) {
console.log(payload[index])
                            return (
                                <>
                                    <p className="intro">{`${payload[index].dataKey}: ${payload[index].value} ` + unit}</p>
                                </>
                            )
                        }
                    })

                    }


                </div>
            );
        }

        return null;
    };
    const scope1Legend = (e) => {
        console.log(e)
        return (
            <div class='col-12 flex justify-content-center'>
                <ul >
                    <li class="recharts-legend-item legend-item-3" style={{ display: 'inline-block', marginRight: 10 }}>
                        <svg class="recharts-surface" width="14" height="14" viewBox="0 0 32 32" style={{ display: 'inline-block', verticalAlign: 'middle', marginRight: 4 }}><title></title><desc></desc><path stroke="none" fill="rgb(22, 96, 130)" d="M0,4h32v24h-32z" class="recharts-legend-icon"></path></svg><span class="recharts-legend-item-text" style={{ color: "rgb(22, 96, 130)" }}>Scope 1</span></li>
                    <li class="recharts-legend-item legend-item-3" style={{ display: 'inline-block', marginRight: 10 }}>
                        <svg class="recharts-surface" width="14" height="14" viewBox="0 0 32 32" style={{ display: 'inline-block', verticalAlign: 'middle', marginRight: 4 }}><title></title><desc></desc><path stroke="none" fill="rgb(233, 113, 50)" d="M0,4h32v24h-32z" class="recharts-legend-icon"></path></svg><span class="recharts-legend-item-text" style={{ color: "rgb(233, 113, 50)" }}>Scope 2</span></li>
                </ul>
            </div>
        )
    }
    const handleLegendClick = (entry) => {
        const key = entry.value;
        setVisibleBars(prevState => ({
            ...prevState,
            [key]: !prevState[key]
        }));

        forceUpdate()
    };
    const handleBackClick = () => {
        setGraphData(data)

        setIsDrilledDown((prev) => false)

    }
    const handleBarClick = (payload, index) => {
        const clickedMonth = payload.month;
        console.log(payload, index)

        if (payload[clickedMonth]) {
            setIsDrilledDown((prev) => !prev)
            setGraphData({ fields: [{ key: 'Men', color: '#008080' }, { key: 'Women', color: '#FF8042' }], data: payload[clickedMonth] })

        }

    }
    const CustomLegend = ({ payload, onClick }) => {
        return (
            <ul style={{ listStyleType: 'none', padding: 0, margin: 0 }}>
                {payload.map((entry, index) => (
                    <li
                        key={`item-${index}`}
                        onClick={() => onClick(entry)}
                        style={{
                            cursor: 'pointer',
                            color: entry.payload.active ? entry.color : '#ccc',
                            fontWeight: entry.payload.active ? 'bold' : 'normal',
                            display: 'flex',
                            alignItems: 'center',
                            textDecoration: entry.payload.active ? 'none' : 'line-through',
                            opacity: entry.payload.active ? 1 : 0.5 // Adjust the opacity for inactive items
                        }}
                    >
                        <span style={{ backgroundColor: entry.color, width: 16, height: 16, display: 'inline-block', marginRight: 10 }}></span>
                        {entry.value}
                    </li>
                ))}
            </ul>
        );
    };


    return (
        <div className='font-lato'>
            <BlockUI blocked={block}>
                <div className="container">
                    <div style={{ display: 'flex', alignItems: 'center', marginBottom: '5px' }}>
                        <h2 style={{ fontWeight: '600', fontSize: '24px' }}>Welcome {login_data.information.empname}!</h2>
                        <p style={{ fontWeight: '400', fontSize: '16px', marginLeft: '25px', marginRight: '25px', paddingTop: '6px' }}>&lt;{login_data.email}&gt;</p>
                        <div style={{ backgroundColor: 'rgba(201, 226, 255, 1)', border: '1px solid rgba(201, 226, 255, 1)', borderRadius: '8px' }}>
                            <p style={{ fontWeight: '500', fontSize: '14px', color: 'rgba(49, 89, 117, 1)', padding: '3px', paddingTop: '3px' }}>Approver</p>
                        </div>

                    </div>
                    <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '5px' }}>
                        <div>
                            <h1 style={{ marginBottom: '0px', fontWeight: '700', fontSize: '20px' }}> ESG Country Data Approval Portal</h1>
                            {/* <p style={{fontWeight: '400', fontSize: '16px'}}>Impedit quis repellat quia quas provident dolor repellendus.</p> */}
                        </div>  
                        {/* <div
                            className="block mt-2 md:mt-0 p-input-icon-left"

                        >
                            <i className="pi pi-search" />
                            <InputText type="search" placeholder="Search..." style={{ width: '100%' }} />
                        </div> */}
                    </div>
<ReadMoreComponent paragraph={`As an Approver, your primary role is to ensure the quality and accuracy of the calculated ESG metrics. You will critically review the data and the calculated metrics for errors, inconsistencies, and adherence to established standards. You will provide explanations or commentary on the ESG performance trends. Finally, you will grant final approval for the data to be used or disseminated, ensuring that the approved ESG metrics remain locked until the next reporting cycle.`} />
                    <div className='grid m-0 align-items-center justify-content-between' >

                        <div className='col-7 grid m-0 align-items-center' >
                            <div className='col-4 grid m-0 align-items-center'>
                                <div className='col-2'>Year :</div>
                                <div className='col-10'>

                                    <Dropdown value={filter.year} options={yearoptions} optionValue='name' onChange={(e) => { updateLocationFilter('year', e.value) }} placeholder="Select Year" style={{ width: "100%" }} />
                                </div>
                            </div>
                            <div className='col-8 grid m-0 align-items-center'>
                                <div className='col-4'>Aspect :</div>
                                <div className='col-8'>

                                    <Dropdown value={filter.category} optionLabel='name' optionValue='id' options={[{ name: 'All', id: 0 }, { name: 'GHG Emissions', id: 1 }, { name: 'Women Representation', id: 2 } , {name:'Water Consumption',id:3} , {name:'Waste',id:3}]} onChange={(e) => { setSelectedIndicator(null); setindicator(indicatorbk.filter(i => (i.category === e.value || e.value === 0))); setFilter((prev) => ({ ...prev, category: e.value })) }} placeholder="Select Category" style={{ width: "100%" }} />
                                </div>
                            </div>
                        </div>
                        <div className='col-3 grid m-0 align-items-center' >
                            <div className='col-3'>Country :</div>
                            <div className='grid m-0 col-9' >
                                <div className='col-12' >

                                    <Dropdown value={filter.country} options={locList.country} onChange={(e) => { updateLocationFilter('country', e.value) }} optionLabel='name' optionValue='id' placeholder="Select Country" style={{ width: '100%' }} />
                                </div>
                                {/* {(filter.country !== 0 && (filter.city !== null || filter.city === 0)) && <div className='col-4' >
                                <Dropdown value={filter.city} options={locList.city} onChange={(e) => { updateLocationFilter('city', e.value) }} optionLabel='name' optionValue='id' placeholder="Select Region" disabled={!locList.city.length} style={{ width: '100%' }} />
                            </div>}
                            {(filter.city !== 0 && filter.location !== null) && <div className='col-4' >
                                <Dropdown value={filter.location} options={locList.location} onChange={(e) => { updateLocationFilter('location', e.value) }} optionLabel='name' optionValue='id' placeholder="Select Business Unit" disabled={!locList.location.length} style={{ width: '100%' }} />
                            </div>} */}
                            </div>
                        </div>
                        <div className='col-2 grid m-0 justify-content-center align-items-center' >
                            <Button style={{ height: 30 }} text label={indicator.every(i => i.selected) ? 'Collapse All' : 'Expand All'} onClick={expandAll} />
                        </div>
                    </div>

                    {/* <div className="grid">
                <div className="p-col-3 p-md-3">
                    <Dropdown value={selectedYear} options={years} onChange={(e) => setSelectedYear(e.value)} placeholder="Select Year" />
                </div>
                <div className="p-col-3 p-md-3">
                    <Dropdown value={selectedCountry} options={sites.map(site => ({ label: site.name, value: site }))} onChange={handleCountryChange} placeholder="Select Country" />
                </div>
                <div className="p-col-3 p-md-3">
                    <Dropdown value={selectedRegion} options={selectedCountry ? selectedCountry.locationTwos.map(loc => ({ label: loc.name, value: loc })) : []} onChange={handleRegionChange} placeholder="Select Region" disabled={!selectedCountry} />
                </div>
                <div className="p-col-3 p-md-3">
                    <Dropdown value={selectedBusinessUnit} options={selectedRegion ? selectedRegion.locationThrees.map(loc => ({ label: loc.name, value: loc })) : []} onChange={(e) => setSelectedBusinessUnit(e.value)} placeholder="Select Business Unit" disabled={!selectedRegion} />
                </div>
            </div> */}

                    {indicator.map(renderTable)}
                    {filter.country !== 0 && filter.city === 0 && filter.location === null &&
                        <div style={{ display: "flex", justifyContent: "start", marginTop: "3%" }}>

                            {/* <Button
                                label="Approve all Pending for Approval"
                                style={{ marginRight: '1%' }}
                                disabled={!indicator.every(obj => obj.labels.every(i => i.checked === true && !i.disabled))}
                                onClick={() => { setValue(''); setVisible(true) }}

                            /> */}
                            <Button
                                label="Approve Selected"
                                outlined
                                disabled={!indicator.filter(i => i.indicatorId !== 1).some(obj => obj.labels.some(i => i.checked === true && !i.disabled))}
                                style={{ color: "rgba(0, 82, 132, 1)" }}
                                onClick={() => { setValue(''); setVisible(true) }}
                            />
                        </div>}
                    <Dialog header={'Methodology :' + methodology.indicator} visible={methodologydialog} style={{
                        width: "70%",
                        background: 'rgba(255, 255, 255, 1)',
                    }} onHide={() => { if (!methodologydialog) return; setMethodologyDialog(false); }}>
                        <div className='fw-6 fs-16 clr-navy'>
                            {methodology.text}

                        </div>
                    </Dialog>
                    <Dialog header={customtHeader} visible={visible} style={{
                        width: "394px",
                        background: 'rgba(255, 255, 255, 1)',
                    }} onHide={() => { if (!visible) return; setVisible(false); }}>
                        <div>
                            <div className='col-12 m-0'>
                                <div className='col-12'>
                                    <InputTextarea style={{ width: '100%' }} autoResize value={value} onChange={(e) => setValue(e.target.value)} rows={5} cols={30} placeholder="Enter your comment" />
                                </div>
                                <div className='col-12 flex justify-content-between'>
                                    <Button
                                        label="Close"
                                        outlined

                                        onClick={() => { setVisible(false) }}
                                    />
                                    <Button
                                        label="Approve Data"


                                        onClick={() => { approveData() }}
                                    />
                                </div>
                            </div>





                        </div>
                    </Dialog>
                    <Dialog header={selectedIndicator !== null ? 'Performance Commentary ' + indicator[selectedIndicator].title + ' - ' + filter.year : 'Performance Commentary'} visible={notevisible} style={{
                        width: "75%", height: 'calc(100vh -  100px)',
                        background: 'rgba(255, 255, 255, 1)',
                    }} onHide={() => { if (!notevisible) return; setNoteVisible(false); }}>
                        <div>
                            <div className='grid col-12 m-0 p-0'>
                                <div className='col-10'>
                                    <InputTextarea style={{ width: '100%' }} autoResize value={note} onChange={(e) => { setNote(e.target.value) }} rows={5} cols={30} placeholder="Enter comments" />
                                </div>
                                <div className='col-12 grid m-0'>
                                    {labels.map((i, index) => {
                                        if (!i.upcoming || (i.disabled && i.checked)) {
                                            return (
                                                <div className={'col-' + (12 / labels.length)} >
                                                    <Button onClick={() => updateNotes(index)} label={'Add Under ' + i.name} />
                                                </div>
                                            )
                                        }
                                    })

                                    }
                                </div>

                            </div>
                            <div className='col-12' style={{ overflow: 'auto', height: 'calc(100vh - 340px)' }}>

                                {labels.map((i, index) => {

                                    if (!i.upcoming) {
                                        return (
                                            <div className='col-12' style={{
                                                border: '1px solid #d3d3d3',
                                                borderRadius: '5px'
                                            }} >
                                                <div className='col-12' style={{ background: '#d3d3d3' }}>
                                                    <label className='fw-6 fs-16'>{i.name + ' ' + filter.year + ' ( ' + i.rp + ' )'}</label>
                                                </div>
                                                <div>
                                                    {commentlist[index].map((cmnt) => {

                                                        return (
                                                            <div className='card m-1 p-3'>
                                                                <div className='col-12 grid m-1 p-0'>

                                                                    <div className='col-6 fw-5 p-0  ' style={{ color: 'green' }}>{DateTime.fromISO(cmnt.created_on, { zone: 'utc' }).toLocal().toFormat('dd/M/yyyy')}</div>
                                                                    <div className='col-6 fw-5 clr-navy p-0 flex justify-content-end'>{getUserId(cmnt.user_id)}</div>
                                                                </div>
                                                                <div className='m-1' style={{ fontStyle: 'italic', fontFamily: 'bold' }}>{cmnt.comment}</div>

                                                            </div>
                                                        )
                                                    })

                                                    }
                                                </div>

                                            </div>
                                        )
                                    }
                                })}

                            </div>

                        </div>
                    </Dialog>
                    <Dialog header={selectedIndicator !== null ? indicator[selectedIndicator].title : 'Chart Window'} visible={chartdialog} style={{
                        width: "90%",
                        background: 'rgba(255, 255, 255, 1)',
                    }} onHide={() => { if (!chartdialog) return; setChartDialog(false); }}>
                        <div className='col-12'>
                            {indicator[selectedIndicator] && (indicator[selectedIndicator].indicatorId === 1 || indicator[selectedIndicator].indicatorId === 2 || indicator[selectedIndicator].indicatorId === 5 || indicator[selectedIndicator].indicatorId === 6 || indicator[selectedIndicator].indicatorId === 8 || indicator[selectedIndicator].indicatorId === 7 || indicator[selectedIndicator].indicatorId === 8 || indicator[selectedIndicator].indicatorId === 9|| indicator[selectedIndicator].indicatorId === 10 ||  indicator[selectedIndicator].indicatorId === 11 ||  indicator[selectedIndicator].indicatorId === 12 ||  indicator[selectedIndicator].indicatorId === 13) && <>
                                <div className='col-12 ml-3'>Reporting Entity :</div>
                                <div className='col-12 grid m-0 align-items-center ' >

                                    <div className='grid m-0 col-12' >
                                        <div className='col-4' >

                                            <Dropdown value={chartfilter.country} options={locList2.country} disabled onChange={(e) => { updateLocationFilter('country', e.value, selectedIndicator) }} optionLabel='name' optionValue='id' placeholder="Select Country" style={{ width: '100%', background: 'lightgray' }} />
                                        </div>
                                        {(chartfilter.country !== 0 && (chartfilter.city !== null || chartfilter.city === 0)) && <div className='col-4' >
                                            <Dropdown value={chartfilter.city} options={locList2.city} onChange={(e) => { updateGraphLocationFilter('city', e.value, selectedIndicator) }} optionLabel='name' optionValue='id' placeholder="Select Region" disabled={!locList2.city.length} style={{ width: '100%' }} />
                                        </div>}
                                        {(chartfilter.city !== 0 && chartfilter.location !== null) && <div className='col-4' >
                                            <Dropdown value={chartfilter.location} options={locList2.location} onChange={(e) => { updateGraphLocationFilter('location', e.value, selectedIndicator) }} optionLabel='name' optionValue='id' placeholder="Select Business Unit" disabled={!locList2.location.length} style={{ width: '100%' }} />
                                        </div>}
                                    </div>
                                </div>
                            </>}


                            {indicator[selectedIndicator] && (indicator[selectedIndicator].indicatorId === 1 || indicator[selectedIndicator].indicatorId === 5 || indicator[selectedIndicator].indicatorId === 2 || indicator[selectedIndicator].indicatorId === 6 ||  indicator[selectedIndicator].indicatorId === 7 || indicator[selectedIndicator].indicatorId === 8 || indicator[selectedIndicator].indicatorId === 9 || indicator[selectedIndicator].indicatorId === 10 || indicator[selectedIndicator].indicatorId === 11 ||  indicator[selectedIndicator].indicatorId === 12 ||  indicator[selectedIndicator].indicatorId === 13 ) &&
                                <>
                                    <div className='col-12 flex justify-content-center '> {indicator[selectedIndicator].indicatorId === 1 ? 'GHG Scope 1 & 2 Market-based : Previous Year Comparison (Without EACs) (tCO2e)' : indicator[selectedIndicator].indicatorId === 5 ? `GHG Scope 2 Location Based: Previous Tear Comparison (tCO2e)` : indicator[selectedIndicator].indicatorId === 2 ? 'Scope 1 Emissions : Previous Year Comparison (tCO2e)' : indicator[selectedIndicator].indicatorId === 6 ? 'Water Withdrawn: Previous Year Comparison (m3)' :  indicator[selectedIndicator].indicatorId === 8 ?  'Water Discharge: Previous Year Comparison (m3)' :  indicator[selectedIndicator].indicatorId === 7 ? 'Water Consumed: Previous Year Comparison (m3)' :  indicator[selectedIndicator].indicatorId === 9 ? 'WUE: Previous Year Comparison' :  indicator[selectedIndicator].indicatorId === 10 ? 'Non-hazardous Waste Generated: Previous Year Comparison (tonnes)' :  indicator[selectedIndicator].indicatorId === 11 ? "Non-hazardous Waste Diverted: Previous Year Comparison (tonnes)": indicator[selectedIndicator].indicatorId === 12 ? 'Hazardous Waste Generated: Previous Year Comparison (tonnes)':indicator[selectedIndicator].indicatorId === 13 ? 'Hazardous Waste Recycling: Previous Year Comparison (tonnes) ':  '' } </div>

                                    <ResponsiveContainer width="100%" height={400}>
                                        <BarChart
                                            data={graphData.data}
                                            margin={{ top: 20, right: 30, left: 20, bottom: 40 }}
                                            barCategoryGap="100%"  // Adds space between categories (Jan, Feb, etc.)
                                            barGap={50}
                                            barSize={15}
                                        >

                                            {/* <CartesianGrid strokeDasharray="3 3" /> */}
                                            <XAxis dataKey="month" tick={{ angle: -90, textAnchor: 'end' }} tickMargin={0} interval={0} />
                                            <YAxis label={{ value: (indicator[selectedIndicator].indicatorId === 6 || indicator[selectedIndicator].indicatorId === 7 || indicator[selectedIndicator].indicatorId === 8) ? 'm3' : indicator[selectedIndicator].indicatorId === 9 ? " ": (indicator[selectedIndicator].indicatorId === 10 || indicator[selectedIndicator].indicatorId === 11 || indicator[selectedIndicator].indicatorId === 12 || indicator[selectedIndicator].indicatorId === 13) ? 'tonnes' : 'tCO2e', angle: -90, position: 'center', dx: -15 }} />
                                            <Tooltip content={(e) => tooltipContent({ ...e, fields: graphData.fields, unit: (indicator[selectedIndicator].indicatorId === 6 || indicator[selectedIndicator].indicatorId === 7 || indicator[selectedIndicator].indicatorId === 8) ? 'm3' :  indicator[selectedIndicator].indicatorId === 9 ? " " : (indicator[selectedIndicator].indicatorId === 10 || indicator[selectedIndicator].indicatorId === 11 || indicator[selectedIndicator].indicatorId === 12 || indicator[selectedIndicator].indicatorId === 13) ? 'tonnes' :'tCO2e' })} />
                                            <Legend
                                                layout="horizontal"  // Change to horizontal layout
                                                verticalAlign="top"
                                                align="center"

                                            />

                                            {/* Bars */}
                                            {graphData.fields.map((i) => {
                                                return (
                                                    <Bar dataKey={i.key} stackId="a" fill={i.color} />

                                                )
                                            })}


                                            {/* Dotted lines between groups */}

                                        </BarChart>
                                    </ResponsiveContainer>
                                </>
                            }
                            {indicator[selectedIndicator] && (indicator[selectedIndicator].indicatorId === 2) &&
                                <>
                                    <div className='col-12 flex justify-content-center '> Scope 1 Emissions Breakdown of Fuel Consumption (tCO2e) </div>
                                    <ResponsiveContainer width="100%" height={400}>
                                        <BarChart
                                            data={graphData2.sc_ghg}
                                            margin={{ top: 20, right: 30, left: 20, bottom: 40 }}
                                            barCategoryGap="100%"  // Adds space between categories (Jan, Feb, etc.)
                                            barGap={50}
                                            barSize={15}
                                        >

                                            {/* <CartesianGrid strokeDasharray="3 3" /> */}
                                            <XAxis dataKey="month" tick={{ angle: -90, textAnchor: 'end' }} tickMargin={0} interval={0} />
                                            <YAxis label={{ value: 'tCO2e', angle: -90, position: 'center', dx: -15 }} />
                                            <Tooltip content={(e) => tooltipContent({ ...e, fields: graphData2.fields, unit: 'tCO2e' })} />
                                            <Legend
                                                layout="horizontal"  // Change to horizontal layout
                                                verticalAlign="top"
                                                align="center"

                                            />

                                            {/* Bars */}
                                            {graphData2.fields.map((i) => {
                                                return (
                                                    <Bar dataKey={i.key} stackId="a" fill={i.color} />

                                                )
                                            })}


                                            {/* Dotted lines between groups */}

                                        </BarChart>
                                    </ResponsiveContainer>
                                    <div className='col-12 flex justify-content-center '>  Breakdown of Fuel Consumption </div>

                                    <ResponsiveContainer width="100%" height={400}>
                                        <BarChart
                                            data={graphData2.sc_usage}
                                            margin={{ top: 20, right: 30, left: 20, bottom: 40 }}
                                            barCategoryGap="100%"  // Adds space between categories (Jan, Feb, etc.)
                                            barGap={50}
                                            barSize={15}
                                        >

                                            {/* <CartesianGrid strokeDasharray="3 3" /> */}
                                            <XAxis dataKey="month" tick={{ angle: -90, textAnchor: 'end' }} tickMargin={0} interval={0} />
                                            <YAxis label={{ value: 'litre', angle: -90, position: 'center', dx: -15 }} />
                                            <Tooltip content={(e) => tooltipContent({ ...e, fields: graphData2.fields, unit: 'litre' })} />

                                            <Legend
                                                layout="horizontal"  // Change to horizontal layout
                                                verticalAlign="top"
                                                align="center"

                                            />

                                            {/* Bars */}
                                            {graphData2.fields.map((i) => {
                                                return (
                                                    <Bar dataKey={i.key} stackId="a" fill={i.color} />

                                                )
                                            })}


                                            {/* Dotted lines between groups */}

                                        </BarChart>
                                    </ResponsiveContainer>
                                    <div className='col-12 flex justify-content-center '> Scope 1 Emissions Breakdown of Refrigerants (tCO2e) </div>

                                    <ResponsiveContainer width="100%" height={400}>
                                        <BarChart
                                            data={graphData3.fg_ghg}
                                            margin={{ top: 20, right: 30, left: 20, bottom: 40 }}
                                            barCategoryGap="100%"  // Adds space between categories (Jan, Feb, etc.)
                                            barGap={50}
                                            barSize={15}
                                        >

                                            {/* <CartesianGrid strokeDasharray="3 3" /> */}
                                            <XAxis dataKey="month" tick={{ angle: -90, textAnchor: 'end' }} tickMargin={0} interval={0} />
                                            <YAxis label={{ value: 'tCO2e', angle: -90, position: 'center', dx: -15 }} />
                                            <Tooltip content={(e) => tooltipContent({ ...e, fields: graphData3.fields, unit: 'tCO2e' })} />

                                            <Legend
                                                layout="horizontal"  // Change to horizontal layout
                                                verticalAlign="top"
                                                align="center"

                                            />

                                            {/* Bars */}
                                            {graphData3.fields.map((i) => {
                                                return (
                                                    <Bar dataKey={i.key} stackId="a" fill={i.color} />

                                                )
                                            })}


                                            {/* Dotted lines between groups */}

                                        </BarChart>
                                    </ResponsiveContainer>
                                    <div className='col-12 flex justify-content-center '>  Breakdown of Refrigerants (kg) </div>

                                    <ResponsiveContainer width="100%" height={400}>
                                        <BarChart
                                            data={graphData3.fg_usage}
                                            margin={{ top: 20, right: 30, left: 20, bottom: 40 }}
                                            barCategoryGap="100%"  // Adds space between categories (Jan, Feb, etc.)
                                            barGap={50}
                                            barSize={15}
                                        >

                                            {/* <CartesianGrid strokeDasharray="3 3" /> */}
                                            <XAxis dataKey="month" tick={{ angle: -90, textAnchor: 'end' }} tickMargin={0} interval={0} />
                                            <YAxis label={{ value: 'kg', angle: -90, position: 'center', dx: -15 }} />
                                            <Tooltip content={(e) => tooltipContent({ ...e, fields: graphData3.fields, unit: 'kg' })} />

                                            <Legend
                                                layout="horizontal"  // Change to horizontal layout
                                                verticalAlign="top"
                                                align="center"

                                            />

                                            {/* Bars */}
                                            {graphData3.fields.map((i) => {
                                                return (
                                                    <Bar dataKey={i.key} stackId="a" fill={i.color} />

                                                )
                                            })}


                                            {/* Dotted lines between groups */}

                                        </BarChart>
                                    </ResponsiveContainer>
                                </>
                            }
                            {indicator[selectedIndicator] && (indicator[selectedIndicator].indicatorId === 3) &&
                                <>
                                    <div className='col-12 flex justify-content-center '>{!isDrilledDown ? 'Total Women in Middle and Senior Management: Past Trending' : 'Breakdown of Women Employees by Level'}</div>

                                    <ResponsiveContainer width="100%" height={400}>
                                        <BarChart
                                            data={graphData.data}
                                            margin={{ top: 20, right: 30, left: 20, bottom: 40 }}
                                            barCategoryGap="100%"  // Adds space between categories (Jan, Feb, etc.)
                                            barGap={50}
                                            barSize={15}
                                        >

                                            {/* <CartesianGrid strokeDasharray="3 3" /> */}
                                            <XAxis dataKey="month" tickMargin={0} interval={0} />
                                            {isDrilledDown ? <YAxis unit={'%'} /> : <YAxis label={{ value: 'Total Employees in Middle & Senior Management', angle: -90, position: 'center', dx: -15 }} />}


                                            {isDrilledDown ? <Tooltip content={empContent} /> : <Tooltip content={(e) => tooltipContent({ ...e, fields: graphData.fields, unit: 'nos' })} />}
                                            <Legend
                                                layout="horizontal"  // Change to horizontal layout
                                                verticalAlign="top"
                                                align="center"

                                            />

                                            {/* Bars */}
                                            {graphData.fields.map((i) => {
                                                return (
                                                    <Bar dataKey={i.key} stackId="a" fill={i.color} onClick={(data, index) => handleBarClick(data, index)} />

                                                )
                                            })}


                                            {/* Dotted lines between groups */}

                                        </BarChart>
                                    </ResponsiveContainer>
                                    {isDrilledDown && (
                                        <Button onClick={handleBackClick}>Back to Past Trending</Button>
                                    )}

                                </>
                            }
                            {indicator[selectedIndicator] && (indicator[selectedIndicator].indicatorId === 4) &&
                                <>
                                    <div className='col-12 flex justify-content-center '>Total Women in Senior Management: Past Trending </div>
                                    <ResponsiveContainer width="100%" height={400}>
                                        <BarChart
                                            data={graphData.data}
                                            margin={{ top: 20, right: 30, left: 20, bottom: 40 }}
                                            barCategoryGap="100%"  // Adds space between categories (Jan, Feb, etc.)
                                            barGap={50}
                                            barSize={15}
                                        >

                                            {/* <CartesianGrid strokeDasharray="3 3" /> */}
                                            <XAxis dataKey="month" tickMargin={0} interval={0} />
                                            <YAxis label={{ value: 'Total Employees in Senior Management', angle: -90, position: 'center', dx: -15 }} />
                                            <Tooltip content={(e) => tooltipContent({ ...e, fields: graphData.fields, unit: 'nos' })} />
                                            <Legend
                                                layout="horizontal"  // Change to horizontal layout
                                                verticalAlign="top"
                                                align="center"

                                            />

                                            {/* Bars */}
                                            {graphData.fields.map((i) => {
                                                return (
                                                    <Bar dataKey={i.key} stackId="a" fill={i.color} />

                                                )
                                            })}


                                            {/* Dotted lines between groups */}

                                        </BarChart>
                                    </ResponsiveContainer>
                                </>
                            }
                            {indicator[selectedIndicator] && (indicator[selectedIndicator].indicatorId === 5 || indicator[selectedIndicator].indicatorId === 6 || indicator[selectedIndicator].indicatorId === 8 || indicator[selectedIndicator].indicatorId === 7 || indicator[selectedIndicator].indicatorId === 9|| indicator[selectedIndicator].indicatorId === 10 || indicator[selectedIndicator].indicatorId === 11 ||  indicator[selectedIndicator].indicatorId === 12 ||  indicator[selectedIndicator].indicatorId === 13) &&
                                <>
                                    <div className='col-12 flex justify-content-center '>{indicator[selectedIndicator].indicatorId === 5 ? 'Breakdown of Electricity Consumption (Not Including EACs) (MWh)' : (indicator[selectedIndicator].indicatorId === 6 ||  indicator[selectedIndicator].indicatorId === 7) ? 'Breakdown of Water Withdrawl Sources (m3)' : indicator[selectedIndicator].indicatorId === 8 ? 'Breakdown of Water Discharge Sources (m3)' : indicator[selectedIndicator].indicatorId === 9 ? 'Water Withdrawn (m3)' :  indicator[selectedIndicator].indicatorId === 10 ? 'Breakdown of Non-Hazardous Waste Generated (tonnes)' : indicator[selectedIndicator].indicatorId === 11 ? ' Breakdown of Non-hazardous Waste Disposal Method (tonnes)' :  indicator[selectedIndicator].indicatorId === 12 ? 'Breakdown of Hazardous Waste Generated (tonnes)' :indicator[selectedIndicator].indicatorId === 13 ? "Breakdown of Hazardous Waste Recycling and Disposal Method (tonnes)": ''}</div>
                                    <ResponsiveContainer width="100%" height={400}>
                                        <BarChart
                                            data={graphData2.data}
                                            margin={{ top: 20, right: 30, left: 20, bottom: 40 }}
                                            barCategoryGap="100%"  // Adds space between categories (Jan, Feb, etc.)
                                            barGap={50}
                                            barSize={15}
                                        >

                                            {/* <CartesianGrid strokeDasharray="3 3"  'MWh' /> */}
                                            <XAxis dataKey="month" tick={{ angle: -90, textAnchor: 'end' }} tickMargin={0} interval={0} />
                                            <YAxis label={{ value: graphData2.unit, angle: -90, position: 'center', dx: -15 }} />
                                            <Tooltip content={(e) => tooltipContent({ ...e, fields: graphData2.fields, unit: graphData2.unit })} />
                                            <Legend
                                                layout="horizontal"  // Change to horizontal layout
                                                verticalAlign="top"
                                                align="center"

                                            />

                                            {/* Bars */}
                                            {graphData2.fields.map((i) => {
                                                return (
                                                    <Bar dataKey={i.key} stackId="a" fill={i.color} />

                                                )
                                            })}


                                            {/* Dotted lines between groups */}

                                        </BarChart>
                                    </ResponsiveContainer>
                                </>
                            }
                                {indicator[selectedIndicator] && (indicator[selectedIndicator].indicatorId === 7  || indicator[selectedIndicator].indicatorId === 9) &&
                                <>
                                    <div className='col-12 flex justify-content-center '>{indicator[selectedIndicator].indicatorId === 7 ? 'Breakdown of Water Discharge Sources (m3)' : indicator[selectedIndicator].indicatorId === 9 ? 'Overall Customer IT Electricity Consumption (MWh) ' : ''  }</div>
                                    <ResponsiveContainer width="100%" height={400}>
                                        <BarChart
                                            data={graphData3.data}
                                            margin={{ top: 20, right: 30, left: 20, bottom: 40 }}
                                            barCategoryGap="100%"  // Adds space between categories (Jan, Feb, etc.)
                                            barGap={50}
                                            barSize={15}
                                        >

                                            {/* <CartesianGrid strokeDasharray="3 3"  'MWh' /> */}
                                            <XAxis dataKey="month" tick={{ angle: -90, textAnchor: 'end' }} tickMargin={0} interval={0} />
                                            <YAxis label={{ value: graphData3.unit, angle: -90, position: 'center', dx: -15 }} />
                                            <Tooltip content={(e) => tooltipContent({ ...e, fields: graphData3.fields, unit: graphData3.unit })} />
                                            <Legend
                                                layout="horizontal"  // Change to horizontal layout
                                                verticalAlign="top"
                                                align="center"

                                            />

                                            {/* Bars */}
                                            {graphData3.fields.map((i) => {
                                                return (
                                                    <Bar dataKey={i.key} stackId="a" fill={i.color} />

                                                )
                                            })}


                                            {/* Dotted lines between groups */}

                                        </BarChart>
                                    </ResponsiveContainer>
                                </>
                            }
                        </div>

                    </Dialog>

                </div>
            </BlockUI>
            <Dialog header={selectedIndicator !== null ? 'Data Source for ' + indicator[selectedIndicator].title + ' - ' + filter.year : 'Data Source'} style={{ width: '75%' }} visible={sourcedialog} onHide={() => { setSourceDialog(false) }}>
                <TabView>
                    <TabPanel header="Data Source List">
                        <div>
                            <DataTable value={sourcelist.filter(i => i.data && (i.data.type === 2 || i.data.type === 3)).map(i => i.data)} >
                                <Column header='Form' body={formTemplate} />
                                <Column header='Entity' body={coverageTemplate} />
                                <Column header='Reporting Period' body={rpTemplate} />
                                <Column header='Action' body={actionTemplate} />
                            </DataTable>
                        </div>
                    </TabPanel>
                    <TabPanel header="Pending Data Submissions">
                        <div>
                            <DataTable value={sourcelist.filter(i => (!i.data || (i.data && i.data.type < 2)))} >
                                <Column header='Form' body={formTemplate_} />
                                <Column header='Entity' body={coverageTemplate} />
                                <Column header='Reporting Period' field='reporting_period' />

                                <Column header='Responsibility' body={responsibilityTemplate} />
                                <Column header='Status' body={statusTemplate} />
                            </DataTable>
                        </div>
                    </TabPanel>
                </TabView>

            </Dialog>
        </div>
    );
};

export const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

const comparisonFn = function (prevProps, nextProps) {
    return prevProps.location.pathname === nextProps.location.pathname;
};

export default React.memo(ApproverDB, comparisonFn);
